import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Campaign } from 'src/app/interfaces/Campaign';
import { ToastType } from 'src/app/interfaces/ToastMessage';
import { User } from 'src/app/interfaces/User';
import { NewsletterService } from 'src/app/services/newsletter.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-contact-details',
	templateUrl: './contact-details.component.html',
	styleUrls: ['./contact-details.component.scss'],
})
export class ContactDetailsComponent implements OnInit {
	campaigns: any = [];
	previousCheckboxState: { [key: string]: boolean } = {};
	changedCheckboxState: { [key: string]: boolean } = {};
	user: User;
	@ViewChild('checkbox') checkbox: ElementRef;

	constructor(
		private readonly titleService: Title,
		private readonly userService: UserService,
		private readonly newsletterService: NewsletterService,
		private readonly toastService: ToastService
	) {}

	ngOnInit(): void {
		this.titleService.setTitle('Contact Details | SoundFormX');

		this.userService.getUser().subscribe({
			next: (res: User) => {
				console.log(res);
				this.user = res;
				this.newsletterService.getCampaigns().subscribe({
					next: (res: Campaign[]) => {
						console.log(res);
						res.forEach((campaign: Campaign) => {
							this.campaigns.push(campaign);

							this.newsletterService.doesSubscriberExist(this.user.email, campaign.id).subscribe(
								(response) => {
									const isSubscriber = response.isSubscriber;
									campaign.optedIn = isSubscriber;
									// Save the initial checkbox state in previousCheckboxState
									this.previousCheckboxState[campaign.id] = isSubscriber;
								},
								(error) => {
									console.log('Error getting user email campaign status: ', error);
								}
							);
						});
					},
					error: (error) => {
						console.log('Error getting data:', error);
					},
				});
			},
			error: (error) => {
				console.log('Error getting user data:', error);
			},
		});
	}

	savePreferences() {
		// Check for changes in checkbox state
		const checkboxes = document.querySelectorAll<HTMLInputElement>('input[type="checkbox"]');
		checkboxes.forEach((checkbox: HTMLInputElement) => {
			if (this.previousCheckboxState[checkbox.id] !== checkbox.checked) {
				console.log(`${checkbox.id} - ${checkbox.checked}`);
				this.changedCheckboxState[checkbox.id] = checkbox.checked;
			}
		});
		// Save preferences and update previous state
		console.log('Preferences saved');
		checkboxes.forEach((checkbox: HTMLInputElement) => {
			this.previousCheckboxState[checkbox.id] = checkbox.checked;
		});
		// Update external API
		this.updateExternalAPI();
	}

	updateExternalAPI() {
		// Loop through the changed checkbox states and update the API list
		for (const id in this.changedCheckboxState) {
			const isChecked = this.changedCheckboxState[id];
			console.log(`Updating API for ${id} - ${isChecked}`);
			if (isChecked) {
				this.newsletterService.subscribeUser(this.user.email, id).subscribe(
					(response) => {
						console.log(response);
						this.toastService.addToast({
							type: ToastType.SUCCESS,
							title: 'Subscribe Email Successful',
							message: `Email ${this.user.email} was successfully subscribed.`,
						});
					},
					(error) => {
						console.log(error);
						this.toastService.addToast({
							type: ToastType.ERROR,
							title: 'Subscribe Email Unsuccessful',
							message: `Email ${this.user.email} failed to subscribe. Please ensure valid email is in used.`,
						});

						this.checkbox.nativeElement.checked = false;
					}
				);
			} else {
				this.newsletterService.unsubscribeUser(this.user.email, id).subscribe(
					(response) => {
						console.log(response);
						this.toastService.addToast({
							type: ToastType.SUCCESS,
							title: 'Unsubscribe Email Successful',
							message: `Email ${this.user.email} was successfully unsubscribed.`,
						});
					},
					(error) => {
						console.log(error);
						this.toastService.addToast({
							type: ToastType.ERROR,
							title: 'Unsubscribe Email Unsuccessful',
							message: `Email ${this.user.email} failed to unsubscribe. Please try again.`,
						});
					}
				);
			}
		}
	}
}
