<nav class="navbar bg-dark navbar-expand-lg navbar-dark taller-navbar">
	<div>
		<button (click)="toggleNavbar()" [attr.aria-expanded]="!isCollapsed" aria-controls="navbarNav" aria-label="Toggle navigation" class="navbar-toggler mx-3" type="button">
			<span class="navbar-toggler-icon"></span>
		</button>

		<a class="navbar-brand mx-lg-3" routerLink="/">
			<img alt="SFX Official Logo" class="navbar-logo" src="./assets/logo.png" />
		</a>
	</div>
	<div class="navbar-nav flex-row position-relative" id="navbarNavRight">
		<div class="nav-item">
			<a (click)="signOut()" class="btn nav-link" role="button">
				<i class="bi bi-power navbar-icon"></i>
			</a>
		</div>
	</div>
	<div [ngClass]="{ show: !isCollapsed }" class="collapse navbar-collapse" id="navbarNav">
		<ul class="navbar-nav gap-3 m-3 mr-auto">
			<li [routerLinkActive]="['active']" class="nav-item">
				<a class="nav-link" routerLink="/admin/products">Products</a>
			</li>
			<li [routerLinkActive]="['active']" class="nav-item">
				<a class="nav-link" routerLink="/admin/orders">Orders</a>
			</li>
			<li [routerLinkActive]="['active']" class="nav-item">
				<a class="nav-link" routerLink="/admin/customers">Customers</a>
			</li>
			<li [routerLinkActive]="['active']" class="nav-item">
				<a class="nav-link" routerLink="/admin/pages">Pages</a>
			</li>
			<li [routerLinkActive]="['active']" class="nav-item">
				<a class="nav-link" routerLink="/admin/coupons">Coupons</a>
			</li>
		</ul>
	</div>
</nav>
