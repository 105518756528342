<div class="container sfx-top-spacing">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item"><a class="text-info" routerLink="/admin">Dashboard</a></li>
			<li aria-current="page" class="breadcrumb-item active">Products</li>
		</ol>
	</nav>

	<h1>Products</h1>
	<hr />

	<p-table class="mt-3" #dt1 [value]="products" dataKey="productTypeId" [rows]="rowPerPage" [showCurrentPageReport]="true" [loading]="loading" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" [globalFilterFields]="['productName', 'price']" [totalRecords]="totalProducts" selectionMode="single" [sortField]="'dateModified'" [sortOrder]="-1" [rowsPerPageOptions]="[10, 25, 50, 100]">
		<ng-template pTemplate="caption">
			<div class="d-flex align-items-stretch gap-2">
				<div class="input-group">
					<span class="input-group-text">
						<i class="bi bi-search"></i>
					</span>

					<input [type]="'text'" class="form-control" id="sfx-datatable-search" placeholder="Search for products" (input)="onSearchHandle($event)" />
				</div>
				<a class="d-flex justify-content-center align-items-center btn btn-primary" style="min-width: 120px" routerLink="/admin/products/add">Add Product</a>
			</div>
		</ng-template>
		<ng-template pTemplate="header">
			<tr>
				<th pSortableColumn="productName" style="width: 35%">Name <p-sortIcon field="code"></p-sortIcon></th>
				<th pSortableColumn="productTypeId" style="width: 20%">Type <p-sortIcon field="code"></p-sortIcon></th>
				<th pSortableColumn="price" style="width: 20%">Price <p-sortIcon field="code"></p-sortIcon></th>
				<th pSortableColumn="dateModified" style="width: 20%">Date Modified <p-sortIcon field="code"></p-sortIcon></th>
				<th style="width: 5%"></th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-product>
			<tr>
				<td>
					{{ product.productName }}
				</td>
				<td>
					<span class="ml-1 vertical-align-middle">{{ getProductType(product.productTypeId) }}</span>
				</td>
				<td *ngIf="product.price !== '0'">{{ product.price | currency }}</td>
				<td *ngIf="product.price === '0'">Free</td>
				<td>
					{{ product.dateModified | date : "M/d/yyyy h:mm a" }}
				</td>
				<td class="icon-cell">
					<a class="text-info" routerLink="/admin/products/edit/{{ product.productId }}">
						<span class="bi bi-pencil-square"></span>
					</a>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="7">No products found.</td>
			</tr>
		</ng-template>
	</p-table>
</div>
