<div class="footer w-100 bg-secondary">
	<div class="footer-container d-flex flex-column w-100 h-100 text-white p-5 gap-4">
		<div class="footer-top d-flex flex-column w-100 gap-5">
			<div class="footer-col d-flex flex-column w-100 gap-2">
				<div class="footer-col-title font-weight-bold text-uppercase text-sfx-light-blue">SHOP</div>
				<a class="text-white text-decoration-none" routerLink="/products/soundpack" [queryParams]="{ category: 2 }">Loops</a>
				<a class="text-white text-decoration-none" routerLink="/products/soundpack" [queryParams]="{ category: 3 }">One-Shots</a>
				<a class="text-white text-decoration-none" routerLink="/products/soundpack" [queryParams]="{ category: 1 }">Soundtracks</a>
				<a class="text-white text-decoration-none" routerLink="/products/soundpack" [queryParams]="{ category: 6 }">Soundfonts</a>
				<a class="text-white text-decoration-none" routerLink="/products/soundpack" [queryParams]="{ category: 5 }">Plugins</a>
				<a class="text-white text-decoration-none" routerLink="/products/tutorials">Tutorials</a>
				<a class="text-white text-decoration-none" routerLink="/products/merchandise">Merch</a>
			</div>
			<div class="footer-col d-flex flex-column w-100 gap-2">
				<div class="footer-col-title font-weight-bold text-uppercase text-sfx-light-blue">SOUNDFORMX</div>
				<a class="text-white text-decoration-none" routerLink="/about-us">About Us</a>
				<a class="text-white text-decoration-none" routerLink="/contact-us">Contact Us</a>
				<a class="text-white text-decoration-none" routerLink="/community">Community</a>
				<a class="text-white text-decoration-none" routerLink="/connect">Connect</a>
				<a class="text-white text-decoration-none" routerLink="/terms-and-conditions">Terms & Conditions</a>
				<a class="text-white text-decoration-none" routerLink="/faq">FAQ</a>
			</div>
			<div class="footer-col d-flex flex-column w-100 gap-2">
				<div class="footer-col-title font-weight-bold text-uppercase text-sfx-light-blue">NEWSLETTER</div>
				<div class="footer-col-description">Be the first to know about new releases, promotions, special events, and more.</div>
				<div class="input-group input-group-sm mt-1">
					<input [(ngModel)]="email" id="email-input" class="form-control footer-col-textbox" placeholder="Enter your email address..." type="text" />
				</div>

				<button id="sign-up-button" class="btn btn-info footer-col-btn btn-sm text-white text-uppercase" type="button" (click)="signUp()">Sign Up</button>
			</div>
		</div>
		<hr />
		<div class="footer-bottom">© 2022 SoundFormX. All rights reserved.</div>
	</div>
</div>
