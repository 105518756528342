import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { last } from 'rxjs';
import { SFXFileType } from 'src/app/enums/SFXFileType';
import { StorageService } from 'src/app/services/storage.service';

@Component({
	selector: 'app-sfx-rich-text-editor',
	templateUrl: './sfx-rich-text-editor.component.html',
	styleUrls: ['./sfx-rich-text-editor.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: SfxRichTextEditorComponent,
		},
	],
})
export class SfxRichTextEditorComponent implements OnInit, ControlValueAccessor {
	@Input() content: string;
	@Output() contentChange: EventEmitter<string> = new EventEmitter<string>();
	touched = false;
	disabled = false;
	config: EditorComponent['init'] = {
		base_url: '/tinymce',
		suffix: '.min',
		menubar: false,
		plugins: 'lists link image table code help wordcount media preview',
		toolbar:
			'undo redo | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image media | code preview',
		file_picker_types: 'image',
		block_unsupported_drop: true,
		placeholder: 'Describe your product here...',
		images_upload_handler: (blobInfo) => {
			const blob = blobInfo.blob();
			const file = new File([blob], blobInfo.filename());

			return new Promise<string>((resolve, reject) => {
				this.storageService
					.uploadFile(file, SFXFileType.PREVIEW_IMAGE)
					.pipe(last())
					.subscribe({
						next: (status) => {
							if (status.filePath) {
								resolve(status.filePath);
							}
						},
						error: (error) => {
							reject(error);
						},
					});
			});
		},
	};

	constructor(private storageService: StorageService) {}

	onChange = (_: string) => {};

	onTouched = () => {};

	ngOnInit(): void {}

	handleEvent(html: string) {
		this.contentChange.emit(html);
		this.renderHTMLOnPreview(html);
		this.onChange(html);
	}

	renderHTMLOnPreview(html: string) {
		const previewElement = document.getElementById('preview-container');
		if (!previewElement) {
			return;
		}
		previewElement.innerHTML = html;
	}

	registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any): void {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	writeValue(html: string): void {
		this.renderHTMLOnPreview(html);
		this.content = html;
	}
}
