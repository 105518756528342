<div class="modal-header">
	<h4 class="modal-title pull-left">Manage Delivery Methods</h4>
	<button (click)="bsModalRef.hide()" aria-label="Close" class="btn-close close pull-right" type="button">
		<span aria-hidden="true" class="visually-hidden">&times;</span>
	</button>
</div>
<div class="modal-body">
	<form (ngSubmit)="onSave()" [formGroup]="deliveryMethodForm">
		<div class="d-flex align-items-end gap-2">
			<div class="form-group w-50">
				<label class="form-label" for="name">Name</label>
				<input class="form-control" formControlName="deliveryTypeName" id="name" placeholder="Enter name" required type="text" />
			</div>
			<div class="form-group w-50">
				<label class="form-label" for="price">Price</label>
				<input class="form-control" formControlName="deliveryPrice" id="price" placeholder="Enter price" required type="number" />
			</div>
			<button [disabled]="!deliveryMethodForm.valid" class="btn btn-primary" type="submit">
				{{ isEditMode ? "Update" : "Add" }}
			</button>
			<button *ngIf="isEditMode" (click)="onCancel()" class="btn btn-danger" type="button">Cancel</button>
		</div>
	</form>

	<hr />

	<table class="table">
		<thead>
			<tr>
				<th>Name</th>
				<th>Price</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let rate of deliveryMethods">
				<td>{{ rate.deliveryTypeName }}</td>
				<td>{{ rate.deliveryPrice | currency }}</td>
				<td class="icon-cell">
					<a (click)="onEdit(rate)" class="text-secondary" role="button"><span class="bi bi-pencil-square"></span></a>
				</td>
			</tr>
		</tbody>
	</table>
</div>
