<div class="card" *ngIf="filterTrees.categoryIds && filterTrees.categoryIds.length > 1">
	<article class="filter-group">
		<header (click)="toggleCollapse($event, categoryToggle)" class="card-header">
			<a aria-expanded="true" class="" role="button">
				<i class="icon-control bi bi-chevron-down"></i>
				<div class="title">Category</div>
			</a>
		</header>
		<div #categoryToggle class="collapse show" id="categoryToggle">
			<div class="card-body px-0 py-2">
				<app-sfx-filter-tree identifier="category" [(ngModel)]="selectedFilters.categoryIds" (ngModelChange)="onFilterChange()" [filters]="filterTrees.categoryIds" [shouldUnselectChildren]="true" [shouldShowCount]="shouldShowCount"></app-sfx-filter-tree>
			</div>
		</div>
	</article>
</div>

<div class="card" *ngIf="filterTrees.genres && filterTrees.genres.length > 0">
	<article class="filter-group">
		<header (click)="toggleCollapse($event, genreToggle)" class="card-header">
			<a aria-expanded="true" class="" role="button">
				<i class="icon-control bi bi-chevron-down"></i>
				<div class="title">Genres</div>
			</a>
		</header>
		<div #genreToggle class="collapse show" id="genreToggle">
			<div class="card-body px-0 py-2">
				<app-sfx-filter-tree identifier="genres" [(ngModel)]="selectedFilters.genres" (ngModelChange)="onFilterChange()" [filters]="filterTrees.genres" [shouldUnselectChildren]="true" [shouldShowCount]="shouldShowCount"></app-sfx-filter-tree>
			</div>
		</div>
	</article>
</div>

<div class="card" *ngIf="filterTrees.instruments && filterTrees.instruments.length > 0">
	<article class="filter-group">
		<header (click)="toggleCollapse($event, instrumentsToggle)" class="card-header">
			<a aria-expanded="true" class="" role="button">
				<i class="icon-control bi bi-chevron-down"></i>
				<div class="title">Instruments</div>
			</a>
		</header>
		<div #instrumentsToggle class="collapse show" id="instrumentsToggle">
			<div class="card-body px-0 py-2">
				<app-sfx-filter-tree identifier="instruments" [(ngModel)]="selectedFilters.instruments" (ngModelChange)="onFilterChange()" [filters]="filterTrees.instruments" [shouldUnselectChildren]="true" [shouldShowCount]="shouldShowCount"></app-sfx-filter-tree>
			</div>
		</div>
	</article>
</div>

<div class="card" *ngIf="filterTrees.formats && filterTrees.formats.length > 0">
	<article class="filter-group">
		<header (click)="toggleCollapse($event, formatsToggle)" class="card-header">
			<a aria-expanded="true" class="" role="button">
				<i class="icon-control bi bi-chevron-down"></i>
				<div class="title">Formats</div>
			</a>
		</header>
		<div #formatsToggle class="collapse show" id="formatsToggle">
			<div class="card-body px-0 py-2">
				<app-sfx-filter-tree identifier="formats" [(ngModel)]="selectedFilters.formats" (ngModelChange)="onFilterChange()" [filters]="filterTrees.formats" [shouldUnselectChildren]="true" [shouldShowCount]="shouldShowCount"></app-sfx-filter-tree>
			</div>
		</div>
	</article>
</div>

<div class="card" *ngIf="rangeFilters.bpm && selectedFilters">
	<article class="filter-group">
		<header (click)="toggleCollapse($event, bpmToggle)" class="card-header">
			<a aria-expanded="true" class="" role="button">
				<i class="icon-control bi bi-chevron-down"></i>
				<div class="title">BPM</div>
			</a>
		</header>
		<div #bpmToggle class="collapse show" id="bpmCollapse">
			<div class="card-body px-0 py-2">
				<app-sfx-filter-range label="BPM" identifier="bpm" [(ngModel)]="selectedFilters.bpm" (ngModelChange)="onFilterChange()" [defaultOptions]="bpmOptions" ngDefaultControl></app-sfx-filter-range>
			</div>
		</div>
	</article>
</div>

<div class="card" *ngIf="rangeFilters.price && selectedFilters">
	<article class="filter-group">
		<header (click)="toggleCollapse($event, priceToggle)" class="card-header">
			<a aria-expanded="true" class="" role="button">
				<i class="icon-control bi bi-chevron-down"></i>
				<div class="title">Price</div>
			</a>
		</header>
		<div #priceToggle class="collapse show" id="priceToggle">
			<div class="card-body px-0 py-2">
				<app-sfx-filter-range label="Price" identifier="price" [(ngModel)]="selectedFilters.price" (ngModelChange)="onFilterChange()" [defaultOptions]="priceOptions" ngDefaultControl></app-sfx-filter-range>
			</div>
		</div>
	</article>
</div>
