import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ToastPayload } from '../interfaces/ToastMessage';

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	toastEvents: Observable<ToastPayload>;
	private _toastEvents = new Subject<ToastPayload>();

	constructor() {
		this.toastEvents = this._toastEvents.asObservable();
	}

	addToast(payload: ToastPayload) {
		this._toastEvents.next(payload);
	}
}
