import { AbstractControl } from '@angular/forms';

interface ValidationErrors {
	minlength?: boolean;
	uppercase?: boolean;
	lowercase?: boolean;
	number?: boolean;
	special?: boolean;
}

export class PasswordValidator {
	static complexity(control: AbstractControl) {
		const MIN_LENGTH = 8;
		const UPPER_CASE_CHAR_PATTERN = /[A-Z]/;
		const LOWER_CASE_CHAR_PATTERN = /[a-z]/;
		const NUMBER_PATTERN = /[0-9]/;
		const SPECIAL_CHAR_PATTERN = /[!@#$%^&*]/;
		const errors: ValidationErrors = {};

		if (!control.value) {
			return null;
		}

		const length = control.value.length;
		const hasUpper = UPPER_CASE_CHAR_PATTERN.test(control.value);
		const hasLower = LOWER_CASE_CHAR_PATTERN.test(control.value);
		const hasNumber = NUMBER_PATTERN.test(control.value);
		const hasSpecial = SPECIAL_CHAR_PATTERN.test(control.value);

		if (length < MIN_LENGTH) {
			errors.minlength = true;
		}

		if (!hasUpper) {
			errors.uppercase = true;
		}

		if (!hasLower) {
			errors.lowercase = true;
		}

		if (!hasNumber) {
			errors.number = true;
		}

		if (!hasSpecial) {
			errors.special = true;
		}

		return Object.keys(errors).length > 0 ? { complexity: errors } : null;
	}
}
