import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { lastValueFrom, Observable } from 'rxjs';
import { Address, CustomSFXAddress } from 'src/app/interfaces/Address';
import { CartItem } from 'src/app/interfaces/CartItem';
import { Coupon } from 'src/app/interfaces/Coupon';
import { ToastType } from 'src/app/interfaces/ToastMessage';
import { User } from 'src/app/interfaces/User';
import { US_STATES, USState } from 'src/app/interfaces/USState';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { OrderService } from 'src/app/services/order.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { noWhiteSpaceValidator } from 'src/app/validators/no-white-space.validator';

@Component({
	selector: 'app-order-checkout',
	templateUrl: './order-checkout.component.html',
	styleUrls: ['./order-checkout.component.scss'],
})
export class OrderCheckoutComponent implements OnInit {
	checkoutForm: FormGroup;
	shippingAddressForm: FormGroup;

	isCouponSubmitted = false;
	isAddressSubmitted = false;
	subtotal: number;
	finalSubtotal: number;
	discountValue: number;
	// tax: number;
	total: number;

	firstName: string = '';
	_US_STATES: USState[] = [];
	showErrors: boolean = false;
	isShippingAsBilling: boolean = false;
	hasPhysicalProduct = false;

	appliedCoupon?: Coupon;
	cartItems: CartItem[] = [];

	defaultShippingAddress?: Address;

	cartItems$: Observable<CartItem[]> = this.cartService.cartItemsSubject.asObservable();

	constructor(
		private formBuilder: FormBuilder,
		private titleService: Title,
		private cartService: CartService,
		private authService: AuthService,
		private readonly router: Router,
		private orderService: OrderService,
		private userService: UserService,
		private toastService: ToastService
	) {}

	// convenience getter for easy access to form fields
	get af() {
		return this.shippingAddressForm.controls;
	}

	get cf() {
		return this.checkoutForm.controls;
	}

	async onCheckout(): Promise<void> {
		if (!this.authService.isAuthenticated()) {
			this.router.navigate(['/sign-in']);
			return;
		}

		this.isAddressSubmitted = true;

		if (!this.shippingAddressForm || this.shippingAddressForm.invalid) {
			return;
		}

		const firstName = this.af['firstName'].value;
		const middleName = this.af['middleName'].value;
		const lastName = this.af['lastName'].value;
		const shouldSaveShipping = this.af['shouldSaveShipping'].value;
		const shippingStreet = this.af['shippingStreet'].value;
		const shippingStreet2 = this.af['shippingStreet2'].value;
		const shippingCity = this.af['shippingCity'].value;
		const shippingState = this.af['shippingState'].value;
		const shippingZipCode = this.af['shippingZipCode'].value;
		const isDefaultShipping = this.af['isDefaultShipping'].value;

		if (
			this.hasPhysicalProduct &&
			this.defaultShippingAddress &&
			shouldSaveShipping &&
			(this.defaultShippingAddress.streetAddress !== shippingStreet ||
				this.defaultShippingAddress.city !== shippingCity ||
				this.defaultShippingAddress.state !== shippingState ||
				this.defaultShippingAddress.zip !== shippingZipCode ||
				this.defaultShippingAddress.firstName !== firstName ||
				this.defaultShippingAddress.middleName !== middleName ||
				this.defaultShippingAddress.lastName !== lastName)
		) {
			this.userService
				.addAddress(
					firstName || '',
					middleName || '',
					lastName || '',
					shippingStreet || '',
					shippingStreet2 || '',
					shippingCity || '',
					shippingState || '',
					shippingZipCode || '',
					'United States',
					isDefaultShipping
				)
				.subscribe({
					error: (error) => {
						console.log('Error adding default address:', error);
						this.toastService.addToast({
							type: ToastType.WARNING,
							title: 'Adding default address failed',
							message: 'Please try again.',
						});
					},
				});
		}

		const shippingAddress: CustomSFXAddress = {
			shippingMethodId: '2',
			shippingName: [firstName, middleName, lastName].filter((o) => !!o).join(' '),
			shippingLine1: shippingStreet,
			shippingLine2: shippingStreet2,
			shippingCity: shippingCity,
			shippingState: shippingState,
			shippingPostalCode: shippingZipCode,
			shippingCountry: 'United States',
		};

		this.orderService
			.createCheckoutSession(this.appliedCoupon?.couponId, this.hasPhysicalProduct ? shippingAddress : undefined)
			.subscribe(({ url }) => {
				window.location.href = url;
			});
	}

	private updateTotalDiscountValue() {
		let discountValue = 0;

		for (const item of this.cartItems) {
			discountValue += item.discountValue || 0;
		}

		this.discountValue = Math.round(discountValue * 100) / 100;
	}

	private updatePricing() {
		let subtotal = 0;

		for (const item of this.cartItems) {
			const price = Number(item.price) || 0;
			const quantity = Number(item.productQuantity) || 1;
			const itemSubPrice = price * quantity;
			subtotal += itemSubPrice;

			if (item.deliveryTypeName?.toLowerCase() === 'physical') {
				this.hasPhysicalProduct = true;
			}
		}

		this.total = Math.round(subtotal * 100) / 100 - Math.round(this.discountValue * 100) / 100;
		this.subtotal = Math.round(subtotal * 100) / 100;
		// this.tax = tax;
	}

	ngOnInit(): void {
		this.titleService.setTitle('Shopping Cart | SoundFormX');

		this.checkoutForm = this.formBuilder.group({
			couponId: ['', [Validators.required, noWhiteSpaceValidator()]],
		});

		this.shippingAddressForm = this.formBuilder.group({
			firstName: ['', Validators.required],
			middleName: [''],
			lastName: ['', Validators.required],
			shippingStreet: ['', Validators.required],
			shippingStreet2: [''],
			shippingCity: ['', Validators.required],
			shippingState: ['', Validators.required],
			shippingZipCode: ['', Validators.required],
			shouldSaveShipping: [false],
			isDefaultShipping: [false],
		});

		this.cartItems$.subscribe((cartItems) => {
			this.hasPhysicalProduct = false;
			this.cartItems = cartItems;

			this.updateTotalDiscountValue();
			this.updatePricing();
			this.updateTotalDiscountValue();
		});

		this.userService.getUser().subscribe({
			next: (user: User) => {
				this.firstName = user.firstName;
			},
			error: (error) => {
				console.log('Error getting user data:', error);
			},
		});

		this._US_STATES = US_STATES;

		this.userService.getShippingAddress().subscribe({
			next: (res: { userResp: Address }) => {
				this.defaultShippingAddress = res.userResp || {
					firstName: '',
					middleName: '',
					lastName: '',
					streetAddress: '',
					city: '',
					state: '',
					zip: '',
				};
				this.shippingAddressForm.patchValue({
					firstName: res.userResp.firstName || '',
					middleName: res.userResp.middleName || '',
					lastName: res.userResp.lastName || '',
					shippingStreet: res.userResp.streetAddress,
					shippingStreet2: res.userResp.unitNumber,
					shippingCity: res.userResp.city,
					shippingState: res.userResp.state,
					shippingZipCode: res.userResp.zip,
					shouldSaveShipping: false,
					isDefaultShipping: false,
				});
			},
			error: (error) => {
				console.log('Error getting user data:', error);
			},
		});
	}

	onCouponSubmit() {
		this.isCouponSubmitted = true;

		if (!this.checkoutForm || this.checkoutForm.invalid) {
			return;
		}

		const couponId = (this.cf['couponId'].value as string).toUpperCase();

		this.orderService.validateCoupon(this.authService.getUserId(), couponId).subscribe({
			next: (response) => {
				const isValid = response.isValid;

				if (!isValid) {
					this.toastService.addToast({
						type: ToastType.WARNING,
						title: 'Coupon Validation Failed',
						message: 'Coupon is invalid. Please try again.',
					});
					return;
				}

				this.displayDiscount(couponId);
			},
			error: (error) => {
				this.toastService.addToast({
					type: ToastType.INFO,
					title: 'Info',
					message: error.error.message,
				});
			},
		});
	}

	displayDiscount(couponId: string) {
		this.orderService.getCoupon(couponId.toUpperCase()).subscribe({
			next: (coupon) => {
				this.appliedCoupon = coupon;
				this.updateTotalDiscountValue();
				this.updatePricing();
				this.cartService.addDiscountToCart(coupon);
			},
			error: (err) => {},
		});
	}

	onBackToShoppingCart() {
		this.router.navigate(['/user/cart']);
	}
}
