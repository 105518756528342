<div class="modal-header">
	<h4 class="modal-title pull-left">Manage Instruments</h4>
	<button (click)="bsModalRef.hide()" aria-label="Close" class="btn-close close pull-right" type="button">
		<span aria-hidden="true" class="visually-hidden">&times;</span>
	</button>
</div>
<div class="modal-body">
	<form (ngSubmit)="addInstrument()" [formGroup]="manageInstrumentsForm">
		<div class="d-flex align-items-end gap-2">
			<div class="form-group w-50">
				<label class="form-label" for="instrument-name">Instrument Name</label>
				<input class="form-control" formControlName="instrumentName" id="instrument-name" placeholder="Piano" required type="text" />
			</div>
			<div class="form-group w-50">
				<label class="form-label" for="parent-instrument">Parent</label>
				<select class="form-control form-select" formControlName="parentInstrument" id="parent-instrument">
					<option selected value="">None (Base Instrument)</option>
					<option *ngFor="let x of instruments" [value]="x.instrumentId">
						{{ x.instrumentName }}
					</option>
				</select>
			</div>
			<input [disabled]="!manageInstrumentsForm.valid" [value]="isEditMode ? 'Update' : 'Add New'" class="btn btn-primary" type="submit" />
			<button (click)="cancelEditMode()" *ngIf="isEditMode" class="btn btn-danger" type="button">Cancel</button>
		</div>
	</form>

	<hr />
	<div class="scroll-box">
		<table class="table">
			<thead>
				<tr>
					<th>Instrument</th>
					<th>Parent</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let instrument of instrumentsWithSubInstruments">
					<td [ngClass]="{ indented: instrument.relatedInstrumentId }">{{ instrument.instrumentName }}</td>
					<td>{{ getInstrumentName(instrument.relatedInstrumentId) }}</td>
					<td class="icon-cell">
						<a (click)="showEditMenu(instrument)" class="text-secondary" role="button"><span class="bi bi-pencil-square"></span></a>
					</td>
					<!-- TODO: Delete endpoint
        <td class="icon-cell"><a role="button" class="text-danger"><i class="bi bi-x-lg"></i></a></td>
        --></tr>
			</tbody>
		</table>
	</div>
</div>
