<div class="container-xl container-fluid sfx-top-spacing">
	<div class="sfx-center-container flex-lg-row justify-content-lg-around">
		<div class="form-wrapper text-center text-md-start">
			<h1>Create a free account</h1>
			<p class="text-muted">Get access to hundreds of unique audio files</p>
		</div>

		<div class="form-wrapper">
			<div class="bg-white p-3 shadow">
				<form (ngSubmit)="onSubmit()" [formGroup]="signUpForm">
					<h4 class="text-center text-md-start mb-4">Sign Up</h4>

					<div class="row">
						<div class="col">
							<div class="form-group">
								<label for="sfx-first-name">First Name</label>
								<input
									[ngClass]="{
										'is-invalid': isSubmitted && f['first_name'].errors,
										'is-valid': !f['first_name'].errors
									}"
									class="form-control mt-2"
									formControlName="first_name"
									id="sfx-first-name"
									placeholder="John"
									type="text"
								/>
							</div>
						</div>
						<div class="col">
							<div class="form-group">
								<label for="sfx-last-name">Last Name</label>
								<input
									[ngClass]="{
										'is-invalid': isSubmitted && f['last_name'].errors,
										'is-valid': !f['last_name'].errors
									}"
									class="form-control mt-2"
									formControlName="last_name"
									id="sfx-last-name"
									placeholder="Doe"
									type="text"
								/>
							</div>
						</div>
					</div>

					<div class="form-group mt-3">
						<label for="sfx-email">Email</label>
						<input
							[ngClass]="{
								'is-invalid': isSubmitted && f['email'].errors,
								'is-valid': !f['email'].errors
							}"
							class="form-control mt-2"
							formControlName="email"
							id="sfx-email"
							placeholder="Email"
							type="email"
						/>
						<div class="m-1 mt-0 mb-0 mr-0">
							<div>
								<small class="text-muted">We'll never share your email with anyone else.</small>
							</div>
						</div>
					</div>

					<div class="form-group mt-3">
						<label for="sfx-password">Password</label>
						<div class="input-group mt-2">
							<input
								[ngClass]="{
									'is-invalid': isSubmitted && f['password'].errors,
									'is-valid': !f['password'].errors
								}"
								[type]="showPassword ? 'text' : 'password'"
								class="form-control"
								formControlName="password"
								id="sfx-password"
								placeholder="Password"
								type="password"
							/>

							<div class="input-group-append">
								<span (click)="showPassword = !showPassword" class="input-group-text">
									<i
										[ngClass]="{
											'bi-eye': !showPassword,
											'bi-eye-slash': showPassword
										}"
										class="bi bi-eye"
									></i>
								</span>
							</div>
						</div>
						<div class="m-1 mt-0 mb-0 mr-0">
							<div>
								<small class="text-muted">Use 8 or more characters with a mix of letters, numbers, and symbols.</small>
							</div>
						</div>
					</div>

					<div class="form-group mt-3">
						<div class="form-check">
							<input class="form-check-input" id="gridCheck" type="checkbox" />
							<label class="form-check-label" for="gridCheck">
								<small>Send me tips, trends, freebies, updates & offers.</small>
							</label>
						</div>
					</div>

					<div class="form-group text-center mt-3">
						<button class="btn w-100 text-white bg-info" id="btnSignUp" type="submit">Create Your Account</button>
					</div>

					<div class="form-group text-center mt-3">
						<small>
							<span class="text-muted">Already a SoundFormX member?</span>
							<a class="text-decoration-none text-sfx-info m-1" routerLink="/sign-in">Sign in here</a>
						</small>
					</div>

					<div class="divider mt-3 w-100 bg-primary"></div>

					<div class="form-group text-center mt-3">
						<small>
							<span class="text-muted">By creating an account, you agree to the SoundFormX</span>
							<a class="text-decoration-underline text-sfx-gray m-1" routerLink="/terms-and-conditions">Terms and Conditions.</a>
						</small>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
