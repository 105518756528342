<div class="main-navbar">
	<nav class="navbar bg-primary navbar-expand-lg navbar-dark taller-navbar">
		<div>
			<button (click)="toggleNavbar()" [attr.aria-expanded]="!isCollapsed" aria-controls="navbarNav" aria-label="Toggle navigation" class="navbar-toggler mx-3" type="button">
				<span class="navbar-toggler-icon"></span>
			</button>

			<a class="navbar-brand mx-lg-3" routerLink="/">
				<img alt="SFX Official Logo" class="navbar-logo" src="./assets/logo.png" />
			</a>
		</div>
		<div class="navbar-nav flex-row position-relative" id="navbarNavRight">
			<div class="nav-item">
				<a class="nav-link position-relative" routerLink="/user/cart">
					<i class="bi bi-cart navbar-icon"></i>
					<div *ngIf="cartCount > 0" id="cart-count" class="position-absolute">{{ cartCount }}</div>
				</a>
			</div>
			<div class="nav-item">
				<div *ngIf="!isLoggedIn()">
					<span dropdown>
						<a aria-controls="basic-link-dropdown" class="nav-link" dropdownToggle role="button">
							<i class="bi bi-person-circle navbar-icon"></i>
						</a>

						<ul *dropdownMenu aria-labelledby="basic-link" class="dropdown-menu dropdown-menu-right position-absolute" role="menu">
							<li role="menuitem">
								<a class="dropdown-item" routerLink="/sign-in">Sign In</a>
							</li>
							<li class="divider dropdown-divider"></li>
							<li role="menuitem">
								<a class="dropdown-item" routerLink="/sign-up">Sign Up</a>
							</li>
						</ul>
					</span>
				</div>

				<div *ngIf="isLoggedIn()">
					<span dropdown>
						<a aria-controls="basic-link-dropdown" class="nav-link" dropdownToggle role="button">
							<i class="bi bi-person-circle navbar-icon"></i>
						</a>

						<ul *dropdownMenu aria-controls="dropdown-basic" aria-labelledby="basic-link" class="dropdown-menu dropdown-menu-right position-absolute" role="menu">
							<li role="menuitem">
								<a class="dropdown-item" routerLink="/user/account-details">Profile</a>
							</li>
							<li *ngIf="isAdmin()" class="divider dropdown-divider"></li>
							<li *ngIf="isAdmin()" role="menuitem">
								<a class="dropdown-item" routerLink="/admin">Admin Panel</a>
							</li>
							<li class="divider dropdown-divider"></li>
							<li role="menuitem">
								<a (click)="signOut()" class="dropdown-item" role="button">Sign out</a>
							</li>
						</ul>
					</span>
				</div>
			</div>
		</div>
		<div [ngClass]="{ show: !isCollapsed }" class="collapse navbar-collapse" id="navbarNav">
			<ul class="navbar-nav gap-3 ml-3 mr-auto">
				<li class="nav-item">
					<a class="nav-link text-center" routerLink="/about-us">About</a>
				</li>
				<li class="nav-item">
					<a class="nav-link text-center" routerLink="/community">Community</a>
				</li>
				<li class="nav-item">
					<a class="nav-link text-center" routerLink="/connect">Connect</a>
				</li>
			</ul>
		</div>
	</nav>

	<div class="categories">
		<div class="d-flex align-items-stretch flex-grow-1 flex-wrap gap-3">
			<a class="category text-center" routerLink="/products/soundpack" [queryParams]="{ category: 2 }">Loops</a>
			<a class="category text-center" routerLink="/products/soundpack" [queryParams]="{ category: 3 }">One-Shots</a>
			<a class="category text-center" routerLink="/products/soundpack" [queryParams]="{ category: 1 }">Soundtracks</a>
			<a class="category text-center" routerLink="/products/soundpack" [queryParams]="{ category: 6 }">Soundfonts</a>
			<a class="category text-center" routerLink="/products/soundpack" [queryParams]="{ category: 5 }">Plugins</a>
			<a class="category text-center" routerLink="/products/tutorials">Tutorials</a>
			<a class="category text-center" routerLink="/products/merchandise">Merch</a>
		</div>
		<div class="p-2">
			<div class="search-box" id="mainSearch">
				<input class="form-control" placeholder="Search..." type="text" [(ngModel)]="searchKeyword" (keyup.enter)="onSearch()" />
				<i class="bi bi-search" (click)="onSearch()"></i>
			</div>
		</div>
	</div>
</div>
