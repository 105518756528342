<div *ngIf="!uploadFileName">
	<button (click)="fileInput.click()" class="btn btn-primary w-100" type="button"><i class="bi bi-upload"></i><span class="m-2">Choose File To Upload</span></button>
</div>
<ng-template #uploadImageTooltip>
	<img [alt]="uploadFileName" class="d-block mw-100" src="{{ storageUrl }}/{{ uploadFileUrl }}" />
</ng-template>

<div *ngIf="uploadFileName">
	<button [disabled]="disabled" (click)="fileInput.click()" [tooltip]="isImage() ? uploadImageTooltip : undefined" class="btn btn-primary w-100" containerClass="opacity-100" placement="bottom" type="button">
		<div class="d-flex flex-row justify-content-between align-content-center w-100 text-white">
			<div class="text-truncate">{{ uploadFileName }}</div>
			<div class="d-flex flex-row align-content-center gap-2">
				<div *ngIf="uploading">
					<i class="bi spinner bi-arrow-clockwise"></i>
				</div>
				<div *ngIf="uploadSuccess">
					<i class="bi bi-check-circle-fill text-success"></i>
				</div>
				<div *ngIf="uploadFailure">
					<i class="bi bi-exclamation-circle-fill text-danger"></i>
				</div>
				<div *ngIf="uploading">{{ percentage }}%</div>
				<div *ngIf="!uploading">{{ uploadFileSize }}</div>
			</div>
		</div>
	</button>
</div>

<input #fileInput [disabled]="disabled" (change)="uploadFile($event)" [accept]="accept" class="d-none" type="file" />
