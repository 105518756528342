import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { CartItem } from 'src/app/interfaces/CartItem';
import { Product } from 'src/app/interfaces/Product';
import { ToastType } from 'src/app/interfaces/ToastMessage';
import { AudioplayerService } from 'src/app/services/audioplayer.service';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-sfx-product-listing',
	templateUrl: './sfx-product-listing.component.html',
	styleUrls: ['./sfx-product-listing.component.scss'],
})
export class SfxProductListingComponent implements OnInit {
	@Input() product: Product;
	productTypes: Record<number, string> = {
		1: 'bundle',
		2: 'soundpack',
		3: 'merch',
		4: 'tutorial',
		5: 'webinar',
	};
	productDefaultQuantities: Record<number, number | null> = {
		1: 1,
		2: 1,
		3: null,
		4: 1,
		5: 1,
	};
	productType: any;
	audioPlayer: HTMLAudioElement;
	isPlaying = false;
	private readonly storage_url: String = environment.storage_url;

	constructor(
		private cartService: CartService,
		private toastService: ToastService,
		private audioPlayerService: AudioplayerService,
		private userService: UserService,
		private authService: AuthService
	) {}

	ngOnInit(): void {
		if (this.product && this.product.productTypeId != undefined) {
			this.productType = this.productTypes[this.product.productTypeId as unknown as number];
		}
	}

	toggleAudioPlayback() {
		if (this !== null) {
			if (this.isPlaying) {
				this.audioPlayerService.pause();
				this.isPlaying = false;
			} else {
				const audioFile = this.getFullPreviewFileURL(this.product);
				this.audioPlayerService.play(this, audioFile);
				this.isPlaying = true;
			}
		}
	}

	getFullImageFileURL(item: Product): string {
		return `${this.storage_url}/${encodeURIComponent(item.imageFileUrl || '')}`;
	}

	getFullPreviewFileURL(item: Product) {
		return `${this.storage_url}/${encodeURIComponent(item.previewFileName || '')}`;
	}

	getFormattedPrice(): string {
		const priceInDollars = Number(this.product.price);
		if (priceInDollars == 0) {
			return 'Free';
		}
		return priceInDollars.toLocaleString('en-US', {
			style: 'currency',
			currency: 'USD',
		});
	}

	async addProductToCartIfNotOwned(): Promise<void> {
		const item: CartItem = {
			productId: this.product.productId,
			productQuantity: this.productDefaultQuantities[this.product.productTypeId as unknown as number] == 1 ? 1 : 0,
			deliveryTypeName: this.product.deliveryTypeName,
		};

		if (!this.cartService.canAddDigitalProductToCart(item)) {
			this.toastService.addToast({
				type: ToastType.INFO,
				title: 'Product Already In Cart!',
				message: 'Oops! This product is already in your cart.',
			});
			return;
		}

		if (this.authService.isAuthenticated()) {
			this.userService
				.checkUserOwnsProduct(this.product.productId || '')
				.pipe(take(1))
				.subscribe(async (res) => {
					if (res.isOwned) {
						this.toastService.addToast({
							type: ToastType.INFO,
							title: 'Product Already Owned!',
							message: 'Oops! This product is already in your library.',
						});
					} else {
						await this.addProductToCart();
					}
				});
		} else {
			await this.addProductToCart();
		}
	}

	async addProductToCart(): Promise<void> {
		const item: CartItem = {
			productId: this.product.productId,
			productQuantity: this.productDefaultQuantities[this.product.productTypeId as unknown as number] == 1 ? 1 : 0,
			deliveryTypeName: this.product.deliveryTypeName,
		};

		await this.cartService.addItem(item);
		this.toastService.addToast({
			type: ToastType.SUCCESS,
			title: 'Success',
			message: this.product.productName + ' added to cart.',
		});
	}
}
