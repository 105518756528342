import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
	selector: 'app-sfx-multiselect',
	templateUrl: './sfx-multiselect.component.html',
	styleUrls: ['./sfx-multiselect.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: SfxMultiselectComponent,
		},
	],
})
export class SfxMultiselectComponent implements OnInit, ControlValueAccessor {
	@Input() placeholder: string = 'Select...';
	@Input() showSelectAll: boolean = false;
	@Input() allowSearch: boolean = true;
	@Input() itemId: string = 'item_id';
	@Input() itemText: string = 'item_text';
	@Input() itemLimit: number | undefined = undefined;
	@Input() displayedItemsLimit: number | undefined = undefined;
	@Input() dropdownList: any[] | null = [];
	@Input() selectedItems: any[] = [];
	@Output() selectedItemsChange = new EventEmitter<any>();
	touched = false;
	disabled = false;
	dropdownSettings: IDropdownSettings = {};

	onChange = (_: string[]) => {};

	onTouched = () => {};

	ngOnInit() {
		this.dropdownSettings = {
			idField: this.itemId,
			textField: this.itemText,
			selectAllText: 'Select All',
			unSelectAllText: 'Unselect All',
			enableCheckAll: this.showSelectAll,
			itemsShowLimit: this.displayedItemsLimit,
			allowSearchFilter: this.allowSearch,
		};

		if (this.itemLimit == 1) {
			this.dropdownSettings.singleSelection = true;
		} else if (this.itemLimit) {
			this.dropdownSettings.limitSelection = this.itemLimit;
		}
	}

	onItemSelect(_: any) {
		this.selectedItemsChange.emit(this.selectedItems);
		this.markAsTouched();
		if (!this.disabled) {
			this.onItemChange(this.selectedItems);
		}
	}

	onItemDeselect(_: any) {
		this.selectedItemsChange.emit(this.selectedItems);
		this.markAsTouched();
		if (!this.disabled) {
			this.onItemChange(this.selectedItems);
		}
	}

	onItemChange(_: any) {
		let items: string[] = this.selectedItems.map((x) => {
			return x[this.itemId];
		});
		this.onChange(items);
	}

	registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any): void {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	writeValue(values: any[]): void {
		this.selectedItems = this.dropdownList?.filter((x) => values.includes(x[this.itemId])) ?? [];
	}
}
