<h3>Tutorial Details</h3>
<fieldset [formGroup]="form">
	<div class="row">
		<div class="col col-sm-6 col-lg-4 col-12 form-group" *ngIf="isTutorialFree">
			<label for="fileName">Free Tutorial Type</label>
			<select class="form-control form-select" (change)="onFreeTutorialTypeChange($event)">
				<option value="upload" [selected]="tutorialType === 'upload'">Download File</option>
				<option value="hyperlink" [selected]="tutorialType === 'hyperlink'">External Hyperlink</option>
			</select>
		</div>
		<div class="col col-sm-6 col-lg-4 col-12 form-group" *ngIf="shouldShowUpload">
			<label for="fileName">Download File *</label>
			<app-sfx-file-upload formControlName="fileName" id="fileName" [accept]="'*'" [sfxFileType]="sfxFileType.ZIP" required></app-sfx-file-upload>
			<div class="invalid-feedback">Required.</div>
		</div>
		<div class="col col-sm-6 col-lg-4 col-12 form-group" *ngIf="!shouldShowUpload">
			<label for="hyperlink">Free External Hyperlink *</label>
			<input (focus)="markAsTouched()" class="form-control" formControlName="hyperlink" id="hyperlink" placeholder="https://www.youtube.com..." type="text" required />
			<div class="invalid-feedback">Required.</div>
		</div>
	</div>
	<div class="row">
		<div class="col col-sm-6 col-lg-4 col-12 form-group">
			<label for="formatIds">Included Formats</label>
			<app-sfx-multiselect formControlName="formatIds" id="formatIds" [dropdownList]="select_formats" itemId="formatId" itemText="formatName"></app-sfx-multiselect>
		</div>

		<div class="col col-sm-6 col-lg-4 col-12 form-group">
			<label for="relatedProductIds">Related Products</label>
			<app-sfx-multiselect formControlName="relatedProductIds" id="relatedProductIds" [dropdownList]="select_products" itemId="productId" itemText="productName"></app-sfx-multiselect>
		</div>
	</div>
</fieldset>
