import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SfxAdminNavComponent } from './components/sfx-admin-nav/sfx-admin-nav.component';
import { SfxButtonComponent } from './components/sfx-button/sfx-button.component';
import { SfxCarouselComponent } from './components/sfx-carousel/sfx-carousel.component';
import { SfxCartQuantityDropdownComponent } from './components/sfx-cart-quantity-dropdown/sfx-cart-quantity-dropdown.component';
import { SfxDetailedCartComponent } from './components/sfx-detailed-cart/sfx-detailed-cart.component';
import { SfxFileUploadComponent } from './components/sfx-file-upload/sfx-file-upload.component';
import { SfxFilterRangeComponent } from './components/sfx-filter-range/sfx-filter-range.component';
import { SfxFilterTreeComponent } from './components/sfx-filter-tree/sfx-filter-tree.component';
import { SfxFilterTreeFieldComponent } from './components/sfx-filter-tree-field/sfx-filter-tree-field.component';
import { SfxFooterComponent } from './components/sfx-footer/sfx-footer.component';
import { SfxInvoiceComponent } from './components/sfx-invoice/sfx-invoice.component';
import { SfxLoadingSpinnerComponent } from './components/sfx-loading-spinner/sfx-loading-spinner.component';
import { SfxMultiselectComponent } from './components/sfx-multiselect/sfx-multiselect.component';
import { SfxNavbarComponent } from './components/sfx-navbar/sfx-navbar.component';
import { SfxPaginationComponent } from './components/sfx-pagination/sfx-pagination.component';
import { SfxProductDetailedComponent } from './components/sfx-product-detailed/sfx-product-detailed.component';
import { SfxProductFilterComponent } from './components/sfx-product-filter/sfx-product-filter.component';
import { SfxProductListingComponent } from './components/sfx-product-listing/sfx-product-listing.component';
import { SfxProductSortComponent } from './components/sfx-product-sort/sfx-product-sort.component';
import { SfxRichTextEditorComponent } from './components/sfx-rich-text-editor/sfx-rich-text-editor.component';
import { SfxSuggestionsComponent } from './components/sfx-suggestions/sfx-suggestions.component';
import { SfxToastComponent } from './components/sfx-toast/sfx-toast.component';
import { SfxToasterComponent } from './components/sfx-toaster/sfx-toaster.component';
import { SfxUserNavComponent } from './components/sfx-user-nav/sfx-user-nav.component';
import { ResponseInterceptor } from './interceptors/response.interceptor';
import { TimeoutInterceptor } from './interceptors/timeout.interceptor';
import { AddCouponComponent } from './pages/admin/add-coupon/add-coupon.component';
import { AddProductComponent } from './pages/admin/add-product/add-product.component';
import { CategoriesModalComponent } from './pages/admin/add-product/categories-modal/categories-modal.component';
import { DeliveryMethodModalComponent } from './pages/admin/add-product/delivery-method-modal/delivery-method-modal.component';
import { FormatsModalComponent } from './pages/admin/add-product/formats-modal/formats-modal.component';
import { GenresModalComponent } from './pages/admin/add-product/genres-modal/genres-modal.component';
import { InstrumentsFormComponent } from './pages/admin/add-product/instruments-form/instruments-form.component';
import { InstrumentsModalComponent } from './pages/admin/add-product/instruments-modal/instruments-modal.component';
import { MerchandiseFormComponent } from './pages/admin/add-product/merchandise-form/merchandise-form.component';
import { TutorialsFormComponent } from './pages/admin/add-product/tutorials-form/tutorials-form.component';
import { AdminComponent } from './pages/admin/admin.component';
import { CustomersComponent } from './pages/admin/customers/customers.component';
import { DashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { EditHomePageComponent } from './pages/admin/edit-home-page/edit-home-page.component';
import { AddPageComponent } from './pages/admin/edit-page/add-page.component';
import { ManageCouponsComponent } from './pages/admin/manage-coupons/manage-coupons.component';
import { ManageOrdersComponent } from './pages/admin/manage-orders/manage-orders.component';
import { ManagePagesComponent } from './pages/admin/manage-pages/manage-pages.component';
import { ManageProductsComponent } from './pages/admin/manage-products/manage-products.component';
import { ViewCustomerComponent } from './pages/admin/view-customer/view-customer.component';
import { AccountDetailsComponent } from './pages/customer/account-details/account-details.component';
import { AddressDetailsComponent } from './pages/customer/address-details/address-details.component';
import { BrowseProductsComponent } from './pages/customer/browse-products/browse-products.component';
import { CheckoutSuccessComponent } from './pages/customer/checkout-success/checkout-success.component';
import { ContactDetailsComponent } from './pages/customer/contact-details/contact-details.component';
import { ContactUsComponent } from './pages/customer/contact-us/contact-us.component';
import { CreateNewPasswordComponent } from './pages/customer/create-new-password/create-new-password.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { HomePageComponent } from './pages/customer/home-page/home-page.component';
import { MyLibraryComponent } from './pages/customer/my-library/my-library.component';
import { MyOrdersComponent } from './pages/customer/my-orders/my-orders.component';
import { OrderCheckoutComponent } from './pages/customer/order-checkout/order-checkout.component';
import { ProductDetailsComponent } from './pages/customer/product-details/product-details.component';
import { ResetPasswordComponent } from './pages/customer/reset-password/reset-password.component';
import { ShoppingCartComponent } from './pages/customer/shopping-cart/shopping-cart.component';
import { SignInComponent } from './pages/customer/sign-in/sign-in.component';
import { SignUpComponent } from './pages/customer/sign-up/sign-up.component';
import { StaticPageComponent } from './pages/customer/static-page/static-page.component';
import { ConnectComponent } from './pages/customer/static-pages/connect/connect.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

@NgModule({
	declarations: [
		AppComponent,
		SfxButtonComponent,
		SfxNavbarComponent,
		SfxFooterComponent,
		SfxCarouselComponent,
		SfxMultiselectComponent,
		HomePageComponent,
		ResetPasswordComponent,
		SignUpComponent,
		SignInComponent,
		SfxFileUploadComponent,
		BrowseProductsComponent,
		ProductDetailsComponent,
		AccountDetailsComponent,
		AddressDetailsComponent,
		ContactDetailsComponent,
		MyOrdersComponent,
		MyLibraryComponent,
		SfxUserNavComponent,
		CustomerComponent,
		AdminComponent,
		AddProductComponent,
		ShoppingCartComponent,
		SfxAdminNavComponent,
		DashboardComponent,
		ManageOrdersComponent,
		ManagePagesComponent,
		CustomersComponent,
		ManageProductsComponent,
		ViewCustomerComponent,
		SfxPaginationComponent,
		SfxPaginationComponent,
		SfxRichTextEditorComponent,
		CreateNewPasswordComponent,
		SfxProductListingComponent,
		SfxDetailedCartComponent,
		SfxToastComponent,
		SfxToasterComponent,
		SfxProductSortComponent,
		SfxProductDetailedComponent,
		SfxDetailedCartComponent,
		SfxProductFilterComponent,
		InstrumentsFormComponent,
		InstrumentsModalComponent,
		GenresModalComponent,
		CheckoutSuccessComponent,
		OrderCheckoutComponent,
		AddCouponComponent,
		ManageCouponsComponent,
		SfxLoadingSpinnerComponent,
		MerchandiseFormComponent,
		SfxFilterTreeComponent,
		SfxFilterTreeFieldComponent,
		SfxInvoiceComponent,
		SfxCartQuantityDropdownComponent,
		NotFoundComponent,
		TutorialsFormComponent,
		ContactUsComponent,
		SfxFilterRangeComponent,
		AddPageComponent,
		ConnectComponent,
		StaticPageComponent,
		EditHomePageComponent,
		SfxSuggestionsComponent,
		DeliveryMethodModalComponent,
		CategoriesModalComponent,
		FormatsModalComponent,
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		HttpClientModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		TooltipModule.forRoot(),
		ButtonsModule.forRoot(),
		CarouselModule.forRoot(),
		BrowserAnimationsModule,
		BsDropdownModule.forRoot(),
		NgMultiSelectDropDownModule.forRoot(),
		ModalModule.forRoot(),
		EditorModule,
		TabsModule.forRoot(),
		NgbModule,
		ProgressbarModule.forRoot(),
		TooltipModule.forRoot(),
		TableModule,
		PaginatorModule,
		TagModule,
		BsDatepickerModule.forRoot(),
		AutoCompleteModule,
	],
	providers: [
		{ provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ResponseInterceptor,
			multi: true,
		},
		{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
