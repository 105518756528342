<div class="container sfx-top-spacing" xmlns="http://www.w3.org/1999/html">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item"><a class="text-info" routerLink="/admin">Dashboard</a></li>
			<li class="breadcrumb-item"><a class="text-info" routerLink="/admin/pages">Pages</a></li>
			<li aria-current="page" class="breadcrumb-item active">Edit Page</li>
		</ol>
	</nav>

	<h1>Edit Home Page</h1>
	<h3>Edit Carousel</h3>
	<form (ngSubmit)="onSubmit()" [formGroup]="form">
		<div class="d-flex align-items-end gap-2">
			<div class="form-group flex-grow-1">
				<label class="form-label" for="picture">Picture</label>
				<app-sfx-file-upload [accept]="'image/*'" [sfxFileType]="sfxFileType.PREVIEW_IMAGE" formControlName="imageFileUrl" id="picture"></app-sfx-file-upload>
			</div>
			<div class="form-group flex-grow-1">
				<label class="form-label" for="altText">Alt Text</label>
				<input class="form-control" formControlName="altText" id="altText" placeholder="Descriptive Text..." required type="text" />
			</div>
			<div class="form-group flex-grow-1">
				<label class="form-label" for="redirectLink">Redirect Link</label>
				<input class="form-control" formControlName="redirectLink" id="redirectLink" placeholder="/relative-url or https://external-url.com..." required type="text" />
			</div>
			<button [disabled]="!form.valid" class="btn btn-primary" type="submit">
				{{ isEditMode ? "Update" : "Add New" }}
			</button>
			<button (click)="cancelEditMode()" *ngIf="isEditMode" class="btn btn-danger" type="button">Cancel</button>
		</div>
	</form>

	<hr />
	<table class="table">
		<thead>
			<tr>
				<th style="width: 25%">Picture</th>
				<th style="width: 25%">Alt Text</th>
				<th>Redirect Link</th>
				<th></th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let carouselItem of carouselItems">
				<td><img [src]="storage_url + '/' + carouselItem.imageFileName" style="width: 100%" [alt]="carouselItem.altText" /></td>
				<td>{{ carouselItem.altText }}</td>
				<td>
					<a [href]="carouselItem.redirectLink">{{ carouselItem.redirectLink }}</a>
				</td>
				<td class="icon-cell">
					<a (click)="showEditMenu(carouselItem)" class="text-secondary" role="button"><span class="bi bi-pencil-square"></span></a>
				</td>
				<td class="icon-cell">
					<a (click)="deleteItem(carouselItem)" role="button" class="text-danger"><i class="bi bi-x-lg"></i></a>
				</td>
			</tr>
		</tbody>
	</table>
</div>
