import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Order } from 'src/app/interfaces/Order';
import { OrderService } from 'src/app/services/order.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-sfx-invoice',
	templateUrl: './sfx-invoice.component.html',
	styleUrls: ['./sfx-invoice.component.scss'],
})
export class SfxInvoiceComponent implements OnInit {
	@Input() showModalId: string;
	@Input() showModal: boolean;
	order: Order;
	dataLoaded: boolean;
	private readonly storageUrl: string = environment.storage_url;

	constructor(private orderService: OrderService) {}

	ngOnInit(): void {
		console.log(this.showModalId);
		this.dataLoaded = false;
		this.orderService.getOrder(this.showModalId).subscribe({
			next: (res: any) => {
				console.log(res);
				this.order = res;
				this.dataLoaded = true;
			},
		});
	}

	openModal(): void {
		const modal: HTMLElement | null = document.getElementById('invoice-modal');
		if (modal && this.showModal) {
			modal.style.display = 'block';
		}
	}

	closeModal(): void {
		const modal: HTMLElement | null = document.getElementById('invoice-modal');
		if (modal && !this.showModal) {
			modal.style.display = 'none';
		}
	}

	getFullImageFileURL(imageFileUrl: string): string {
		return `${this.storageUrl}/${imageFileUrl || ''}`;
	}

	Number(num: any): number {
		return Number(num);
	}
}
