import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { SFXHTMLContent } from '../../../interfaces/SFXHTMLContent';
import { ToastPayload, ToastType } from '../../../interfaces/ToastMessage';
import { StaticPagesService } from '../../../services/static-pages.service';
import { ToastService } from '../../../services/toast.service';
import { noWhiteSpaceValidator } from '../../../validators/no-white-space.validator';

@Component({
	selector: 'app-edit-page',
	templateUrl: './add-page.component.html',
	styleUrls: ['./add-page.component.scss'],
})
export class AddPageComponent implements OnInit {
	isEditMode: boolean = false;
	pageTitle: string;
	id: string;
	showErrors: boolean = false;
	form: FormGroup = this.formBuilder.group({
		pageURL: ['', [Validators.required, noWhiteSpaceValidator()]],
		pageTitle: ['', Validators.required],
		content: [''],
	});

	constructor(
		private staticPagesService: StaticPagesService,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: FormBuilder,
		private toastService: ToastService
	) {}

	async ngOnInit(): Promise<void> {
		let params = await firstValueFrom(this.route.params);
		this.id = params['id'];
		if (this.id) this.isEditMode = true;

		await this.loadEditForm();
	}

	async loadEditForm() {
		if (this.isEditMode) {
			let result = await lastValueFrom(this.staticPagesService.getStaticContent(this.id));
			result.pageURL = this.id;

			this.form.patchValue({
				pageURL: result.pageURL ?? '',
				pageTitle: result.pageTitle ?? '',
				content: result.htmlContent ?? '',
			});
		}
	}

	async onSubmit() {
		if (!this.form.valid) {
			this.showErrors = true;
			return;
		}

		let result: SFXHTMLContent = {
			pageURL: this.form.get('pageURL')?.value ?? '',
			pageTitle: this.form.get('pageTitle')?.value ?? '',
			htmlContent: this.form.get('content')?.value ?? '',
		};

		console.log(result);
		if (this.isEditMode) {
			await this.updateStaticContent(result);
		} else {
			await this.addStaticContent(result);
		}
	}

	private async addStaticContent(result: SFXHTMLContent) {
		this.staticPagesService.addStaticContent(result).subscribe({
			next: this.alertSuccess(result.pageTitle, 'Page', 'created'),
			error: this.alertFailure(result.pageTitle, 'Page', 'create'),
		});
	}

	private async updateStaticContent(result: SFXHTMLContent) {
		this.staticPagesService.updateStaticContent(this.id, result).subscribe({
			next: this.alertSuccess(result.pageTitle, 'Page', 'updated'),
			error: this.alertFailure(result.pageTitle, 'Page', 'update'),
		});
	}

	deletePage() {
		let shouldDelete = confirm('Are you sure you want to permanently delete this page? This action cannot be undone.');

		if (shouldDelete && this.id) {
			let pageTitle = this.form.get('pageTitle')?.value;
			this.staticPagesService.deleteStaticContent(this.id).subscribe({
				next: this.alertSuccess(pageTitle, 'Page', 'deleted'),
				error: this.alertFailure(pageTitle, 'Page', 'delete'),
			});
		}
	}

	private alertFailure(name: string | undefined, type: string, action: string) {
		return (error: any) => {
			let toast: ToastPayload = {
				title: `${type} failed to ${action}.`,
				type: ToastType.ERROR,
				message: name + ' had errors: ' + error?.message,
			};
			this.toastService.addToast(toast);
		};
	}

	private alertSuccess(name: string | undefined, type: string, action: string) {
		return async (_: any) => {
			let toast: ToastPayload = {
				title: `${type} ${action}!`,
				type: ToastType.SUCCESS,
				message: name + ` was ${action} successfully.`,
			};
			this.toastService.addToast(toast);
			await this.router.navigate(['admin', 'pages']);
		};
	}
}
