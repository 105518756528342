import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
	selector: 'app-sfx-admin-nav',
	templateUrl: './sfx-admin-nav.component.html',
	styleUrls: ['./sfx-admin-nav.component.scss'],
})
export class SfxAdminNavComponent implements OnInit {
	isCollapsed = true;

	constructor(private authService: AuthService) {}

	ngOnInit(): void {}

	isLoggedIn(): boolean {
		return this.authService.isAuthenticated();
	}

	signOut(): void {
		this.authService.logout();
	}

	toggleNavbar() {
		this.isCollapsed = !this.isCollapsed;
	}
}
