import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SFXUserGroup } from './enums/UserGroup';
import { AuthGuard } from './guards/auth.guard';
import { AddCouponComponent } from './pages/admin/add-coupon/add-coupon.component';
import { AddProductComponent } from './pages/admin/add-product/add-product.component';
import { AdminComponent } from './pages/admin/admin.component';
import { CustomersComponent } from './pages/admin/customers/customers.component';
import { EditHomePageComponent } from './pages/admin/edit-home-page/edit-home-page.component';
import { AddPageComponent } from './pages/admin/edit-page/add-page.component';
import { ManageCouponsComponent } from './pages/admin/manage-coupons/manage-coupons.component';
import { ManageOrdersComponent } from './pages/admin/manage-orders/manage-orders.component';
import { ManagePagesComponent } from './pages/admin/manage-pages/manage-pages.component';
import { ManageProductsComponent } from './pages/admin/manage-products/manage-products.component';
import { ViewCustomerComponent } from './pages/admin/view-customer/view-customer.component';
import { AccountDetailsComponent } from './pages/customer/account-details/account-details.component';
import { AddressDetailsComponent } from './pages/customer/address-details/address-details.component';
import { BrowseProductsComponent } from './pages/customer/browse-products/browse-products.component';
import { CheckoutSuccessComponent } from './pages/customer/checkout-success/checkout-success.component';
import { ContactDetailsComponent } from './pages/customer/contact-details/contact-details.component';
import { ContactUsComponent } from './pages/customer/contact-us/contact-us.component';
import { CreateNewPasswordComponent } from './pages/customer/create-new-password/create-new-password.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { HomePageComponent } from './pages/customer/home-page/home-page.component';
import { MyLibraryComponent } from './pages/customer/my-library/my-library.component';
import { MyOrdersComponent } from './pages/customer/my-orders/my-orders.component';
import { OrderCheckoutComponent } from './pages/customer/order-checkout/order-checkout.component';
import { ProductDetailsComponent } from './pages/customer/product-details/product-details.component';
import { ResetPasswordComponent } from './pages/customer/reset-password/reset-password.component';
import { ShoppingCartComponent } from './pages/customer/shopping-cart/shopping-cart.component';
import { SignInComponent } from './pages/customer/sign-in/sign-in.component';
import { SignUpComponent } from './pages/customer/sign-up/sign-up.component';
import { StaticPageComponent } from './pages/customer/static-page/static-page.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

const routes: Routes = [
	{ path: 'not-found', component: NotFoundComponent },
	{
		path: 'admin',
		component: AdminComponent,
		canActivate: [AuthGuard],
		data: {
			role: [SFXUserGroup.Admin],
		},
		children: [
			{ path: '', redirectTo: 'products', pathMatch: 'full' },
			{ path: 'products', component: ManageProductsComponent },
			{ path: 'products/add', component: AddProductComponent },
			{ path: 'products/edit/:id', component: AddProductComponent },
			{ path: 'orders', component: ManageOrdersComponent },
			{ path: 'orders/:id', component: ManageOrdersComponent },
			{ path: 'customers', component: CustomersComponent },
			{ path: 'customers/:id', component: ViewCustomerComponent },
			{ path: 'customers/:id', component: CustomersComponent },
			{ path: 'pages', component: ManagePagesComponent },
			{ path: 'pages/home', component: EditHomePageComponent },
			{ path: 'pages/add', component: AddPageComponent },
			{ path: 'pages/edit/:id', component: AddPageComponent },
			{ path: 'coupons', component: ManageCouponsComponent },
			{ path: 'coupons/add', component: AddCouponComponent },
			{ path: 'coupons/edit/:code', component: AddCouponComponent },
		],
	},
	{
		path: '',
		component: CustomerComponent,
		children: [
			{ path: '', component: HomePageComponent },
			{
				path: 'products',
				children: [
					{
						path: ':productType',
						component: BrowseProductsComponent,
						children: [],
					},
					{ path: 'details/:productId', component: ProductDetailsComponent },
				],
			},
			{
				path: 'reset-password/new',
				canActivate: [AuthGuard],
				data: {
					role: [SFXUserGroup.Any],
				},
				component: CreateNewPasswordComponent,
			},
			{
				path: '',
				canActivate: [AuthGuard],
				data: {
					role: [SFXUserGroup.Unauthenticated],
				},
				children: [
					{ path: 'reset-password', component: ResetPasswordComponent },
					{ path: 'sign-up', component: SignUpComponent },
					{ path: 'sign-in', component: SignInComponent },
				],
			},
			{
				path: 'user/cart',
				canActivate: [AuthGuard],
				data: {
					role: [SFXUserGroup.Any],
				},
				component: ShoppingCartComponent,
			},
			{
				path: 'checkout/success',
				canActivate: [AuthGuard],
				data: {
					role: [SFXUserGroup.Account, SFXUserGroup.Admin],
				},
				component: CheckoutSuccessComponent,
			},
			{
				path: 'user',
				canActivate: [AuthGuard],
				data: {
					role: [SFXUserGroup.Account, SFXUserGroup.Admin],
				},
				children: [
					{ path: 'account-details', component: AccountDetailsComponent },
					{ path: 'address-details', component: AddressDetailsComponent },
					{ path: 'contact-details', component: ContactDetailsComponent },
					{ path: 'my-orders', component: MyOrdersComponent },
					{ path: 'my-library', component: MyLibraryComponent },
					{ path: 'checkout', component: OrderCheckoutComponent },
				],
			},
			{ path: 'contact-us', component: ContactUsComponent },
			// Static page needs to be at the very end
			{ path: ':pageURL', component: StaticPageComponent },
		],
	},
	{ path: '**', component: NotFoundComponent },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'enabledBlocking',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
