import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { parseStringToUserGroupRole, SFXUserGroup } from 'src/app/enums/UserGroup';
import { ToastType } from 'src/app/interfaces/ToastMessage';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { ToastService } from 'src/app/services/toast.service';
import { PasswordValidator } from 'src/app/validators/password.validator';

@Component({
	selector: 'app-sign-in',
	templateUrl: './sign-in.component.html',
	styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
	signInForm: FormGroup;
	showPassword = false;
	isSubmitted = false;

	constructor(
		private route: ActivatedRoute,
		private readonly titleService: Title,
		private readonly formBuilder: FormBuilder,
		private readonly authService: AuthService,
		private readonly router: Router,
		private readonly cartService: CartService,
		private toastService: ToastService
	) {}

	// convenience getter for easy access to form fields
	get f() {
		return this.signInForm.controls;
	}

	ngOnInit() {
		this.route.data.subscribe((data) => {
			this.titleService.setTitle(data['title']);
		});

		this.signInForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required, PasswordValidator.complexity]],
		});
	}

	onSubmit() {
		this.isSubmitted = true;

		if (!this.signInForm || this.signInForm.invalid) {
			return;
		}

		const email = this.f['email'].value;
		const password = this.f['password'].value;
		this.authService.signIn(email, password).subscribe({
			next: (response) => {
				this.cartService.transferLocalCartToDB();
				if (parseStringToUserGroupRole(response.userGroup) === SFXUserGroup.Admin) {
					this.router.navigate(['/admin']);
				} else {
					this.router.navigate(['/']);
				}
			},
			error: (error) => {
				console.log('Error signing in:', error);
				this.toastService.addToast({
					type: ToastType.ERROR,
					title: 'Login Failed',
					message: 'Invalid login credentials. Please try again.',
				});
			},
		});
	}
}
