<div class="container sfx-top-spacing">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item"><a class="text-info" routerLink="/admin">Dashboard</a></li>
			<li class="breadcrumb-item"><a class="text-info" routerLink="/admin/products">Products</a></li>
			<li *ngIf="!isEditMode" aria-current="page" class="breadcrumb-item active">Add Product</li>
			<li *ngIf="isEditMode" aria-current="page" class="breadcrumb-item active">Edit Product</li>
		</ol>
	</nav>

	<form [formGroup]="addProductForm" [ngClass]="{ 'invalid-form': showErrors, 'admin-form': true }">
		<div class="float-end">
			<button [disabled]="!(addProductForm.touched && addProductForm.dirty)" class="btn btn-primary" type="button" (click)="addProduct()">
				{{ isEditMode ? "Save Changes" : "Add Product" }}
			</button>
			<button type="button" class="btn btn-danger ms-2" *ngIf="isEditMode" (click)="deleteProduct()">Delete</button>
		</div>
		<h1 *ngIf="!isEditMode">Add Product</h1>
		<h1 *ngIf="isEditMode">Edit Product</h1>
		<hr />
		<div *ngIf="showErrors && addProductForm.invalid" class="alert alert-danger">Error: Missing required fields.</div>
		<h3>Basic Product Info</h3>
		<div class="row align-items-start">
			<div class="col col-sm-6 col-md-4 col-12 form-group">
				<label for="product-name">Product Name *</label>
				<input class="form-control" formControlName="productName" id="product-name" placeholder="80's Pack..." required type="text" />
				<div class="invalid-feedback">Required.</div>
			</div>

			<div class="col col-sm-6 col-md-4 col-12 form-group">
				<label for="product-type">Product Type <sup aria-label="Required">*</sup></label>
				<select class="form-control form-select" formControlName="productTypeId" id="product-type" required>
					<option disabled selected value="">Select...</option>
					<!--option *ngFor="let x of select_product_types" [value]="x.productTypeId">
						{{ x.productTypeName }}
					</option-->
					<option [value]="'2'">Sound Pack</option>
					<option [value]="'3'">Merchandise</option>
					<option [value]="'4'">Tutorial</option>
				</select>
				<div class="invalid-feedback">Required.</div>
			</div>

			<div class="col col-sm-6 col-md-4 col-12 form-group">
				<label for="price">Price</label>
				<div class="input-group">
					<span class="input-group-text">$</span>
					<input (change)="onPriceChange()" class="form-control" formControlName="price" id="price" placeholder="0.00" type="number" />
				</div>
			</div>

			<div class="col col-sm-6 col-md-4 col-12 form-group">
				<label for="product-image">Product Image</label>
				<app-sfx-file-upload [accept]="'image/*'" [sfxFileType]="sfxFileType.PREVIEW_IMAGE" formControlName="imageFileUrl" id="product-image"></app-sfx-file-upload>
			</div>

			<div class="col col-sm-6 col-md-4 col-12 form-group">
				<label for="delivery-method">Delivery Method <sup aria-label="Required">*</sup></label>
				<select class="form-control form-select" formControlName="deliveryTypeId" id="delivery-method" required>
					<option value="" disabled selected>Select...</option>
					<option *ngFor="let delivery_method of select_delivery_methods" [value]="delivery_method.deliveryTypeId">{{ delivery_method.deliveryTypeName }} ({{ delivery_method.deliveryPrice | currency }})</option>
				</select>
				<div class="invalid-feedback">Required.</div>
				<div class="form-text">
					<a (click)="openDeliveryMethodModal()" class="link-info small" role="button">Manage Methods</a>
				</div>
			</div>

			<div class="col col-sm-6 col-md-4 col-12 form-group align-self-center">
				<div class="form-check pb-1">
					<input class="form-check-input" formControlName="isFeatured" id="featured-product" type="checkbox" />
					<label class="form-check-label" for="featured-product">Feature Product <span class="bi bi-info-circle-fill text-muted small" tooltip="Show product on the home page and first when browsing."></span></label>
				</div>
			</div>
		</div>
		<div *ngIf="shouldShowOptions(ProductTypes.PACK)">
			<hr class="mt-4 mb-4" />

			<h3>Sound Pack Details</h3>

			<div class="row">
				<div class="col col-sm-6 col-md-4 col-12 form-group">
					<label for="product-category">Category <sup aria-label="Required">*</sup></label>
					<select class="form-control form-select" formControlName="categoryId" id="product-category" required>
						<option disabled selected value="">Select...</option>
						<option *ngFor="let x of select_categories" [value]="x.categoryId">
							{{ x.categoryName }}
						</option>
					</select>
					<div class="invalid-feedback">Required.</div>
					<div class="form-text">
						<a (click)="openCategoriesModal()" class="link-info small" role="button">Manage Categories</a>
					</div>
				</div>

				<div class="col col-sm-6 col-md-4 col-12 form-group">
					<label for="download-file">Download File <sup aria-label="Required">*</sup></label>
					<app-sfx-file-upload [accept]="'*'" [sfxFileType]="sfxFileType.ZIP" formControlName="downloadFileUrl" id="download-file" required></app-sfx-file-upload>
					<div class="invalid-feedback">Required.</div>
				</div>

				<div class="col col-sm-6 col-md-4 col-12 form-group">
					<label for="audio-preview">Audio Preview</label>
					<app-sfx-file-upload [accept]="'.mp3'" [sfxFileType]="sfxFileType.PREVIEW_AUDIO" formControlName="audioPreviewFileUrl" id="audio-preview"></app-sfx-file-upload>
				</div>

				<div class="col col-sm-6 col-md-4 col-12 form-group">
					<label for="included-formats">Included Formats</label>
					<app-sfx-multiselect [dropdownList]="select_formats" formControlName="formatIds" id="included-formats" itemId="formatId" itemText="formatName"></app-sfx-multiselect>
					<div class="form-text">
						<a (click)="openFormatsModal()" class="link-info small" role="button">Manage Formats</a>
					</div>
				</div>

				<div class="col col-sm-6 col-md-4 col-12 form-group">
					<label for="resolution">Resolution</label>
					<input class="form-control" formControlName="resolution" id="resolution" placeholder="24 KBPS..." type="text" />
				</div>

				<div class="col col-sm-6 col-md-4 col-12 form-group">
					<label for="bpm-input">BPM</label>
					<input class="form-control" formControlName="bpm" id="bpm-input" placeholder="125..." type="number" />
				</div>

				<div class="col col-sm-6 col-md-4 col-12 form-group">
					<label for="genre-input">Genre</label>
					<app-sfx-multiselect [dropdownList]="select_genres" (selectedItemsChange)="onGenreChange($event)" formControlName="genreIds" id="genre-input" itemId="genreId" itemText="genreName"></app-sfx-multiselect>
					<div class="form-text">
						<a (click)="openGenresModal()" class="link-info small" role="button">Manage Genres</a>
					</div>
				</div>

				<div class="col col-sm-6 col-md-4 col-12 form-group">
					<label for="style-input">Style</label>
					<app-sfx-suggestions formControlName="style" id="style-input" [suggestions]="styleSuggestions" [multiple]="true" placeholder="Disco Inferno, Bruno Mars..."></app-sfx-suggestions>
				</div>

				<div class="col col-sm-6 col-md-4 col-12 form-group">
					<label for="brand">Brand</label>
					<app-sfx-suggestions formControlName="brand" id="brand" [suggestions]="brandSuggestions" placeholder="SoundFormX..."></app-sfx-suggestions>
				</div>
			</div>

			<hr class="mt-4 mb-4" />

			<app-instruments-form formControlName="instruments"></app-instruments-form>
		</div>
		<div *ngIf="shouldShowOptions(ProductTypes.MERCH)">
			<hr class="mt-4 mb-4" />
			<app-merchandise-form formControlName="merchandise"></app-merchandise-form>
		</div>
		<div *ngIf="shouldShowOptions(ProductTypes.TUTORIAL)">
			<hr class="mt-4 mb-4" />
			<app-tutorials-form [price]="price" formControlName="tutorial"></app-tutorials-form>
		</div>
		<hr class="mt-4 mb-4" />
		<div class="row">
			<div class="col form-group">
				<label for="description">Description</label>
				<app-sfx-rich-text-editor [(content)]="descriptionContent" formControlName="description" id="description"></app-sfx-rich-text-editor>
			</div>
		</div>
		<div class="row mt-5 mb-5">
			<div class="col">
				<button [disabled]="!addProductForm.touched" class="btn btn-primary w-100" type="button" (click)="addProduct()">
					{{ isEditMode ? "Save Changes" : "Add Product" }}
				</button>
			</div>
		</div>
	</form>
</div>
