import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Product } from 'src/app/interfaces/Product';
import { ProductService } from 'src/app/services/product.service';

@Component({
	selector: 'app-home-page',
	templateUrl: './home-page.component.html',
	styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
	loaded: boolean = false;
	totalPages = 0;
	totalProducts = 0;
	products: any[] = [];

	constructor(private readonly titleService: Title, private productsService: ProductService) {
		this.loaded = false;

		this.productsService.getBatchFeaturedProductsSorted(1, 99999).subscribe((resp: HttpResponse<Product[]>) => {
			this.totalPages = Number(resp.headers.get('X-Total-Pages')) || 0;
			this.totalProducts = Number(resp.headers.get('X-Total-Count')) || 0;
			const data = resp.body || [];
			this.products = data;
			this.loaded = true;
		});
	}

	ngOnInit(): void {
		this.titleService.setTitle('SoundFormX Audio Library');
	}
}
