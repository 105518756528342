import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { StaticPagesService } from '../../../services/static-pages.service';

@Component({
	selector: 'app-static-page',
	templateUrl: './static-page.component.html',
	styleUrls: ['./static-page.component.scss'],
})
export class StaticPageComponent implements OnInit, OnDestroy {
	pageUrl: string;
	pageTitle: string;
	htmlContent: SafeHtml;
	loaded: boolean = false;
	subscriptions: Subscription[] = [];

	constructor(
		private readonly titleService: Title,
		private sanitizer: DomSanitizer,
		private staticPagesService: StaticPagesService,
		private route: ActivatedRoute,
		private router: Router
	) {}

	async ngOnInit(): Promise<void> {
		this.route.params.subscribe((params) => this.onParamsChange(params));
	}

	ngOnDestroy() {
		for (let subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	private navigateToErrorPage() {
		this.router.navigate(['not-found'], { skipLocationChange: true });
	}

	onParamsChange(params: Params) {
		this.pageUrl = params['pageURL'];
		if (this.pageUrl) {
			let sub = this.staticPagesService.getStaticContent(this.pageUrl).subscribe({
				next: (result) => {
					this.pageTitle = result.pageTitle;
					this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(result.htmlContent);
					this.loaded = true;
					this.titleService.setTitle(`${this.pageTitle} | SoundFormX`);
				},
				error: (_) => {
					this.navigateToErrorPage();
				},
			});

			this.subscriptions.push(sub);
		} else {
			this.navigateToErrorPage();
		}
	}
}
