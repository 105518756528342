import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastPayload } from 'src/app/interfaces/ToastMessage';
import { ToastService } from 'src/app/services/toast.service';

@Component({
	selector: 'app-sfx-toaster',
	templateUrl: './sfx-toaster.component.html',
	styleUrls: ['./sfx-toaster.component.scss'],
})
export class SfxToasterComponent implements OnInit {
	currentToasts: ToastPayload[] = [];

	constructor(private toastService: ToastService, private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.subscribeToToasts();
	}

	subscribeToToasts() {
		this.toastService.toastEvents.subscribe((toasts) => {
			const currentToast: ToastPayload = {
				type: toasts.type,
				title: toasts.title,
				message: toasts.message,
			};
			this.currentToasts.push(currentToast);
			this.cdr.detectChanges();
		});
	}

	dispose(index: number) {
		this.currentToasts.splice(index, 1);
		this.cdr.detectChanges();
	}
}
