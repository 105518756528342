<div class="container sfx-top-spacing">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item"><a class="text-info" routerLink="/admin">Dashboard</a></li>
			<li class="breadcrumb-item"><a class="text-info" routerLink="/admin/pages">Pages</a></li>
			<li aria-current="page" class="breadcrumb-item active">Edit Page</li>
		</ol>
	</nav>

	<form class="admin-form" [formGroup]="form" (submit)="onSubmit()" [ngClass]="{ 'invalid-form': showErrors }">
		<div>
			<div class="float-end">
				<button class="btn btn-primary" type="submit">{{ isEditMode ? "Save Changes" : "Add Page" }}</button>
				<button type="button" class="btn btn-danger ms-2" *ngIf="isEditMode" (click)="deletePage()">Delete</button>
			</div>
			<h1>{{ isEditMode ? "Edit" : "Add" }} Page</h1>
		</div>
		<hr />
		<div class="row">
			<div class="col form-group">
				<label for="pageTitle">Page Title <sup aria-label="Required">*</sup></label>
				<input class="form-control" formControlName="pageTitle" id="pageTitle" placeholder="My Custom Page..." type="text" required />
				<div class="invalid-feedback">Required.</div>
			</div>

			<div class="col col-12 col-md-6 form-group">
				<label for="pageURL">URL <sup aria-label="Required">*</sup></label>
				<div class="input-group">
					<span class="input-group-text">/</span>
					<input class="form-control" formControlName="pageURL" id="pageURL" placeholder="example..." type="text" required />
					<div class="invalid-feedback">
						<div *ngIf="form.get('pageURL')?.hasError('required')">Required.</div>
						<div *ngIf="form.get('pageURL')?.hasError('whiteSpace')">URL should not have white space.</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row mt-5">
			<div class="col">
				<app-sfx-rich-text-editor formControlName="content"></app-sfx-rich-text-editor>
			</div>
		</div>

		<div class="row mt-5 mb-5">
			<div class="col">
				<button class="btn btn-primary w-100" type="submit">{{ isEditMode ? "Save Changes" : "Add Page" }}</button>
			</div>
		</div>
	</form>
</div>
