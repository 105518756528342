<div class="container sfx-top-spacing">
	<div class="row">
		<div class="col-md-9 col-lg-3 mb-3 mb-lg-0">
			<app-sfx-user-nav></app-sfx-user-nav>
		</div>
		<div class="col-sm-12 col-md-8 col-lg-9 col-xl-9">
			<h1>Contact Preferences</h1>
			<div *ngIf="this.campaigns.length < 1" class="text-center">
				<app-sfx-loading-spinner></app-sfx-loading-spinner>
			</div>
			<div *ngIf="this.campaigns.length > 0">
				<div class="card shadow-sm bg-light mt-4" *ngFor="let campaign of this.campaigns">
					<div class="card-body">
						<div class="row">
							<div class="col-md-4 col-lg-4">
								<p>{{ campaign.name }}</p>
							</div>
							<div class="col-md-4 col-lg-4">
								<p>Email Newsletter</p>
							</div>
							<div class="col-md-4 col-lg-4">
								<div class="form-check">
									<input class="form-check-input" id="{{ campaign.id }}" type="checkbox" [checked]="campaign.optedIn" (ngModelChange)="campaign.optedIn = $event" #checkbox />
									<label class="form-check-label ms-1" for="{{ campaign.id }}">Opt-In</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="mt-4" *ngIf="this.campaigns.length > 0">
				<button class="btn btn-info text-white float-end" type="save" (click)="savePreferences()">Save</button>
			</div>
		</div>
	</div>
</div>
