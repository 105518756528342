<div class="container sfx-top-spacing">
	<div class="row">
		<div class="col-md-9 col-lg-3 mb-3 mb-lg-0">
			<app-sfx-user-nav></app-sfx-user-nav>
		</div>
		<div class="col-sm-12 col-md-9 col-lg-9">
			<h1>My Orders</h1>
			<div [hidden]="!loading">
				<app-sfx-loading-spinner></app-sfx-loading-spinner>
			</div>
			<div [hidden]="loading">
				<div *ngIf="userOrders.length < 1" class="text-center">
					<p>You have no previous orders.</p>
					<button [routerLink]="['/products', 'soundpack']" class="btn text-white bg-success" type="button">Start shopping</button>
				</div>

				<div *ngIf="userOrders.length > 0">
					<p-table class="m-4" #dt1 [value]="userOrders" dataKey="orderId" [rows]="rowPerPage" [showCurrentPageReport]="true" [loading]="loading" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orders" [globalFilterFields]="['orderId', 'orderDate', 'subTotal', 'deliveryStatus']" [totalRecords]="totalOrders" selectionMode="single" [lazy]="true" (onLazyLoad)="onPageChanged($event)">
						<ng-template pTemplate="header">
							<tr>
								<th pSortableColumn="orderId" style="width: 20%">Order ID <p-sortIcon field="code"></p-sortIcon></th>
								<th pSortableColumn="orderDate" style="width: 20%">Date Purchased <p-sortIcon field="code"></p-sortIcon></th>
								<th pSortableColumn="deliveryStatus" style="width: 20%">Status <p-sortIcon field="code"></p-sortIcon></th>
								<th pSortableColumn="subtotal" style="width: 20%">Total <p-sortIcon field="code"></p-sortIcon></th>
								<th style="width: 20%">Invoice</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-order>
							<tr>
								<td>
									{{ order.orderId }}
								</td>
								<td>
									<span class="ml-1 vertical-align-middle">{{ order.orderDate }}</span>
								</td>
								<td>
									<p-tag [value]="order.deliveryStatus" [severity]="getSeverity(order.deliveryStatus)"> </p-tag>
								</td>
								<td>
									{{ order.orderTotal || order.subTotal | currency }}
								</td>
								<td>
									<a role="button" class="btn btn-link" (click)="openModal(order.orderId)"><span class="bi bi-eye-fill" style="font-size: 20px"></span></a>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="7">No orders found.</td>
							</tr>
						</ng-template>
					</p-table>
				</div>

				<ng-container *ngIf="showModal && showModalId">
					<div id="order-modal" class="modal" tabindex="-1" role="dialog" style="display: block" #orderModal>
						<div class="modal-dialog modal-lg" role="document">
							<div class="modal-content">
								<div class="modal-header">
									<h2 class="modal-title pull-left">Order {{ showModalId }}</h2>
									<button type="button" class="btn-close close pull-right" (click)="onCloseModal()">
										<span aria-hidden="true" class="visually-hidden">&times;</span>
									</button>
								</div>
								<div class="modal-body">
									<app-sfx-invoice [showModalId]="showModalId"></app-sfx-invoice>
								</div>
								<div class="modal-footer">
									<button (click)="exportPDF()" class="btn btn-primary">Download</button>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-backdrop" style="display: block; opacity: 0.5"></div>
				</ng-container>
				<div *ngIf="userOrders.length > 25">
					<app-sfx-pagination></app-sfx-pagination>
				</div>
			</div>
		</div>
	</div>
</div>
