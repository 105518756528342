import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomSFXAddress } from '../interfaces/Address';
import { Coupon } from '../interfaces/Coupon';
import { Order } from '../interfaces/Order';
import { Product } from '../interfaces/Product';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class OrderService {
	private readonly apiUrl: string = environment.base_url;
	private readonly userId: string = this.authService.getUserId();

	constructor(private authService: AuthService, private readonly http: HttpClient) {}

	public createCheckoutSession(couponId: string = '', shippingAddress?: CustomSFXAddress): Observable<{ url: string }> {
		return this.http.post<{ url: string }>(
			this.apiUrl + `/orders/${this.authService.getUserId()}/create`,
			shippingAddress
				? {
						couponId: couponId ? couponId : null,
						shippingOptions: shippingAddress,
				  }
				: {
						couponId: couponId ? couponId : null,
				  },
			this.authService.getHttpHeaders()
		);
	}

	public validateCoupon(userId: string, couponId: string): Observable<{ isValid: boolean }> {
		const body = {
			userId,
			couponId,
		};
		return this.http.post<{ isValid: boolean }>(this.apiUrl + '/coupons/validate', body, this.authService.getHttpHeaders()).pipe(retry(1));
	}

	public getCoupon(couponId: string): Observable<Coupon> {
		return this.http
			.get<Coupon>(this.apiUrl + `/coupons/couponId/${encodeURIComponent(couponId)}`, this.authService.getHttpHeaders())
			.pipe(retry(1));
	}

	public getOrder(orderId: string): Observable<Order> {
		return this.http.get<Order>(this.apiUrl + 'orders/' + this.userId + '/orderId/' + orderId, this.authService.getHttpHeaders()).pipe(retry(1));
	}

	public getAllOrders(): Observable<Order> {
		return this.http.get<Order>(this.apiUrl + '/orders/all/', this.authService.getHttpHeaders()).pipe(retry(1));
	}

	public getBatchAllOrdersSorted(sortBy: string, sortField: string, page: number, limit: number) {
		return this.http.get<Order[]>(this.apiUrl + '/orders/all', {
			params: { sortBy, sortField, page, limit },
			headers: this.authService.getHttpHeadersObject(),
			observe: 'response',
		});
	}

	public getBatchUserOrdersSorted(sortBy: string, sortField: string, page: number, limit: number) {
		const userId = this.authService.getUserId();

		return this.http.get<Order[]>(this.apiUrl + '/orders/all/' + userId, {
			params: { sortBy, sortField, page, limit },
			headers: this.authService.getHttpHeadersObject(),
			observe: 'response',
		});
	}

	public getOrdersForUser(userId: string): Observable<Order> {
		return this.http.get<Order>(this.apiUrl + '/orders/all/' + userId, this.authService.getHttpHeaders()).pipe(retry(1));
	}

	public getLibraryForUser(userId: string): Observable<Product> {
		return this.http.get<Product>(this.apiUrl + '/userLibrary/' + userId + '/all', this.authService.getHttpHeaders()).pipe(retry(1));
	}

	public getDownloadFileForUser(fileUrl: string) {
		return this.http.get(fileUrl, { responseType: 'arraybuffer' }).pipe(retry(1));
	}

	public getDownloadLinkForUser(userId: string, fileName: string): Observable<any> {
		const options = {
			params: { fileName },
			headers: this.authService.getHttpHeadersObject(),
		};
		return this.http.get<{ link: string }>(`${this.apiUrl}/userLibrary/${userId}/file`, options).pipe(retry(1));
	}

	public updateOrderStatus(orderId: string, status: string) {
		const body = {
			deliveryStatus: status,
		};
		return this.http.put<{ token: string }>(this.apiUrl + '/orders/' + orderId, body, this.authService.getHttpHeaders());
	}
}
