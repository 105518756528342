import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, take } from 'rxjs';
import { CartItem } from 'src/app/interfaces/CartItem';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { SFXUserGroup } from '../../enums/UserGroup';

@Component({
	selector: 'app-sfx-navbar',
	templateUrl: './sfx-navbar.component.html',
	styleUrls: ['./sfx-navbar.component.scss'],
})
export class SfxNavbarComponent implements OnInit {
	isCollapsed = true;
	searchKeyword: string;
	cartCount = 0;

	cartItems$: Observable<CartItem[]> = this.cartService.cartItemsSubject.asObservable();

	constructor(private authService: AuthService, private router: Router, private cartService: CartService, private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.cartService.getAllItems();
		this.cartItems$.subscribe((cartItems) => {
			this.cartCount = cartItems.reduce((prev, curr) => prev + curr.productQuantity, 0);
		});

		this.route.queryParams.subscribe((params) => {
			if (params['keyword']) {
				this.searchKeyword = params['keyword'];
			} else {
				this.searchKeyword = '';
			}
		});
	}

	isLoggedIn(): boolean {
		return this.authService.isAuthenticated();
	}

	signOut(): void {
		this.authService.logout();
	}

	toggleNavbar() {
		this.isCollapsed = !this.isCollapsed;
	}

	isAdmin() {
		return this.authService.getUserRole() === SFXUserGroup.Admin;
	}

	onSearch() {
		let productTypeUrl = 'products';

		this.route.queryParams.pipe(take(1)).subscribe((params) => {
			const updatedParams = { ...params, keyword: this.searchKeyword };
			this.router.navigate(['products', productTypeUrl], { queryParams: updatedParams });
		});
	}
}
