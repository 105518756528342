import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CarouselItem } from '../interfaces/CarouselItem';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class CarouselService {
	apiUrl: string = environment.base_url;

	constructor(private readonly http: HttpClient, private authService: AuthService) {}

	getAllCarouselItems(): Promise<CarouselItem[]> {
		return lastValueFrom(this.http.get<any>(this.apiUrl + '/carousel/all'));
	}

	addCarouselItem(item: CarouselItem): Observable<any> {
		return this.http.post<any>(this.apiUrl + '/carousel/add', item, this.authService.getHttpHeaders());
	}

	updateCarouselItem(item: CarouselItem): Observable<any> {
		return this.http.put<any>(this.apiUrl + '/carousel/update', item, this.authService.getHttpHeaders());
	}

	deleteCarouselItem(itemId: string): Observable<any> {
		return this.http.delete<any>(this.apiUrl + '/carousel/' + itemId, this.authService.getHttpHeaders());
	}
}
