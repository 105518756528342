<div class="container sfx-top-spacing">
	<div class="row">
		<div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
			<app-sfx-user-nav></app-sfx-user-nav>
		</div>
		<div class="col-sm-12 col-md-8 col-lg-9 col-xl-9">
			<h1>My Library</h1>
			<div *ngIf="!dataLoaded">
				<app-sfx-loading-spinner></app-sfx-loading-spinner>
			</div>
			<div *ngIf="dataLoaded">
				<div *ngIf="userProducts.length < 1" class="text-center">
					<p>Your library is currently empty.</p>
					<button [routerLink]="['/products', 'soundpack']" class="btn text-white bg-success" type="button">Start shopping</button>
				</div>
				<div>
					<p-table *ngIf="userProducts.length > 0" #libraryTable [value]="userProducts" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50, 100]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" [globalFilterFields]="['productName', 'productTypeName']" selectionMode="single">
						<ng-template pTemplate="caption">
							<div class="input-group">
								<span class="input-group-text">
									<i class="bi bi-search"></i>
								</span>

								<input type="text" class="form-control" id="sfx-datatable-search" placeholder="Search your library..." (input)="onSearchHandle($event)" />
							</div>
						</ng-template>
						<ng-template pTemplate="header">
							<tr>
								<th pSortableColumn="productName" scope="col">Product Name <p-sortIcon field="code"></p-sortIcon></th>
								<th pSortableColumn="productTypeName" scope="col">Type <p-sortIcon field="code"></p-sortIcon></th>
								<th scope="col">Download</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-product>
							<tr>
								<td>{{ product.productName }}</td>
								<td>{{ product.productTypeName }}</td>
								<td class="icon-cell text-center">
									<a class="text-secondary" role="button"><span class="bi bi-download" (click)="downloadProduct(product)"></span></a>
								</td>
							</tr>
						</ng-template>
					</p-table>
				</div>
			</div>
		</div>
	</div>
</div>
