<div class="container sfx-top-spacing">
	<ol class="breadcrumb">
		<li class="breadcrumb-item"><a class="text-info" routerLink="/admin">Dashboard</a></li>
		<li class="breadcrumb-item"><a class="text-info" routerLink="/admin/customers">Customers</a></li>
		<li aria-current="page" class="breadcrumb-item active">View Customer</li>
	</ol>

	<h1>View Customer</h1>
	<hr />
	<form (ngSubmit)="onSubmit()" [formGroup]="editDetailsForm">
		<div class="row mt-4">
			<div class="col-6 mt-1">
				<label for="firstName">First Name</label>
				<input class="form-control" formControlName="firstName" id="firstName" placeholder="First Name" type="text" />
			</div>
			<div class="col-6 mt-1">
				<label for="lastName">Last Name</label>
				<input class="form-control" formControlName="lastName" id="lastName" placeholder="Last Name" type="text" />
			</div>
		</div>
		<div class="row mt-4">
			<div class="col-6 mt-1">
				<label for="email">Customer Email</label>
				<input class="form-control" formControlName="email" id="email" placeholder="Customer Email" type="text" />
			</div>
			<div class="col-6 mt-1">
				<label for="phone">Phone Number</label>
				<input class="form-control" formControlName="phone" id="phone" placeholder="Phone Number" type="tel" />
			</div>
		</div>
		<div class="row mt-4">
			<div class="col-6 btn-group">
				<button (click)="resetPassword()" class="btn btn-primary btn-block" type="button">Reset Password</button>
			</div>
			<div class="col-6 btn-group">
				<button class="btn btn-success btn-block text-light" type="submit">Save</button>
			</div>
		</div>
	</form>

	<div class="mt-4">
		<p-table #customerTable dataKey="orderId" [value]="userOrders" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50, 100]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orders" [globalFilterFields]="['orderId', 'firstName', 'lastName', 'email', 'deliveryStatus', 'orderDate']" selectionMode="single" [loading]="loading">
			<ng-template pTemplate="caption">
				<div class="d-flex align-items-stretch gap-2">
					<div class="input-group flex-grow-1">
						<span class="input-group-text">
							<i class="bi bi-search"></i>
						</span>

						<input type="text" class="form-control" id="sfx-datatable-search" placeholder="Search for orders" (input)="onSearchHandle($event)" />
					</div>
				</div>
			</ng-template>
			<ng-template pTemplate="header">
				<tr>
					<th pSortableColumn="orderId">Order ID <p-sortIcon field="code"></p-sortIcon></th>
					<th>First Name</th>
					<th>Last Name</th>
					<th>Email</th>
					<th pSortableColumn="deliveryStatus">Status <p-sortIcon field="code"></p-sortIcon></th>
					<th pSortableColumn="orderDate">Date Purchased <p-sortIcon field="code"></p-sortIcon></th>
					<th></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-order>
				<tr>
					<td>{{ order.orderId }}</td>
					<td>{{ user.firstName }}</td>
					<td>{{ user.lastName }}</td>
					<td>{{ user.email }}</td>
					<td>{{ order.deliveryStatus ? order.deliveryStatus : "Delivered" }}</td>
					<td>{{ order.orderDate }}</td>
					<td class="icon-cell">
						<a class="text-info" (click)="openModal(order.orderId)">
							<span class="bi bi-eye-fill"></span>
						</a>
					</td>
				</tr>
			</ng-template>

			<ng-template pTemplate="emptymessage">
				<tr>
					<td colspan="7">No orders found.</td>
				</tr>
			</ng-template>
		</p-table>
	</div>

	<ng-container *ngIf="showModal && showModalId">
		<div id="order-modal" class="modal" tabindex="-1" role="dialog" style="display: block" #orderModal>
			<div class="modal-dialog modal-lg" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h2 class="modal-title pull-left">Order {{ showModalId }}</h2>
						<button type="button" class="btn-close close pull-right" (click)="onCloseModal()">
							<span aria-hidden="true" class="visually-hidden">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<app-sfx-invoice [showModalId]="showModalId"></app-sfx-invoice>
					</div>
					<div class="modal-footer">
						<button (click)="exportPDF()" class="btn btn-primary">Download</button>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-backdrop" style="display: block; opacity: 0.5"></div>
	</ng-container>

	<div class="row mt-4">
		<div class="col-6"></div>
		<div class="col-6">
			<button class="btn btn-danger btn-block float-end" type="button">DEACTIVATE ACCOUNT</button>
		</div>
	</div>
</div>
