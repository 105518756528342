import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Instrument } from '../../../../interfaces/Instrument';
import { ToastPayload, ToastType } from '../../../../interfaces/ToastMessage';
import { RefDataService } from '../../../../services/ref-data.service';
import { ToastService } from '../../../../services/toast.service';

@Component({
	selector: 'app-instruments-modal',
	templateUrl: './instruments-modal.component.html',
	styleUrls: ['./instruments-modal.component.scss'],
})
export class InstrumentsModalComponent implements OnInit {
	instruments: Instrument[];
	instrumentsWithSubInstruments: Instrument[];
	isEditMode: boolean = false;
	editInstrumentId: string | undefined;
	manageInstrumentsForm: FormGroup;

	constructor(
		public bsModalRef: BsModalRef,
		private refDataService: RefDataService,
		private formBuilder: FormBuilder,
		private toastService: ToastService
	) {}

	async ngOnInit() {
		this.manageInstrumentsForm = this.formBuilder.group({
			instrumentName: ['', Validators.required],
			parentInstrument: [''],
		});

		await this.loadInstruments();

		this.bsModalRef.setClass('modal-lg');
	}

	async loadInstruments() {
		this.instruments = await this.refDataService.getAllInstruments();
		this.instrumentsWithSubInstruments = this.sortItems(this.instruments);
	}

	getInstrumentName(id: number | string | null | undefined) {
		return this.instruments.find((x) => x.instrumentId == id)?.instrumentName ?? '';
	}

	showEditMenu(instrument: Instrument) {
		this.isEditMode = true;
		this.editInstrumentId = instrument.instrumentId;
		this.manageInstrumentsForm.patchValue({
			instrumentName: instrument.instrumentName || '',
			parentInstrument: instrument.relatedInstrumentId || '',
		});
	}

	cancelEditMode() {
		this.isEditMode = false;
		this.editInstrumentId = undefined;
		this.manageInstrumentsForm.patchValue({
			instrumentName: '',
			parentInstrument: '',
		});
	}

	sortItems(instruments: Instrument[]) {
		const sorted = instruments.filter((x) => x.relatedInstrumentId === null);

		for (const item of instruments.reverse()) {
			if (item.relatedInstrumentId !== null) {
				const index = sorted.findIndex((x) => x.instrumentId == item.relatedInstrumentId);
				sorted.splice(index + 1, 0, item);
			}
		}

		return sorted;
	}

	async addInstrument() {
		if (this.manageInstrumentsForm.valid) {
			let instrument: Instrument = {
				instrumentName: this.manageInstrumentsForm.get('instrumentName')?.value,
			};
			let parent = this.manageInstrumentsForm.get('parentInstrument')?.value;
			if (parent && parent != '') instrument.relatedInstrumentId = parent;
			else instrument.relatedInstrumentId = null;

			if (!this.isEditMode) {
				this.refDataService.addInstrument(instrument).subscribe({
					next: (_) => {
						let toast: ToastPayload = {
							title: 'Instrument added!',
							type: ToastType.SUCCESS,
							message: instrument.instrumentName + ' added successfully.',
						};
						this.toastService.addToast(toast);
					},
					error: (error: any) => {
						let toast: ToastPayload = {
							title: 'Failed to add instrument.',
							type: ToastType.ERROR,
							message: instrument.instrumentName + ' had errors: ' + error?.message,
						};
						this.toastService.addToast(toast);
					},
				});
			} else {
				instrument.instrumentId = this.editInstrumentId;
				this.refDataService.updateInstrument(instrument).subscribe({
					next: (_) => {
						let toast: ToastPayload = {
							title: 'Instrument updated!',
							type: ToastType.SUCCESS,
							message: instrument.instrumentName + ' updated successfully.',
						};
						this.toastService.addToast(toast);
					},
					error: (error: any) => {
						let toast: ToastPayload = {
							title: 'Failed to update instrument.',
							type: ToastType.ERROR,
							message: instrument.instrumentName + ' had errors: ' + error?.message,
						};
						this.toastService.addToast(toast);
					},
				});
				this.cancelEditMode();
			}

			this.manageInstrumentsForm.reset();

			// Wait a second and then update instruments
			// This decreases likelihood of it using a cached call
			await new Promise((r) => setTimeout(r, 100));
			await this.loadInstruments();
		}
	}
}
