import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormArray,
	FormBuilder,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	Validator,
} from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Instrument } from '../../../../interfaces/Instrument';
import { InstrumentData } from '../../../../interfaces/InstrumentData';
import { ProductService } from '../../../../services/product.service';
import { RefDataService } from '../../../../services/ref-data.service';
import { InstrumentsModalComponent } from '../instruments-modal/instruments-modal.component';

@Component({
	selector: 'app-instruments-form',
	templateUrl: './instruments-form.component.html',
	styleUrls: ['./instruments-form.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: InstrumentsFormComponent,
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: InstrumentsFormComponent,
		},
	],
})
export class InstrumentsFormComponent implements ControlValueAccessor, OnInit, OnDestroy, Validator, AfterViewInit {
	form: FormGroup = this.formBuilder.group({
		instrumentIds: [],
		detailedInstruments: this.formBuilder.array([]),
	});
	onChangeSubs: Subscription[] = [];
	select_instruments: Instrument[];
	selected_instruments: Instrument[];
	suggestedEffects: string[] = [];
	suggestedModels: string[] = [];
	suggestedTechniques: string[] = [];
	touched: Boolean = false;
	bsModalRef?: BsModalRef;

	constructor(
		private formBuilder: FormBuilder,
		private refDataService: RefDataService,
		private modalService: BsModalService,
		private productService: ProductService
	) {}

	onTouched: Function = () => {};

	async ngOnInit() {
		this.select_instruments = await this.refDataService.getAllInstruments();
	}

	async ngAfterViewInit() {
		// This is annoying, but writeValue is being called before the instruments are loaded
		// so the form can't match on the correct value in the dropdown menus.
		this.select_instruments = await this.refDataService.getAllInstruments();

		[this.suggestedEffects, this.suggestedTechniques, this.suggestedModels] = await Promise.all([
			this.productService.getSoundpackSuggestions('effect'),
			this.productService.getSoundpackSuggestions('playTechnique'),
			this.productService.getSoundpackSuggestions('instrumentModel'),
		]);

		this.form.get('instrumentIds')?.setValue(this.form.get('instrumentIds')?.value);
	}

	newInstrument(): FormGroup {
		return this.formBuilder.group({
			instrumentTypeId: '',
			instrumentModel: '',
			playTechnique: '',
			effect: '',
		});
	}

	detailedInstruments(): FormArray {
		return this.form.get('detailedInstruments') as FormArray;
	}

	addInstrument() {
		this.detailedInstruments().push(this.newInstrument());
	}

	removeInstrument(i: number) {
		this.detailedInstruments().removeAt(i);
		this.markAsTouched();
	}

	ngOnDestroy() {
		for (let sub of this.onChangeSubs) {
			sub.unsubscribe();
		}
	}

	getSelectedInstruments(): string[] {
		let instruments = this.form.get('instrumentIds')?.value;
		return instruments ? [...instruments] : [];
	}

	getSelectedInstrumentsWithLabel(): Instrument[] {
		let instrumentIds = this.getSelectedInstruments();
		let detailedInstruments: Instrument[] = [];
		if (instrumentIds) {
			for (const instrumentId of instrumentIds) {
				let instrument = this.select_instruments?.find((x) => x.instrumentId == instrumentId);
				if (instrument) detailedInstruments.push(instrument);
			}
		}

		return detailedInstruments;
	}

	registerOnChange(onChange: any) {
		const sub = this.form.valueChanges.subscribe((_) => {
			if (this.form.touched) {
				this.markAsTouched();
			}
			// add parents
			this.selected_instruments = this.getSelectedInstrumentsWithLabel();
			let results: any[] = [];
			let allInstruments = this.select_instruments;
			if (!allInstruments) return;

			this.selected_instruments.forEach((item) => {
				if (results.indexOf(item.instrumentId) === -1) results.push(item.instrumentId);
				const instrument = allInstruments.find((g) => g.instrumentId === item.instrumentId);
				if (instrument && instrument.relatedInstrumentId !== null) {
					let relatedInstrumentId: string | undefined | null = instrument.relatedInstrumentId;
					while (relatedInstrumentId !== null) {
						const parentInstrument = allInstruments.find((x) => x.instrumentId == relatedInstrumentId);
						if (parentInstrument) {
							if (results.indexOf(parentInstrument.instrumentId) === -1) {
								results.push(parentInstrument.instrumentId);
							}
							relatedInstrumentId = parentInstrument.relatedInstrumentId;
						}
					}
				}
			});

			this.form.get('instrumentIds')?.patchValue(results, { emitEvent: false });

			// Populate detailed instruments
			let undetailedInstruments = this.getSelectedInstruments();
			let instruments: InstrumentData[] = [];

			this.detailedInstruments().controls.forEach((instrumentSubForm) => {
				let instrument: InstrumentData = {};
				let hasValue = false;

				let instrumentTypeId = instrumentSubForm.get('instrumentTypeId')?.value;
				if (instrumentTypeId) instrument.instrumentTypeId = instrumentTypeId;

				let instrumentModel = instrumentSubForm.get('instrumentModel')?.value;
				if (instrumentModel) {
					instrument.instrumentModel = instrumentModel;
					hasValue = true;
				} else instrument.instrumentModel = null;

				let playTechnique = instrumentSubForm.get('playTechnique')?.value;
				if (playTechnique) {
					instrument.playTechnique = playTechnique;
					hasValue = true;
				} else instrument.playTechnique = null;

				let effect = instrumentSubForm.get('effect')?.value;
				if (effect) {
					instrument.effect = effect;
					hasValue = true;
				} else instrument.effect = null;

				if (hasValue) {
					instruments.push(instrument);

					let index = undetailedInstruments.indexOf(instrumentTypeId);
					// only remove when instrument is found
					if (index > -1) {
						undetailedInstruments.splice(index, 1); // 2nd parameter means remove one item only
					}
				}
			});

			for (const undetailedInstrument of undetailedInstruments) {
				instruments.push({ instrumentTypeId: undetailedInstrument, instrumentModel: null, playTechnique: null, effect: null });
			}
			this.selected_instruments = this.getSelectedInstrumentsWithLabel();
			onChange(instruments);
		});

		this.onChangeSubs.push(sub);
	}

	registerOnTouched(onTouched: Function) {
		this.onTouched = onTouched;
	}

	setDisabledState(disabled: boolean) {
		if (disabled) {
			this.form.disable();
		} else {
			this.form.enable();
		}
	}

	async writeValue(instruments: InstrumentData[]) {
		if (instruments) {
			let selectedInstruments = instruments.map((x) => x.instrumentTypeId);
			console.log('Selected: ' + selectedInstruments);

			this.form.get('instrumentIds')?.patchValue(selectedInstruments, { emitEvent: false });

			for (let instrument of instruments) {
				if (instrument.instrumentModel || instrument.playTechnique || instrument.effect) {
					let instrumentSubForm = this.newInstrument();
					instrumentSubForm.patchValue(
						{
							instrumentTypeId: instrument.instrumentTypeId || '',
							instrumentModel: instrument.instrumentModel || '',
							playTechnique: instrument.playTechnique || '',
							effect: instrument.effect || '',
						},
						{ emitEvent: false }
					);
					this.detailedInstruments().push(instrumentSubForm);
				}
			}
		}
	}

	validate(_: AbstractControl) {
		if (this.form.valid) {
			return null;
		}

		let errors: any = {};

		errors = this.addControlErrors(errors, 'instrumentTypeId');
		errors = this.addControlErrors(errors, 'instrumentModel');
		errors = this.addControlErrors(errors, 'playTechnique');
		errors = this.addControlErrors(errors, 'effect');

		return errors;
	}

	addControlErrors(allErrors: any, controlName: string) {
		const errors = { ...allErrors };
		const controlErrors = this.form.controls[controlName].errors;

		if (controlErrors) {
			errors[controlName] = controlErrors;
		}
		return errors;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	openInstrumentsModal() {
		this.bsModalRef = this.modalService.show(InstrumentsModalComponent);
		this.bsModalRef?.onHidden?.subscribe(async (_) => {
			let currentValue = this.form.get('instrumentIds')?.value;
			this.select_instruments = await this.refDataService.getAllInstruments();
			// Wait to patch the value. It doesn't like it happening immediately.
			await new Promise((r) => setTimeout(r, 100));
			this.form.get('instrumentIds')?.setValue(currentValue, { emitEvent: false });
		});
	}
}
