import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Campaign } from 'src/app/interfaces/Campaign';
import { ToastType } from 'src/app/interfaces/ToastMessage';
import { AuthService } from 'src/app/services/auth.service';
import { NewsletterService } from 'src/app/services/newsletter.service';
import { ToastService } from 'src/app/services/toast.service';
import { PasswordValidator } from 'src/app/validators/password.validator';

@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.component.html',
	styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
	signUpForm: FormGroup;
	showPassword = false;
	isSubmitted = false;
	campaigns: any = [];

	constructor(
		private route: ActivatedRoute,
		private readonly titleService: Title,
		private readonly formBuilder: FormBuilder,
		private readonly authService: AuthService,
		private readonly router: Router,
		private toastService: ToastService,
		private readonly newsletterService: NewsletterService
	) {}

	// convenience getter for easy access to form fields
	get f() {
		return this.signUpForm.controls;
	}

	ngOnInit(): void {
		this.route.data.subscribe((data) => {
			this.titleService.setTitle(data['title']);
		});

		this.signUpForm = this.formBuilder.group({
			first_name: ['', [Validators.required]],
			last_name: ['', [Validators.required]],
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required, PasswordValidator.complexity]],
		});
	}

	onSubmit() {
		this.isSubmitted = true;

		if (!this.signUpForm || this.signUpForm.invalid) {
			return;
		}

		const firstName = this.f['first_name'].value;
		const lastName = this.f['last_name'].value;
		const email = this.f['email'].value;
		const password = this.f['password'].value;
		this.authService.signUp(firstName, lastName, email, password).subscribe({
			next: (_) => {
				console.log('Sign up successful');
				this.router.navigate(['/']);

				this.newsletterService.getCampaigns().subscribe({
					next: (res: Campaign[]) => {
						this.campaigns = [];
						console.log(res);
						res.forEach((element: Campaign) => {
							this.campaigns.push(element);
							this.newsletterService.subscribeUser(email, element.id).subscribe((error) => {
								console.log(error);
							});
						});
					},
					error: (error) => {
						console.log('Error getting email campaign data:', error);
					},
				});
			},
			error: (error) => {
				console.log('Error signing up:', error);
				this.toastService.addToast({
					type: ToastType.ERROR,
					title: 'Signup Failed',
					message: 'Account already exists. Please try a different email or log in instead.',
				});
			},
		});
	}
}
