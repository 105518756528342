<div class="container-fluid sfx-top-spacing sfx-center-container">
	<ng-template #template>
		<div class="modal-header">
			<h4 class="modal-title pull-left">Success</h4>
			<button (click)="modalRef?.hide()" aria-label="Close" class="btn-close close pull-right" type="button">
				<span aria-hidden="true" class="visually-hidden">&times;</span>
			</button>
		</div>
		<div class="modal-body">Password change request submitted. We'll email instructions if your email is in our system.</div>
		<div class="modal-footer">
			<button (click)="modalRef?.hide(); goToLogin()" class="btn btn-success text-white btn-default" type="button">Go to login</button>
		</div>
	</ng-template>

	<div class="form-wrapper">
		<div class="bg-white col-12 col-md-8 col-lg-6 p-3 w-100 shadow">
			<form (ngSubmit)="onSubmit(template)" [formGroup]="resetPasswordForm">
				<h1 class="mt-3 text-center">Forgot password?</h1>
				<p class="text-center text-muted">Enter your email below to request a SoundFormX account password reset.</p>

				<div class="form-group mt-3">
					<label for="sfx-email">Email</label>
					<input
						[ngClass]="{
							'is-invalid': isSubmitted && f['email'].errors,
							'is-valid': !f['email'].errors
						}"
						class="form-control mt-2"
						formControlName="email"
						id="sfx-email"
						placeholder="Email"
						type="email"
					/>
				</div>

				<div class="form-group text-center mt-3">
					<button class="btn w-100 bg-success text-white" id="btnResetPassword" type="submit">Submit</button>
				</div>
			</form>
		</div>
	</div>
</div>
