<div class="container sfx-top-spacing">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item"><a class="text-info" routerLink="/admin">Dashboard</a></li>
			<li aria-current="page" class="breadcrumb-item active">Orders</li>
		</ol>
	</nav>

	<h1>Orders</h1>
	<hr />

	<p-table class="mt-4" #dt1 [value]="userOrders" dataKey="orderId" [rows]="rowPerPage" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50, 100]" [loading]="loading" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orders" [globalFilterFields]="['orderId', 'userFirstName', 'userLastName', 'userEmail', 'deliveryStatus', 'orderDate']" [totalRecords]="totalOrders" selectionMode="single">
		<ng-template pTemplate="caption">
			<div class="d-flex align-items-stretch gap-2">
				<div class="input-group">
					<span class="input-group-text">
						<i class="bi bi-search"></i>
					</span>

					<input [type]="'text'" class="form-control" id="sfx-datatable-search" placeholder="Search for orders" (input)="onSearchHandle($event)" />
				</div>
			</div>
		</ng-template>
		<ng-template pTemplate="header">
			<tr>
				<th pSortableColumn="orderId" style="width: 15%">Order ID <p-sortIcon field="code"></p-sortIcon></th>
				<th pSortableColumn="userFirstName" style="width: 15%">First Name <p-sortIcon field="code"></p-sortIcon></th>
				<th pSortableColumn="userLastName" style="width: 15%">Last Name <p-sortIcon field="code"></p-sortIcon></th>
				<th pSortableColumn="userEmail" style="width: 20%">Email <p-sortIcon field="code"></p-sortIcon></th>
				<th pSortableColumn="deliveryStatus" style="width: 10%">Status <p-sortIcon field="code"></p-sortIcon></th>
				<th pSortableColumn="orderDate" style="width: 20%">Date Purchased <p-sortIcon field="code"></p-sortIcon></th>
				<th style="width: 5%"></th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-order>
			<tr>
				<td>
					{{ order.orderId }}
				</td>
				<td>
					<span class="ml-1 vertical-align-middle">{{ order.userFirstName }}</span>
				</td>
				<td>
					<span class="ml-1 vertical-align-middle">{{ order.userLastName }}</span>
				</td>
				<td>{{ order.userEmail }}</td>
				<td>
					<p-tag [value]="order.deliveryStatus" [severity]="getSeverity(order.deliveryStatus)"> </p-tag>
				</td>
				<td>{{ order.orderDate }}</td>
				<td>
					<button type="button" class="btn btn-link" (click)="openModal(order.orderId)"><span class="bi bi-pencil-square" style="font-size: 20px"></span></button>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="7">No orders found.</td>
			</tr>
		</ng-template>
	</p-table>

	<ng-container *ngIf="showModal && showModalId">
		<div id="order-modal" class="modal" tabindex="-1" role="dialog" style="display: block" #orderModal>
			<div class="modal-dialog modal-lg" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h2 class="modal-title pull-left">Order {{ showModalId }}</h2>
						<button type="button" class="btn-close close pull-right" (click)="onCloseModal()">
							<span aria-hidden="true" class="visually-hidden">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<app-sfx-invoice [showModalId]="showModalId"></app-sfx-invoice>
					</div>
					<div class="modal-footer justify-content-between">
						<div class="flex-grow-0">
							<div>
								<form [formGroup]="orderStatusForm" (ngSubmit)="updateOrderStatus()" class="input-group">
									<select class="form-control form-select" formControlName="deliveryStatus">
										<option selected value="">Update Order Status...</option>
										<option value="Pending">Pending</option>
										<option value="Shipped">Shipped</option>
										<option value="Delivered">Delivered</option>
										<option value="Canceled">Canceled</option>
									</select>
									<button type="submit" class="btn btn-info" [disabled]="orderStatusForm.invalid">Update</button>
								</form>
							</div>
						</div>
						<button (click)="exportPDF()" class="btn btn-primary">Download</button>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-backdrop" style="display: block; opacity: 0.5"></div>
	</ng-container>
</div>
