<div class="container sfx-top-spacing">
	<div class="row">
		<div class="col-md-9 col-lg-3 mb-3 mb-lg-0">
			<app-sfx-user-nav></app-sfx-user-nav>
		</div>
		<div class="col-md-9 col-lg-9">
			<h1>Address Information</h1>
			<div *ngIf="!dataLoaded">
				<app-sfx-loading-spinner></app-sfx-loading-spinner>
			</div>
			<div *ngIf="dataLoaded">
				<div *ngIf="addresses.length < 1" class="text-center pt-4">
					<p>Your profile has no saved addresses.</p>
				</div>
				<div *ngFor="let address of addresses; let addressIndex = index" class="row mt-1">
					<div class="col-12">
						<div class="card shadow-sm bg-light">
							<div class="card-body">
								<div class="row">
									<div class="col-md-3">
										<p>Address {{ addressIndex + 1 }}</p>
									</div>
									<div class="col-md-3">
										<p class="text-left">
											{{ address.firstName }} {{ address.lastName }}<br />
											{{ address.streetAddress }}<br />
											{{ address.unitNumber }}<br />
											{{ address.city }}, {{ address.state }} {{ address.zip }}<br />
											{{ address.country }}
										</p>
									</div>
									<div class="col-md-3">
										<input class="form-check-input" id="shipping-{{ addressIndex }}" type="checkbox" [attr.checked]="address.isDefaultShipping ? true : null" disabled />
										<label class="form-check-label ms-1" for="shipping-{{ addressIndex }}"> Default Shipping </label>
									</div>
								</div>
							</div>
							<div class="mb-3">
								<button class="btn btn-danger btn-block ms-3 me-3 float-end" type="button" (click)="deleteAddress(address.addressId)">Delete</button>
								<button (click)="editAddressModal.show()" class="btn text-white ms-3 me-3 float-end bg-sfx-gray" type="submit">Edit</button>
							</div>
						</div>
					</div>

					<div #editAddressModal="bs-modal" [config]="{ backdrop: 'static' }" bsModal class="modal fade" role="dialog" tabindex="-1">
						<div class="modal-dialog modal-xl">
							<div class="modal-content">
								<div class="modal-header">
									<h4 class="modal-title pull-left">Edit Address {{ addressIndex + 1 }}</h4>
									<button (click)="editAddressModal.hide()" aria-label="Close" class="btn-close close pull-right" data-dismiss="bs-modal" type="button">
										<span aria-hidden="true" class="visually-hidden">&times;</span>
									</button>
								</div>
								<div class="modal-body">
									<form (ngSubmit)="onEditAddressSubmit(address.addressId, address)" [formGroup]="editAddressForm">
										<div class="row">
											<div class="col-md-6">
												<label class="d-block mb-4">
													<span class="form-label d-block">First name</span>
													<input [value]="address.firstName" class="form-control" formControlName="firstName" placeholder="{{ address.firstName }}" type="text" />
												</label>
											</div>

											<div class="col-md-6">
												<label class="d-block mb-4">
													<span class="form-label d-block">Last name</span>
													<input [value]="address.lastName" class="form-control" formControlName="lastName" placeholder="{{ address.lastName }}" type="text" />
												</label>
											</div>
										</div>
										<div class="row">
											<div class="col-md-8 mb-4">
												<label class="d-block">
													<span class="form-label d-block">Address Line 1</span>
													<input [value]="address.streetAddress" class="form-control" formControlName="address" placeholder="{{ address.streetAddress }}" type="text" />
												</label>
											</div>

											<div class="col-md-4 mb-4">
												<label class="d-block">
													<span class="form-label d-block">Line 2</span>
													<input [value]="address.unitNumber" class="form-control" formControlName="unitNumber" placeholder="{{ address.unitNumber }}" type="text" />
												</label>
											</div>
										</div>

										<div class="row">
											<div class="col-md-6 mb-4">
												<label class="d-block">
													<span class="form-label d-block">City</span>
													<input [value]="address.city" class="form-control" formControlName="city" placeholder="{{ address.city }}" type="text" />
												</label>
											</div>

											<div class="col-md-2 mb-4">
												<label class="d-block">
													<span class="form-label d-block">State</span>
													<input [value]="address.state" class="form-control" formControlName="state" placeholder="{{ address.state }}" type="text" />
												</label>
											</div>

											<div class="col-md-4 mb-4">
												<label class="d-block">
													<span class="form-label d-block">Zip Code</span>
													<input [value]="address.zip" class="form-control" formControlName="zip" placeholder="{{ address.zip }}" type="text" />
												</label>
											</div>
										</div>

										<div class="row">
											<label class="d-block mb-4 col-cd-7">
												<span class="form-label d-block">Country</span>
												<input [value]="address.country" class="form-control" formControlName="country" placeholder="{{ address.country }}" type="text" />
											</label>

											<div class="mb-4 col-cd-3">
												<label class="form-check form-check-inline">
													<input class="form-check-input" formControlName="checkDefaultShipping" type="checkbox" [checked]="address.isDefaultShipping" />
													<span class="form-check-label">Default Shipping</span>
												</label>
											</div>
										</div>

										<div class="mb-3">
											<button class="btn btn-primary" type="submit">Save</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="mb-3 mt-3">
					<button (click)="newAddressModal.show()" class="btn btn-info text-white ms-3 float-end" type="submit">+ New Address</button>
				</div>
			</div>
		</div>

		<div #newAddressModal="bs-modal" [config]="{ backdrop: 'static' }" bsModal class="modal fade" role="dialog" tabindex="-1">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title pull-left">Add New Address</h4>
						<button (click)="newAddressModal.hide()" aria-label="Close" class="btn-close close pull-right" type="button">
							<span aria-hidden="true" class="visually-hidden">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<form (ngSubmit)="onAddAddressSubmit()" [formGroup]="addAddressForm">
							<div class="row">
								<div class="col-md-6">
									<label class="d-block mb-4">
										<span class="form-label d-block">First name</span>
										<input class="form-control" formControlName="firstName" placeholder="" type="text" required />
									</label>
								</div>

								<div class="col-md-6">
									<label class="d-block mb-4">
										<span class="form-label d-block">Last name</span>
										<input class="form-control" formControlName="lastName" placeholder="" type="text" required />
									</label>
								</div>
							</div>
							<div class="row">
								<label class="d-block mb-4 col-md-8">
									<span class="form-label d-block">Address</span>
									<input class="form-control" formControlName="address" placeholder="" type="text" required />
								</label>

								<label class="d-block mb-4 col-md-4">
									<span class="form-label d-block">Unit</span>
									<input class="form-control" formControlName="unitNumber" placeholder="" type="text" />
								</label>
							</div>

							<div class="row">
								<label class="d-block mb-4 col-md-6">
									<span class="form-label d-block">City</span>
									<input class="form-control" formControlName="city" placeholder="" type="text" required />
								</label>

								<label class="d-block mb-4 col-md-2">
									<span class="form-label d-block">State</span>
									<input class="form-control" formControlName="state" placeholder="" type="text" required />
								</label>

								<label class="d-block mb-4 col-md-4">
									<span class="form-label d-block">Zip Code</span>
									<input class="form-control" formControlName="zip" placeholder="" type="text" required />
								</label>
							</div>

							<div class="row">
								<label class="d-block mb-4 col-md-7">
									<span class="form-label d-block">Country</span>
									<input class="form-control" formControlName="country" placeholder="" type="text" required />
								</label>

								<div class="mb-4 col-cd-3">
									<label class="form-check form-check-inline">
										<span class="form-check-label">Default Shipping</span>
										<input class="form-check-input" formControlName="checkDefaultShipping" type="checkbox" />
									</label>
								</div>
							</div>

							<div class="mb-3">
								<button class="btn btn-primary" type="submit">Save</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
