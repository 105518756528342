import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import { Table } from 'primeng/table';
import { ProductTypes } from 'src/app/enums/ProductTypes';
import { SoundPackProduct } from 'src/app/interfaces/SoundPackProduct';
import { AuthService } from 'src/app/services/auth.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
	selector: 'app-my-library',
	templateUrl: './my-library.component.html',
	styleUrls: ['./my-library.component.scss'],
})
export class MyLibraryComponent implements OnInit {
	userProducts: SoundPackProduct[] = [];
	userId: string;
	dataLoaded: boolean = false;
	@ViewChild('libraryTable') libraryTable: Table;

	constructor(
		private readonly titleService: Title,
		private readonly authService: AuthService,
		private readonly orderService: OrderService
	) {}

	ngOnInit(): void {
		this.titleService.setTitle('My Library | SoundFormX');

		this.userId = this.authService.getUserId();

		this.orderService.getLibraryForUser(this.userId).subscribe({
			next: (res: any) => {
				res.forEach((product: SoundPackProduct) => {
					this.userProducts.push(product);
				});
				this.dataLoaded = true;
			},
		});
	}

	downloadProduct(product: SoundPackProduct): void {
		if (product.productTypeId === ProductTypes.TUTORIAL) {
			if (product.hyperlink !== null) {
				window.open(product.hyperlink, '_blank');
			} else if (product.fileName !== null) {
				this.orderService.getDownloadLinkForUser(this.userId, product.fileName!).subscribe({
					next: (res: any) => {
						this.orderService.getDownloadFileForUser(res.link).subscribe((fileData) => {
							const blob = new Blob([fileData], { type: 'application/octet-stream' });
							saveAs(blob, product.fileName);
						});
					},
				});
			}
		} else if (product.productTypeId === (ProductTypes.PACK || ProductTypes.BUNDLE)) {
			this.orderService.getDownloadLinkForUser(this.userId, product.packFileName!).subscribe({
				next: (res: any) => {
					this.orderService.getDownloadFileForUser(res.link).subscribe((fileData) => {
						const blob = new Blob([fileData], { type: 'application/octet-stream' });
						saveAs(blob, product.packFileName);
					});
				},
			});
		}
	}

	onSearchHandle(event: any) {
		const text = event.target.value || '';
		this.libraryTable.filterGlobal(text, 'contains');
	}
}
