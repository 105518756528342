import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';

@Component({
	selector: 'app-sfx-product-sort',
	templateUrl: './sfx-product-sort.component.html',
	styleUrls: ['./sfx-product-sort.component.scss'],
	providers: [
		{
			provide: BsDropdownConfig,
			useValue: { isAnimated: true, autoClose: true },
		},
	],
})
export class SfxProductSortComponent implements OnInit {
	@Output() sortOptionChanged = new EventEmitter<string>();
	options: string[] = ['Featured', 'Alphabetical', 'Price: Low to High', 'Price: High to Low'];
	selectedSortOption = 'Featured';
	text?: string;

	constructor() {}

	onOpenChange(data: boolean): void {
		this.text = data ? 'opened' : 'closed';
	}

	onSortOptionChange(option: string) {
		this.selectedSortOption = option;
		this.sortOptionChanged.emit(this.selectedSortOption);
	}

	ngOnInit(): void {}
}
