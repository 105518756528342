<div *ngIf="product" class="card border-0">
	<div>
		<a [routerLink]="['/products', 'details', product.productId]">
			<img [alt]="product.productName" [src]="getFullImageFileURL(product)" class="w-100" />
		</a>
		<div class="d-flex p-1">
			<div class="col pb-0">
				<p class="name pb-0 mb-0">
					<a [routerLink]="['/products', 'details', product.productId]">
						<b>{{ product.productName }}</b>
					</a>
				</p>
				<p>{{ getFormattedPrice() }}</p>
			</div>
			<div class="col-auto d-flex">
				<div>
					<a (click)="addProductToCartIfNotOwned()" class="sfx-icon" role="button">
						<i class="bi bi-cart-plus"></i>
					</a>
				</div>
				<div *ngIf="product.previewFileName">
					<a (click)="toggleAudioPlayback()" class="sfx-icon" role="button">
						<i [ngClass]="{ 'bi-play-circle': !isPlaying, 'bi-pause-circle': isPlaying }" class="bi"></i>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>
