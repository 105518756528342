import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastType } from 'src/app/interfaces/ToastMessage';
import { User } from 'src/app/interfaces/User';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { PasswordValidator } from 'src/app/validators/password.validator';

@Component({
	selector: 'app-account-details',
	templateUrl: './account-details.component.html',
	styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent implements OnInit {
	editDetailsForm: FormGroup;

	constructor(
		private readonly titleService: Title,
		private readonly formBuilder: FormBuilder,
		private readonly userService: UserService,
		private readonly authService: AuthService,
		private readonly router: Router,
		private toastService: ToastService
	) {}

	// convenience getter for easy access to form fields
	get f() {
		return this.editDetailsForm.controls;
	}

	ngOnInit(): void {
		this.titleService.setTitle('Account Details | SoundFormX');

		this.editDetailsForm = this.formBuilder.group({
			firstName: [''],
			lastName: [''],
			email: ['', [Validators.email]],
			phone: ['', Validators.pattern('[- +()0-9]+')],
			currentPassword: [''],
			newPassword: ['', [PasswordValidator.complexity]],
			confirmPassword: ['', [PasswordValidator.complexity]],
		});

		this.getUserData();
	}

	onSubmit() {
		const firstName = this.f['firstName'].value;
		const lastName = this.f['lastName'].value;
		const email = this.f['email'].value;
		const phone = this.f['phone'].value;
		const currentPassword = this.f['currentPassword'].value;
		const newPassword = this.f['newPassword'].value;
		const confirmPassword = this.f['confirmPassword'].value;

		if (this.editDetailsForm.valid) {
			if (currentPassword === '') {
				this.userService.updateUser(firstName, lastName, email, phone).subscribe({
					next: (_) => {
						this.toastService.addToast({
							type: ToastType.SUCCESS,
							title: 'Update Successful',
							message: 'User updated successfully!',
						});
					},
					error: (error) => {
						console.log('Error updating user:', error);
						this.toastService.addToast({
							type: ToastType.ERROR,
							title: 'Update Failed',
							message: 'User update failed!',
						});
					},
				});
			} else {
				if ((currentPassword as String).length < 8) {
					this.toastService.addToast({
						type: ToastType.ERROR,
						title: 'Edit Failed',
						message: 'Current password invalid. Passwords use 8 or more characters with a mix of letters, numbers and symbols.',
					});
				} else if (newPassword != confirmPassword) {
					this.toastService.addToast({
						type: ToastType.ERROR,
						title: 'Edit Failed',
						message: 'New password and confirmation password must match.',
					});
				} else {
					this.userService.updateUser(firstName, lastName, email, phone, currentPassword, newPassword).subscribe({
						next: () => {
							this.editDetailsForm.patchValue({
								currentPassword: null,
								newPassword: null,
								confirmPassword: null,
							});
							this.toastService.addToast({
								type: ToastType.SUCCESS,
								title: 'Update Successful',
								message: 'Password updated successfully!',
							});
						},
						error: (error: any) => {
							console.log('Error updating user:', error);

							if (error.error.error_msg.startsWith('Invalid Current Password')) {
								this.toastService.addToast({
									type: ToastType.ERROR,
									title: 'Edit Failed',
									message: 'Invalid Current Password. New Password cannot be updated.',
								});
							}
						},
					});
				}
			}
		} else {
			this.toastService.addToast({
				type: ToastType.ERROR,
				title: 'Edit Failed',
				message: 'Edited values invalid.',
			});
		}
	}

	deleteUser() {
		this.userService.deleteUser().subscribe({
			next: () => {
				this.toastService.addToast({
					type: ToastType.SUCCESS,
					title: 'User Account Delete Successful',
					message: 'User account was successfully deleted.',
				});

				this.authService.logout();
				this.router.navigate(['/']);
			},
			error: (_: any) => {
				this.toastService.addToast({
					type: ToastType.ERROR,
					title: 'User Account Delete Failed',
					message: 'Deleting user account failed. Please try again.',
				});
			},
		});
	}

	private getUserData() {
		this.userService.getUser().subscribe({
			next: (res: User) => {
				this.editDetailsForm.patchValue({
					firstName: res.firstName,
					lastName: res.lastName,
					email: res.email,
					phone: res.phone,
				});
			},
			error: (error) => {
				console.log('Error getting user data:', error);
			},
		});
	}
}
