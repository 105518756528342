import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';
import { SFXFileType } from 'src/app/enums/SFXFileType';
import { ProductTypes } from '../../../enums/ProductTypes';
import { Category } from '../../../interfaces/Category';
import { DeliveryMethod } from '../../../interfaces/DeliveryMethod';
import { FileFormat } from '../../../interfaces/FileFormat';
import { Genre } from '../../../interfaces/Genre';
import { Merchandise } from '../../../interfaces/Merchandise';
import { Pack } from '../../../interfaces/Pack';
import { Product } from '../../../interfaces/Product';
import { ProductType } from '../../../interfaces/ProductType';
import { ToastPayload, ToastType } from '../../../interfaces/ToastMessage';
import { Tutorial } from '../../../interfaces/Tutorial';
import { ProductService } from '../../../services/product.service';
import { RefDataService } from '../../../services/ref-data.service';
import { ToastService } from '../../../services/toast.service';
import { CategoriesModalComponent } from './categories-modal/categories-modal.component';
import { DeliveryMethodModalComponent } from './delivery-method-modal/delivery-method-modal.component';
import { FormatsModalComponent } from './formats-modal/formats-modal.component';
import { GenresModalComponent } from './genres-modal/genres-modal.component';

@Component({
	selector: 'app-add-product',
	templateUrl: './add-product.component.html',
	styleUrls: ['./add-product.component.scss'],
})
export class AddProductComponent implements OnInit {
	select_product_types: ProductType[];
	select_delivery_methods: DeliveryMethod[];
	select_categories: Category[];
	select_formats: FileFormat[];
	select_genres: Genre[];
	styleSuggestions: string[] = [];
	brandSuggestions: string[] = [];

	productType: ProductTypes | undefined;
	showErrors: boolean = false;
	id: string;
	isEditMode: boolean = false;
	descriptionContent: string = '';
	bsModalRef?: BsModalRef;
	price: number;

	ProductTypes = ProductTypes;

	public sfxFileType: typeof SFXFileType = SFXFileType;
	public addProductForm: FormGroup = this.formBuilder.group({
		productName: ['', Validators.required],
		price: [''],
		imageFileUrl: [''],
		productTypeId: ['', Validators.required],
		deliveryTypeId: [''],
		description: [''],
		isFeatured: [false],

		// TODO: Refactor into own component, like merch and tutorials
		// Sound Pack only controls
		categoryId: [''],
		downloadFileUrl: [''],
		audioPreviewFileUrl: [''],
		formatIds: [[]],
		genreIds: [[]],
		style: [[]],
		brand: [''],
		bpm: [''],
		resolution: [''],
		instruments: [[]],

		// Merch only controls
		merchandise: [],
		tutorial: [],
	});

	constructor(
		private formBuilder: FormBuilder,
		private refDataService: RefDataService,
		private route: ActivatedRoute,
		private router: Router,
		private productService: ProductService,
		private toastService: ToastService,
		private modalService: BsModalService
	) {}

	async ngOnInit() {
		this.id = this.route.snapshot.params['id'];

		if (this.id) this.isEditMode = true;

		await this.loadDropdowns();
		await this.loadEditForm();

		this.addProductForm.get('productTypeId')?.valueChanges.subscribe((val) => {
			this.productType = val as ProductTypes;
			this.fixValidators();
		});
	}

	async loadDropdowns() {
		[this.select_product_types, this.select_delivery_methods] = await Promise.all([
			lastValueFrom(this.refDataService.getAllProductTypes()),
			this.refDataService.getAllDeliveryMethods(),
		]);

		// soundpack things
		[this.select_formats, this.select_categories, this.select_genres, this.styleSuggestions, this.brandSuggestions] = await Promise.all([
			this.refDataService.getAllFileFormats(),
			this.refDataService.getAllCategories(),
			this.refDataService.getAllGenres(),
			this.productService.getSoundpackSuggestions('styles'),
			this.productService.getSoundpackSuggestions('brand'),
		]);
	}

	async loadEditForm() {
		if (this.isEditMode) {
			let product = await lastValueFrom(this.productService.getProduct(this.id));
			console.log(product);

			this.addProductForm.patchValue({
				productName: product.productName || '',
				price: product.price || '',
				imageFileUrl: product.imageFileUrl || '',
				productTypeId: product.productTypeId || '',
				deliveryTypeId: product.deliveryTypeId || '',
				description: product.description || '',
				isFeatured: product.isFeatured || false,
			});

			this.addProductForm.get('productTypeId')?.disable();
			this.productType = product.productTypeId as ProductTypes;

			if (product.productTypeId == ProductTypes.PACK) {
				let soundPack = await lastValueFrom(this.productService.getAdminSoundPack(this.id));
				console.log(soundPack);

				this.addProductForm.patchValue({
					categoryId: soundPack.categoryId || '',
					downloadFileUrl: soundPack.packFileName || '',
					audioPreviewFileUrl: soundPack.previewFileName || '',
					formatIds: soundPack.formatIds || [],
					genreIds: soundPack.genreIds || [],
					style: soundPack.styles || [],
					brand: soundPack.brand || '',
					bpm: soundPack.bpm || '',
					resolution: soundPack.resolution || '',
					instruments: soundPack.instruments || [],
				});
			} else if (product.productTypeId == ProductTypes.MERCH) {
				let merchandise = await lastValueFrom(this.productService.getAdminMerchandise(this.id));

				this.addProductForm.patchValue({
					merchandise: merchandise || null,
				});
			} else if (product.productTypeId == ProductTypes.TUTORIAL) {
				let tutorial = (await lastValueFrom(this.productService.getAdminTutorial(this.id))) as Tutorial;
				console.log(tutorial);
				this.addProductForm.patchValue({
					tutorial: tutorial || null,
				});
			}

			this.fixValidators();
		}
	}

	/* Form Interaction */
	shouldShowOptions(productType?: ProductTypes) {
		return this.productType === productType;
	}

	fixValidators() {
		let f = this.addProductForm;

		// Set new validators
		if (this.productType === ProductTypes.PACK) {
			f.get('categoryId')?.setValidators([Validators.required]);
			f.get('categoryId')?.updateValueAndValidity();
			f.get('downloadFileUrl')?.setValidators([Validators.required]);
			f.get('downloadFileUrl')?.updateValueAndValidity();
		} else {
			f.get('categoryId')?.clearValidators();
			f.get('categoryId')?.updateValueAndValidity();
			f.get('downloadFileUrl')?.clearValidators();
			f.get('downloadFileUrl')?.updateValueAndValidity();
		}

		if (this.productType === ProductTypes.TUTORIAL) {
			f.get('tutorial')?.setValidators([Validators.required]);
			f.get('tutorial')?.updateValueAndValidity();
		} else {
			f.get('tutorial')?.clearValidators();
			f.get('tutorial')?.updateValueAndValidity();
		}

		if (this.productType === ProductTypes.MERCH) {
			f.get('merchandise')?.setValidators([Validators.required]);
			f.get('merchandise')?.updateValueAndValidity();
		} else {
			f.get('merchandise')?.clearValidators();
			f.get('merchandise')?.updateValueAndValidity();
		}
	}

	onPriceChange() {
		this.price = Number.parseFloat(this.addProductForm.get('price')?.value) ?? 0;
	}

	/* Form Submission */
	getProductOptions(): Product {
		let product: Product = {};
		let f = this.addProductForm;
		if (this.isEditMode) product.productId = this.id;

		// required fields

		if (f.get('productName')?.value) product.productName = f.get('productName')?.value;

		if (f.get('productTypeId')?.value) product.productTypeId = f.get('productTypeId')?.value;

		if (f.get('deliveryTypeId')?.value) product.deliveryTypeId = f.get('deliveryTypeId')?.value.toString();

		let price = f.get('price')?.value.toString();
		product.price = price != '' ? price : '0';

		if (f.get('imageFileUrl')?.value) product.imageFileUrl = f.get('imageFileUrl')?.value;
		else product.imageFileUrl = null;

		if (f.get('description')?.value) product.description = f.get('description')?.value;
		else product.description = null;

		if (f.get('isFeatured')?.value) product.isFeatured = f.get('isFeatured')?.value;
		else product.isFeatured = false;

		return product;
	}

	getSoundOptions(): Pack {
		let pack: Pack = {};
		let f = this.addProductForm;

		if (f.get('categoryId')?.value) pack.categoryId = f.get('categoryId')?.value;

		if (f.get('downloadFileUrl')?.value != '') pack.packFileName = f.get('downloadFileUrl')?.value;

		if (f.get('audioPreviewFileUrl')?.value != '') pack.previewFileName = f.get('audioPreviewFileUrl')?.value;
		else pack.previewFileName = null;

		let formats: any[] = f.get('formatIds')?.value;
		if (formats && Array.isArray(formats)) {
			pack.formatIds = formats;
		} else pack.formatIds = formats;

		let genres: any[] = f.get('genreIds')?.value;
		if (genres && Array.isArray(genres)) {
			pack.genreIds = genres;
		} else pack.genreIds = [];

		let styles: any[] = f.get('style')?.value;
		if (styles && Array.isArray(styles)) pack.styles = styles;
		else pack.styles = [];

		if (f.get('brand')?.value) pack.brand = f.get('brand')?.value;
		else pack.brand = null;

		if (f.get('bpm')?.value && !isNaN(f.get('bpm')?.value)) pack.bpm = f.get('bpm')?.value.toString();
		else pack.bpm = null;

		if (f.get('resolution')?.value) pack.resolution = f.get('resolution')?.value;
		else pack.resolution = null;

		let instruments: any[] = f.get('instruments')?.value;
		if (instruments && Array.isArray(instruments)) pack.instruments = f.get('instruments')?.value;
		else pack.instruments = [];

		return pack;
	}

	addProduct() {
		if (!this.addProductForm.valid) {
			this.showErrors = true;
			return;
		}

		let product = this.getProductOptions();
		console.log(product);

		if (this.productType === ProductTypes.PACK) {
			let pack = this.getSoundOptions();
			console.log(pack);
			if (!this.isEditMode) {
				this.productService.addSoundPack(product, pack).subscribe({
					next: this.alertSuccess(product.productName, 'Sound Pack', 'uploaded'),
					error: this.alertFailure(product.productName, 'Sound Pack', 'upload'),
				});
			} else {
				this.productService.updateSoundPack(product, pack).subscribe({
					next: this.alertSuccess(product.productName, 'Sound Pack', 'updated'),
					error: this.alertFailure(product.productName, 'Sound Pack', 'update'),
				});
			}
		} else if (this.productType === ProductTypes.MERCH) {
			let merch = (this.addProductForm.get('merchandise')?.value as Merchandise) ?? {};
			console.log(merch);
			if (!this.isEditMode) {
				this.productService.addMerchandise(product, merch).subscribe({
					next: this.alertSuccess(product.productName, 'Merchandise', 'uploaded'),
					error: this.alertFailure(product.productName, 'Merchandise', 'upload'),
				});
			} else {
				this.productService.updateMerchandise(product, merch).subscribe({
					next: this.alertSuccess(product.productName, 'Merchandise', 'updated'),
					error: this.alertFailure(product.productName, 'Merchandise', 'update'),
				});
			}
		} else if (this.productType === ProductTypes.TUTORIAL) {
			let tutorial = this.addProductForm.get('tutorial')?.value as Tutorial;
			console.log(tutorial);
			if (!this.isEditMode) {
				this.productService.addTutorial(product, tutorial).subscribe({
					next: this.alertSuccess(product.productName, 'Tutorial', 'uploaded'),
					error: this.alertFailure(product.productName, 'Tutorial', 'upload'),
				});
			} else {
				this.productService.updateTutorial(product, tutorial).subscribe({
					next: this.alertSuccess(product.productName, 'Tutorial', 'updated'),
					error: this.alertFailure(product.productName, 'Tutorial', 'update'),
				});
			}
		} else {
			if (!this.isEditMode)
				this.productService.addProduct(product).subscribe({
					next: this.alertSuccess(product.productName, 'Product', 'uploaded'),
					error: this.alertFailure(product.productName, 'Product', 'upload'),
				});
			else {
				this.productService.updateProduct(product).subscribe({
					next: this.alertSuccess(product.productName, 'Product', 'updated'),
					error: this.alertFailure(product.productName, 'Product', 'update'),
				});
			}
		}
	}

	private alertFailure(name: string | undefined, type: string, action: string) {
		return (error: any) => {
			let toast: ToastPayload = {
				title: `${type} failed to ${action}.`,
				type: ToastType.ERROR,
				message: name + ' had errors: ' + error?.message,
			};
			this.toastService.addToast(toast);
		};
	}

	private alertSuccess(name: string | undefined, type: string, action: string) {
		return async (_: any) => {
			let toast: ToastPayload = {
				title: `${type} ${action}!`,
				type: ToastType.SUCCESS,
				message: name + ` was ${action} successfully.`,
			};
			this.toastService.addToast(toast);
			await this.router.navigate(['admin', 'products']);
		};
	}

	findInvalidControls() {
		const invalid = [];
		const controls = this.addProductForm.controls;
		for (const name in controls) {
			if (controls[name].invalid) {
				invalid.push(name);
			}
		}
		return invalid;
	}

	openGenresModal() {
		this.bsModalRef = this.modalService.show(GenresModalComponent);
		this.bsModalRef?.onHide?.subscribe(async (_) => {
			let currentValue = this.addProductForm.get('genreIds')?.value;
			this.select_genres = await this.refDataService.getAllGenres();
			// Wait to patch the value. It doesn't like it happening immediately.
			await new Promise((r) => setTimeout(r, 100));
			this.addProductForm.get('genreIds')?.setValue(currentValue);
		});
	}

	onGenreChange(selectedItems: Genre[]) {
		console.log(selectedItems);
		let results: any[] = [];
		let genres = this.select_genres;
		selectedItems.forEach((item) => {
			results.push(item.genreId);
			const genre = genres.find((g) => g.genreId === item.genreId);
			if (genre && genre.relatedGenreId !== null) {
				let relatedGenreId: string | null | undefined = genre.relatedGenreId;
				while (relatedGenreId !== null) {
					const parentGenre = genres.find((g) => g.genreId === relatedGenreId);
					if (parentGenre) {
						if (!results.includes(parentGenre.genreId)) results.push(parentGenre.genreId);
						relatedGenreId = parentGenre.relatedGenreId;
					}
				}
			}
		});

		this.addProductForm.patchValue({
			genreIds: results,
		});
	}

	deleteProduct() {
		let shouldDelete = confirm('Are you sure you want to permanently delete this product? This action cannot be undone.');

		if (shouldDelete && this.id) {
			let productName = this.addProductForm.get('productName')?.value;
			this.productService.deleteProduct(this.id).subscribe({
				next: this.alertSuccess(productName, 'Product', 'deleted'),
				error: this.alertFailure(productName, 'Product', 'delete'),
			});
		}
	}

	openDeliveryMethodModal() {
		this.bsModalRef = this.modalService.show(DeliveryMethodModalComponent);
		this.bsModalRef?.onHide?.subscribe(async (_) => {
			let currentValue = this.addProductForm.get('deliveryTypeId')?.value;
			this.select_delivery_methods = await this.refDataService.getAllDeliveryMethods();
			this.addProductForm.get('deliveryTypeId')?.setValue(currentValue);
		});
	}

	openCategoriesModal() {
		this.bsModalRef = this.modalService.show(CategoriesModalComponent);
		this.bsModalRef?.onHide?.subscribe(async (_) => {
			let currentValue = this.addProductForm.get('categoryId')?.value;
			this.select_categories = await this.refDataService.getAllCategories();
			this.addProductForm.get('categoryId')?.setValue(currentValue);
		});
	}

	openFormatsModal() {
		this.bsModalRef = this.modalService.show(FormatsModalComponent);
		this.bsModalRef?.onHide?.subscribe(async (_) => {
			let currentValue = this.addProductForm.get('formatIds')?.value;
			this.select_formats = await this.refDataService.getAllFileFormats();
			// Wait to patch the value. It doesn't like it happening immediately.
			await new Promise((r) => setTimeout(r, 100));
			this.addProductForm.get('formatIds')?.setValue(currentValue);
		});
	}
}
