<div class="container py-5" style="text-align: center; margin: auto">
	<header>
		<h1 style="font-weight: bold; margin-bottom: 0">Connect With Us!</h1>
		<h5 style="padding: 15px 0 0 0; margin: 0">Get in Tune with the Audio Universe! Connect with Artists, Producers, and Audio Lovers Worldwide</h5>
	</header>
</div>

<div class="container" style="margin: auto">
	<div class="row">
		<div class="col-lg-7 mx-auto text-center">
			<div class="card mb-4 shadow-sm">
				<div class="card-body p-5">
					<!-- Block Social Links-->
					<ul class="list-unstyled" style="padding-left: 0">
						<li class="mb-2">
							<!-- TikTok -->
							<a href="" class="social-link social-tiktok d-flex align-items-center py-2 rounded-pill shadow-sm" style="color: #fff; background: #ff8c3f; display: block; transition: all 0.3s; text-decoration: none">
								<span class="icon py-1" style="width: 4rem; text-align: center; margin-right: 1.5rem; border-right: 1px solid rgba(255, 255, 255, 0.4)">
									<i class="bi bi-tiktok"></i>
								</span>
								<span class="font-weight-bold">Check out our TikTok!</span>
							</a>
						</li>
						<li class="mb-2">
							<!-- Facebook -->
							<a href="" class="social-link social-facebook d-flex align-items-center py-2 rounded-pill shadow-sm" style="color: #fff; background: #3b5998; display: block; transition: all 0.3s; text-decoration: none">
								<span class="icon py-1" style="width: 4rem; text-align: center; margin-right: 1.5rem; border-right: 1px solid rgba(255, 255, 255, 0.4)">
									<i class="bi bi-facebook"></i>
								</span>
								<span class="font-weight-bold">Join us on Facebook!</span>
							</a>
						</li>
						<li class="mb-2">
							<!-- Twitter -->
							<a href="" class="social-link social-twitter d-flex align-items-center py-2 rounded-pill shadow-sm" style="color: #fff; background: #00aced; display: block; transition: all 0.3s; text-decoration: none">
								<span class="icon py-1" style="width: 4rem; text-align: center; margin-right: 1.5rem; border-right: 1px solid rgba(255, 255, 255, 0.4)">
									<i class="bi bi-twitter"></i>
								</span>
								<span class="font-weight-bold">Follow our Tweets!</span>
							</a>
						</li>
						<li class="mb-2">
							<!-- Instagram -->
							<a href="" class="social-link social-instagram d-flex align-items-center py-2 rounded-pill shadow-sm" style="color: #fff; background: #e440b8; display: block; transition: all 0.3s; text-decoration: none">
								<span class="icon py-1" style="width: 4rem; text-align: center; margin-right: 1.5rem; border-right: 1px solid rgba(255, 255, 255, 0.4)">
									<i class="bi bi-instagram"></i>
								</span>
								<span class="font-weight-bold">Discover our Insta world!</span>
							</a>
						</li>

						<li class="mb-2">
							<!-- YouTube -->
							<a href="" class="social-link social-youtube d-flex align-items-center py-2 rounded-pill shadow-sm" style="color: #fff; background: #ff3f3f; display: block; transition: all 0.3s; text-decoration: none">
								<span class="icon py-1" style="width: 4rem; text-align: center; margin-right: 1.5rem; border-right: 1px solid rgba(255, 255, 255, 0.4)">
									<i class="bi bi-youtube"></i>
								</span>
								<span class="font-weight-bold">Subscribe to our YouTube channel!</span>
							</a>
						</li>
						<li class="mb-2">
							<!-- Email -->
							<a href="" class="social-link social-email d-flex align-items-center py-2 rounded-pill shadow-sm" style="color: #fff; background: #02b875; display: block; transition: all 0.3s; text-decoration: none">
								<span class="icon py-1" style="width: 4rem; text-align: center; margin-right: 1.5rem; border-right: 1px solid rgba(255, 255, 255, 0.4)">
									<i class="bi bi-envelope"></i>
								</span>
								<span class="font-weight-bold">Connect with us via Email!</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
