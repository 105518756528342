import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormBuilder,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	Validator,
	Validators,
} from '@angular/forms';
import { lastValueFrom, Subscription } from 'rxjs';
import { SFXFileType } from '../../../../enums/SFXFileType';
import { FileFormat } from '../../../../interfaces/FileFormat';
import { Product } from '../../../../interfaces/Product';
import { Tutorial } from '../../../../interfaces/Tutorial';
import { ProductService } from '../../../../services/product.service';
import { RefDataService } from '../../../../services/ref-data.service';

@Component({
	selector: 'app-tutorials-form',
	templateUrl: './tutorials-form.component.html',
	styleUrls: ['./tutorials-form.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: TutorialsFormComponent,
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: TutorialsFormComponent,
		},
	],
})
export class TutorialsFormComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy, Validator {
	@Input() price = 0;
	select_products: Product[];
	select_formats: FileFormat[];
	form: FormGroup = this.formBuilder.group({
		formatIds: [[]],
		fileName: [''],
		hyperlink: [''],
		relatedProductIds: [[]],
	});
	onChangeSubs: Subscription[] = [];
	touched: Boolean = false;
	sfxFileType = SFXFileType;
	isTutorialFree: boolean = true;
	shouldShowUpload: boolean = true;
	tutorialType: string = 'upload';

	constructor(private formBuilder: FormBuilder, private productService: ProductService, private refDataService: RefDataService) {
		this.onPriceChange();
	}

	onTouched: Function = () => {};

	async ngOnInit() {
		this.select_products = await lastValueFrom(this.productService.getAllProductsNonPaginated());
		this.select_formats = await this.refDataService.getAllFileFormats();

		let sub = this.form.valueChanges.subscribe((_) => {
			if (this.form.touched) {
				this.markAsTouched();
			}
		});

		this.onChangeSubs.push(sub);
	}

	private onPriceChange() {
		if (this.price === 0 || !this.price) {
			this.isTutorialFree = true;
			this.shouldShowUpload = this.tutorialType === 'upload';
		} else {
			this.shouldShowUpload = true;
			this.isTutorialFree = false;
		}

		this.fixValidators();
	}

	ngOnChanges() {
		this.onPriceChange();
	}

	ngOnDestroy() {
		for (let sub of this.onChangeSubs) {
			sub.unsubscribe();
		}
	}

	registerOnChange(onChange: any) {
		const sub = this.form.valueChanges.subscribe((_) => {
			let tutorial: Tutorial = {};

			let formatIds = this.form.get('formatIds')?.value;
			if (formatIds) tutorial.formatIds = formatIds;

			if (this.tutorialType == 'upload') {
				let fileName = this.form.get('fileName')?.value;
				if (fileName) tutorial.fileName = fileName;
				tutorial.hyperlink = null;
			} else {
				let hyperlink = this.form.get('hyperlink')?.value;
				if (hyperlink) tutorial.hyperlink = hyperlink;
				tutorial.fileName = null;
			}

			let relatedProductIds = this.form.get('relatedProductIds')?.value;
			if (relatedProductIds && relatedProductIds.length > 0) tutorial.relatedProductIds = relatedProductIds;

			onChange(tutorial);
		});

		this.onChangeSubs.push(sub);
	}

	registerOnTouched(onTouched: Function) {
		this.onTouched = onTouched;
	}

	setDisabledState(disabled: boolean) {
		if (disabled) {
			this.form.disable();
		} else {
			this.form.enable();
		}
	}

	async writeValue(tutorial: Tutorial) {
		if (tutorial) {
			this.select_products = await lastValueFrom(this.productService.getAllProductsNonPaginated());
			this.select_formats = await this.refDataService.getAllFileFormats();

			if (tutorial.hyperlink && !tutorial.fileName) this.tutorialType = 'hyperlink';
			else this.tutorialType = 'upload';

			this.form.patchValue(
				{
					formatIds: tutorial.formatIds || [],
					fileName: tutorial.fileName || '',
					hyperlink: tutorial.hyperlink || '',
					relatedProductIds: tutorial.relatedProductIds || [],
				},
				{ emitEvent: false }
			);

			this.onPriceChange();
		}
	}

	validate(_: AbstractControl) {
		if (this.form.valid) {
			return null;
		}

		let errors: any = {};

		errors = this.addControlErrors(errors, 'formatIds');
		errors = this.addControlErrors(errors, 'fileName');
		errors = this.addControlErrors(errors, 'hyperlink');
		errors = this.addControlErrors(errors, 'relatedProductIds');

		return errors;
	}

	addControlErrors(allErrors: any, controlName: string) {
		const errors = { ...allErrors };
		const controlErrors = this.form.controls[controlName].errors;

		if (controlErrors) {
			errors[controlName] = controlErrors;
		}
		return errors;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	onFreeTutorialTypeChange(event: any) {
		this.tutorialType = event.currentTarget.value;
		this.onPriceChange();
		this.fixValidators();
	}

	fixValidators() {
		if (this.shouldShowUpload) {
			this.form.get('fileName')?.setValidators([Validators.required]);
			this.form.get('fileName')?.updateValueAndValidity();
			this.form.get('hyperlink')?.clearValidators();
			this.form.get('hyperlink')?.updateValueAndValidity();
		} else {
			this.form.get('hyperlink')?.setValidators([Validators.required]);
			this.form.get('hyperlink')?.updateValueAndValidity();
			this.form.get('fileName')?.clearValidators();
			this.form.get('fileName')?.updateValueAndValidity();
		}
	}
}
