import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CartItem } from '../interfaces/CartItem';
import { Coupon } from '../interfaces/Coupon';
import { ToastType } from '../interfaces/ToastMessage';
import { AuthService } from './auth.service';
import { ProductService } from './product.service';
import { RefDataService } from './ref-data.service';
import { ToastService } from './toast.service';

@Injectable({
	providedIn: 'root',
})
export class CartService {
	public readonly apiUrl: string = environment.base_url;
	private readonly CACHE_CART_KEY = 'cartItems';

	public cartItems: CartItem[] = [];
	public cartItemsSubject: BehaviorSubject<CartItem[]> = new BehaviorSubject(this.cartItems);

	constructor(
		private http: HttpClient,
		public authService: AuthService,
		private productService: ProductService,
		private toastService: ToastService,
		private refDataService: RefDataService
	) {}

	public async transferLocalCartToDB(): Promise<void> {
		const localItems = await this.getAllItemsFromLocal();

		for (const item of localItems) {
			if (item.productId) {
				this.addItemToDB(item.productId, item.productQuantity);
			}
		}

		const cartItems = await this.getAllItemsFromDB();
		this.broadcastCartItems(cartItems);
	}

	public async getAllItems(): Promise<void> {
		let cartItems: CartItem[];
		if (this.authService.isAuthenticated()) {
			cartItems = await this.getAllItemsFromDB();
		} else {
			cartItems = await this.getAllItemsFromLocal();
			this.saveCartToLocalStorage(cartItems);
		}

		this.broadcastCartItems(cartItems);
	}

	public canAddDigitalProductToCart(item: CartItem): boolean {
		const found = this.cartItems.find((ci) => ci.productId === item.productId);

		if (!found) {
			return true;
		}

		if ((item.deliveryTypeName || '').toLowerCase() === 'digital') {
			return false;
		}
		return true;
	}

	public async addItem(item: CartItem): Promise<void> {
		let cartItems: CartItem[] = [];
		if (this.authService.isAuthenticated()) {
			if (item.productId) {
				const existingCartItem = this.cartItems.find((ci) => ci.productId === item.productId);
				if (existingCartItem) {
					// If item already in cart, increment instead
					await this.updateItemQuantity(existingCartItem, existingCartItem.productQuantity + 1);
				} else {
					await this.addItemToDB(item.productId);
				}
				cartItems = await this.getAllItemsFromDB();
			}
		} else {
			cartItems = await this.getAllItemsFromLocal();
			const existingCartItem = cartItems.find((ci) => ci.productId === item.productId);
			if (existingCartItem) {
				existingCartItem.productQuantity += 1;
			} else {
				cartItems.push(item);
			}
			this.saveCartToLocalStorage(cartItems);
			cartItems = await this.getAllItemsFromLocal();
			this.saveCartToLocalStorage(cartItems);
		}

		this.broadcastCartItems(cartItems);
	}

	public async updateItemQuantity(item: CartItem, newQuantity: number): Promise<void> {
		let cartItems: CartItem[] = [];
		if (this.authService.isAuthenticated()) {
			if (item.productId) {
				await this.updateItemQuantityOnDB(item.productId, newQuantity);
				cartItems = await this.getAllItemsFromDB();
			}
		} else {
			cartItems = await this.getAllItemsFromLocal();
			cartItems = cartItems.map((i) => {
				return {
					...i,
					productQuantity: i.productId === item.productId ? newQuantity : i.productQuantity,
				};
			});
			this.saveCartToLocalStorage(cartItems);
		}

		this.broadcastCartItems(cartItems);
	}

	public async clearCart(): Promise<void> {
		for (const item of this.cartItems) {
			this.removeItem(item, false);
		}

		this.broadcastCartItems([]);
	}

	public async removeItem(item: CartItem, broadcast = true): Promise<void> {
		let cartItems: CartItem[];
		if (this.authService.isAuthenticated()) {
			await firstValueFrom(
				this.http.delete(this.apiUrl + `/cart/user/${this.authService.getUserId()}/delete`, {
					body: {
						productId: item.productId,
					},
					...this.authService.getHttpHeaders(),
				})
			);

			cartItems = await this.getAllItemsFromDB();
		} else {
			cartItems = await this.getAllItemsFromLocal();
			cartItems = cartItems.filter((i) => i.productId !== item.productId);
			this.saveCartToLocalStorage(cartItems);
		}

		if (broadcast) {
			this.broadcastCartItems(cartItems);
		}
	}

	public addDiscountToCart(coupon: Coupon): void {
		const eligibleProducts = (coupon.associatedProductIds || []).filter((c) => !!c).map((c) => String(c));

		if (eligibleProducts.length === 0) {
			// Apply to all, prefill with everything in cart so that it applies to everything
			for (const item of this.cartItems) {
				const itemId = item.productId || '';
				eligibleProducts.push(itemId);
			}
		}

		if (coupon.isPercentage) {
			// This case is straight forward
			for (const item of this.cartItems) {
				const discount = (coupon.discount || 0) / 100;
				const itemId = item.productId || '';
				const itemPrice = (Number(item.price) || 0) * item.productQuantity;
				if (eligibleProducts.includes(itemId)) {
					const itemDiscount = discount * itemPrice;
					item.discountValue = itemDiscount;
					item.discountCouponId = coupon.couponId;
				}
			}
		} else {
			// This is a funky case
			let discountBank = coupon.discount || 0;
			let eligibleQuantities = this.cartItems
				.filter((c) => eligibleProducts.indexOf(c.productId || '') > -1)
				.map((c) => c.productQuantity)
				.reduce((prev, curr) => prev + curr, 0);

			for (const item of this.cartItems) {
				const itemId = item.productId || '';
				const itemPrice = (Number(item.price) || 0) * item.productQuantity;
				if (eligibleProducts.includes(itemId)) {
					const perQuantityDiscount = discountBank / eligibleQuantities;
					const itemDiscount = perQuantityDiscount * item.productQuantity;
					item.discountValue = Math.min(itemDiscount, itemPrice);
					discountBank -= item.discountValue;
					eligibleQuantities -= item.productQuantity;
					item.discountCouponId = coupon.couponId;
				}
			}

			// If discount is all used up, distribute proportionally to the item value
			if (discountBank <= 0) {
				const subtotalEligibleItems = this.cartItems
					.filter((c) => eligibleProducts.indexOf(c.productId || '') > -1)
					.map((c) => (Number(c.price) || 0) * c.productQuantity)
					.reduce((prev, curr) => prev + curr, 0);

				for (let i = 0; i < this.cartItems.length; i++) {
					const item = this.cartItems[i];
					const itemId = item.productId || '';
					const itemPrice = (Number(item.price) || 0) * item.productQuantity;
					if (eligibleProducts.includes(itemId)) {
						let proportionalDiscountValue = ((coupon.discount || 0) / subtotalEligibleItems) * itemPrice;
						item.discountValue = proportionalDiscountValue;
					}
				}
			}
		}

		this.broadcastCartItems(this.cartItems);
	}

	public saveCartToLocalStorage(cartItems: CartItem[]): void {
		const basicCartItems = cartItems.map((i) => {
			return {
				productId: i.productId || '',
				productQuantity: Number(i.productQuantity) || 1,
			};
		});
		localStorage.setItem(this.CACHE_CART_KEY, JSON.stringify(basicCartItems));
	}

	public verifyLocalCart(cartItems: CartItem[]): boolean {
		for (const item of cartItems) {
			if (!item.productId || !item.productQuantity || !Number.isInteger(item.productQuantity) || Number(item.productQuantity) < 0) {
				return false;
			}
		}
		return true;
	}

	public async getAllItemsFromLocal(): Promise<CartItem[]> {
		try {
			const basicCartItems = JSON.parse(localStorage.getItem(this.CACHE_CART_KEY) || '[]') || [];
			localStorage.setItem(this.CACHE_CART_KEY, '[]');

			if (!Array.isArray(basicCartItems)) {
				return [];
			}

			if (!this.verifyLocalCart(basicCartItems)) {
				return [];
			}

			const cartItems: CartItem[] = [];
			for (const basicCartItem of basicCartItems) {
				const productInfo = await this.productService.getProductById(basicCartItem.productId);
				cartItems.push({
					...productInfo,
					productQuantity: Number(basicCartItem.productQuantity) || 1,
				});
			}

			return cartItems;
		} catch (err) {
			return [];
		}
	}

	public sortCartItems(cartItems: CartItem[]): CartItem[] {
		return cartItems.sort((a: CartItem, b: CartItem) => {
			const productNameA = a.productName || '';
			const productNameB = b.productName || '';
			return productNameA.localeCompare(productNameB);
		});
	}

	public async getAllItemsFromDB(): Promise<CartItem[]> {
		return await firstValueFrom(
			this.http.get<CartItem[]>(this.apiUrl + `/cart/user/${this.authService.getUserId()}`, this.authService.getHttpHeaders())
		);
	}

	public broadcastCartItems(cartItems: CartItem[]): void {
		this.refDataService.getAllProductTypes().subscribe({
			next: (productTypes) => {
				for (const item of cartItems) {
					const type = productTypes.find((t) => t.productTypeId === item.productTypeId);
					if (type) {
						item.productTypeName = type.productTypeName;
					}
				}

				cartItems = this.sortCartItems(cartItems);
				this.cartItems = cartItems;
				this.cartItemsSubject.next(this.cartItems);
			},
			error: (_) => {},
		});
	}

	public async addItemToDB(productId: string, productQuantity = 1): Promise<void> {
		try {
			await firstValueFrom(
				this.http.post(
					this.apiUrl + `/cart/user/${this.authService.getUserId()}/add`,
					{ productId, productQuantity },
					this.authService.getHttpHeaders()
				)
			);
		} catch (err) {
			this.toastService.addToast({
				type: ToastType.ERROR,
				title: 'Error',
				message: `Failed adding item to cart.`,
			});
		}
	}

	public async updateItemQuantityOnDB(productId: string, newQuantity: number): Promise<void> {
		try {
			await firstValueFrom(
				this.http.put(
					this.apiUrl + `/cart/user/${this.authService.getUserId()}/update`,
					{ productId, productQuantity: newQuantity },
					this.authService.getHttpHeaders()
				)
			);
		} catch (err) {
			this.toastService.addToast({
				type: ToastType.ERROR,
				title: 'Error',
				message: `Failed updating cart item.`,
			});
		}
	}
}
