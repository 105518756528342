import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { parseStringToUserGroupRole, SFXUserGroup } from '../enums/UserGroup';
import { LoginUserResponse } from '../interfaces/User';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private readonly apiUrl: string = environment.base_url;
	private readonly CACHE_TOKEN_KEY = 'auth-token';
	private readonly CACHE_USER_ID_KEY = 'userId';
	private readonly CACHE_USER_ROLE_KEY = 'role';

	private token: string;
	private userId: string;
	private roleId: string;

	constructor(private readonly http: HttpClient, private readonly router: Router) {}

	signIn(email: string, password: string): Observable<LoginUserResponse> {
		const body = { email, password };
		return this.http.post<LoginUserResponse>(this.apiUrl + '/users/login', body, this.getHttpHeaders()).pipe(
			tap((user) => {
				this.saveToken(user.token);
				this.saveUserId(user.userId);
				this.saveUserRole(user.userGroup);
			})
		);
	}

	signUp(firstName: string, lastName: string, email: string, password: string): Observable<any> {
		const body = {
			firstName,
			lastName,
			email,
			password,
		};
		return this.http.post<any>(this.apiUrl + '/users/register', body, this.getHttpHeaders());
	}

	resetPassword(email: string): Observable<any> {
		const body = { email };
		return this.http.post<JSON>(this.apiUrl + '/users/resetPassword', body, this.getHttpHeaders());
	}

	createNewPassword(password: string, token: string): Observable<any> {
		const body = { password };
		return this.http.post<JSON>(this.apiUrl + `/users/resetPassword/${token}`, body, this.getHttpHeaders(token));
	}

	isAuthenticated(): boolean {
		const token = this.getToken();
		return !!token;
	}

	getToken() {
		this.token = localStorage.getItem(this.CACHE_TOKEN_KEY) || '';
		return this.token;
	}

	getUserId() {
		const userId = localStorage.getItem(this.CACHE_USER_ID_KEY);
		if (userId !== null) {
			this.userId = userId;
		}
		return this.userId;
	}

	getUserRole(): SFXUserGroup {
		const userGroup = localStorage.getItem(this.CACHE_USER_ROLE_KEY) || '';
		if (!userGroup) {
			this.roleId = '-1';
		} else {
			this.roleId = userGroup;
		}

		return parseStringToUserGroupRole(this.roleId);
	}

	getHttpHeaders(token: string = this.getToken()) {
		return {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			}),
		};
	}

	getHttpHeadersObject(token: string = this.getToken()) {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});
	}

	getNonJSONHttpHeadersObject(token: string = this.getToken()) {
		return new HttpHeaders({
			Authorization: `Bearer ${token}`,
		});
	}

	logout(redirect = true) {
		this.token = '';
		this.userId = '-1';
		this.roleId = '-1';

		localStorage.removeItem(this.CACHE_TOKEN_KEY);
		localStorage.removeItem(this.CACHE_USER_ID_KEY);
		localStorage.removeItem(this.CACHE_USER_ROLE_KEY);

		if (redirect) {
			this.router.navigate(['/sign-in']);
		}
	}

	private saveToken(token: string) {
		this.token = token;
		localStorage.setItem(this.CACHE_TOKEN_KEY, token);
	}

	private saveUserRole(role: string) {
		this.roleId = role;
		localStorage.setItem(this.CACHE_USER_ROLE_KEY, role);
	}

	private saveUserId(userId: string) {
		localStorage.setItem(this.CACHE_USER_ID_KEY, userId);
		this.userId = userId;
	}
}
