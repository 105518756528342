<div class="modal-header">
	<h4 class="modal-title pull-left">Manage Genres</h4>
	<button (click)="bsModalRef.hide()" aria-label="Close" class="btn-close close pull-right" type="button">
		<span aria-hidden="true" class="visually-hidden">&times;</span>
	</button>
</div>
<div class="modal-body">
	<form (ngSubmit)="addGenre()" [formGroup]="manageGenresForm">
		<div class="d-flex align-items-end gap-2">
			<div class="form-group w-50">
				<label class="form-label" for="genre-name">Genre Name</label>
				<input class="form-control" formControlName="genreName" id="genre-name" placeholder="Rock" required type="text" />
			</div>
			<div class="form-group w-50">
				<label class="form-label" for="parent-genre">Parent</label>
				<select class="form-control form-select" formControlName="parentGenre" id="parent-genre">
					<option selected value="">None (Base Genre)</option>
					<option *ngFor="let x of genres" [value]="x.genreId">
						{{ x.genreName }}
					</option>
				</select>
			</div>
			<input [disabled]="!manageGenresForm.valid" [value]="isEditMode ? 'Update' : 'Add New'" class="btn btn-primary" type="submit" />
			<button (click)="cancelEditMode()" *ngIf="isEditMode" class="btn btn-danger" type="button">Cancel</button>
		</div>
	</form>

	<hr />
	<div class="scroll-box">
		<table class="table">
			<thead>
				<tr>
					<th>Genre</th>
					<th>Parent</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let genre of genresWithSubGenres">
					<td [ngClass]="{ indented: genre.relatedGenreId }">{{ genre.genreName }}</td>
					<td>{{ getGenreName(genre.relatedGenreId) }}</td>
					<td class="icon-cell">
						<a (click)="showEditMenu(genre)" class="text-secondary" role="button"><span class="bi bi-pencil-square"></span></a>
					</td>
					<!-- TODO: Delete endpoint
      <td class="icon-cell"><a role="button" class="text-danger"><i class="bi bi-x-lg"></i></a></td>
      -->
				</tr>
			</tbody>
		</table>
	</div>
</div>
