import { AbstractControl } from '@angular/forms';
import { Coupon } from '../interfaces/Coupon';

export function couponExists(coupons: Coupon[]) {
	return (control: AbstractControl) => {
		let coupon = coupons.find((x) => x.couponId === control.value);
		if (coupon) {
			return coupon.isActive ? { notExisting: true } : { notInactive: true };
		}

		return null;
	};
}
