import { Component, OnInit } from '@angular/core';
import { Campaign } from 'src/app/interfaces/Campaign';
import { ToastType } from 'src/app/interfaces/ToastMessage';
import { NewsletterService } from 'src/app/services/newsletter.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
	selector: 'app-sfx-footer',
	templateUrl: './sfx-footer.component.html',
	styleUrls: ['./sfx-footer.component.scss'],
})
export class SfxFooterComponent implements OnInit {
	email: string;
	campaigns: Campaign[] = [];
	defaultCampaignId: string;

	constructor(private readonly newsletterService: NewsletterService, private readonly toastService: ToastService) {}

	ngOnInit(): void {}

	signUp() {
		this.newsletterService.getCampaigns().subscribe({
			next: (res: Campaign[]) => {
				res.forEach((element: Campaign) => {
					this.campaigns.push(element);
				});
				if (this.campaigns.length > 0) {
					this.defaultCampaignId = (this.campaigns[0] as Campaign).id;
				}

				this.newsletterService.doesSubscriberExist(this.email, this.defaultCampaignId).subscribe(
					(response) => {
						const isSubscriber = response.isSubscriber;
						console.log(`Subscriber exists: ${isSubscriber}`);
						if (!isSubscriber) {
							this.newsletterService.subscribeUser(this.email, this.defaultCampaignId).subscribe(
								(response) => {
									console.log(response);
									this.toastService.addToast({
										type: ToastType.SUCCESS,
										title: 'Subscribe Email Successful',
										message: `Email ${this.email} was successfully subscribed.`,
									});
								},
								(error) => {
									console.log(error);
									this.toastService.addToast({
										type: ToastType.ERROR,
										title: 'Subscribe Email Unsuccessful',
										message: `Email ${this.email} failed to subscribe. Please ensure valid email is used.`,
									});
								}
							);
						} else {
							this.toastService.addToast({
								type: ToastType.SUCCESS,
								title: `Email ${this.email} already subscribed`,
								message: `Email ${this.email} is a current subscriber.`,
							});
						}
					},
					(error) => {
						console.log('Error checking subscriber: ', error);
						this.toastService.addToast({
							type: ToastType.ERROR,
							title: 'Subscribe Email Unsuccessful',
							message: `An error occurred during subscription attempt. Please try again.`,
						});
					}
				);
			},
			error: (error) => {
				console.log('Error getting email campaign data:', error);
			},
		});
	}
}
