import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-sfx-loading-spinner',
	templateUrl: './sfx-loading-spinner.component.html',
	styleUrls: ['./sfx-loading-spinner.component.scss'],
})
export class SfxLoadingSpinnerComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
