<div [hidden]="isCustomOptionSelected" class="btn-group" dropdown>
	<button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">{{ isCustomOptionSelected ? customOptionValue : selectedOption.label }} <span class="caret"></span></button>
	<ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
		<li *ngFor="let option of dropdownOptions" (click)="onOptionSelect(option)" role="menuitem" [attr.data-value]="option.value">
			<span class="dropdown-item">{{ option.label }}</span>
		</li>

		<!-- TODO: Incomplete free form quantity because of issues with updating quantity on every input change -->
		<!-- <li class="divider dropdown-divider"></li>

        <li (click)="onOptionSelect({ value: 10, label: '10+' })" role="menuitem">
            <span class="dropdown-item">10+</span>
        </li> -->
	</ul>
</div>

<form [hidden]="!isCustomOptionSelected" [formGroup]="quantityForm">
	<input
		[ngClass]="{
			'is-invalid': f['quantity'].errors,
			'is-valid': !f['quantity'].errors
		}"
		class="form-control"
		formControlName="quantity"
		type="number"
		id="customOptionInput"
		[(ngModel)]="customOptionValue"
		(input)="onCustomOptionChange($event)"
	/>
</form>
