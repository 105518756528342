import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

interface CartQuantityOption {
	value: number;
	label: string;
}

@Component({
	selector: 'app-sfx-cart-quantity-dropdown',
	templateUrl: './sfx-cart-quantity-dropdown.component.html',
	styleUrls: ['./sfx-cart-quantity-dropdown.component.scss'],
})
export class SfxCartQuantityDropdownComponent implements OnInit {
	quantityForm: FormGroup;

	@Input() defaultSelectedOption: number = 1;
	@Output() optionSelected = new EventEmitter<number>();

	// These options are copied from Amazon
	dropdownOptions: CartQuantityOption[] = [
		{ value: 1, label: '1' },
		{ value: 2, label: '2' },
		{ value: 3, label: '3' },
		{ value: 4, label: '4' },
		{ value: 5, label: '5' },
		{ value: 6, label: '6' },
		{ value: 7, label: '7' },
		{ value: 8, label: '8' },
		{ value: 9, label: '9' },
	];

	selectedOption: CartQuantityOption;

	isCustomOptionSelected = false;
	customOptionValue = '';

	constructor(private readonly formBuilder: FormBuilder) {}

	ngOnInit(): void {
		this.quantityForm = this.formBuilder.group({
			quantity: ['', [Validators.required, Validators.min(1), Validators.max(99)]],
		});

		const matchingOption = this.dropdownOptions.find((option) => option.value === this.defaultSelectedOption);
		if (matchingOption) {
			this.selectedOption = matchingOption;
		} else {
			this.isCustomOptionSelected = true;
			document.getElementById('customOptionInput')!.innerText = `${this.defaultSelectedOption}`;
		}
	}

	// convenience getter for easy access to form fields
	get f() {
		return this.quantityForm.controls;
	}

	onOptionSelect(option: CartQuantityOption): void {
		this.selectedOption = option;

		if (option.value === 10) {
			this.isCustomOptionSelected = true;
			setTimeout(() => document.getElementById('customOptionInput')!.focus(), 0);
		} else {
			this.isCustomOptionSelected = false;
			this.optionSelected.emit(option.value);
		}
	}

	onCustomOptionChange(event: any): void {
		if (event.target.value) {
			const value = parseInt(event.target.value, 10);

			this.optionSelected.emit(value);
		} else {
			this.optionSelected.emit(1);
		}
	}
}
