import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastType } from 'src/app/interfaces/ToastMessage';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
	contactUsForm: FormGroup;
	isSubmitted = false;
	apiUrl: string = environment.base_url;

	constructor(
		private route: ActivatedRoute,
		private readonly titleService: Title,
		private readonly formBuilder: FormBuilder,
		private readonly authService: AuthService,
		private readonly router: Router,
		private toastService: ToastService,
		private readonly http: HttpClient
	) {}

	// convenience getter for easy access to form fields
	get f() {
		return this.contactUsForm.controls;
	}

	ngOnInit(): void {
		this.titleService.setTitle('Contact Us | SoundFormX');

		this.contactUsForm = this.formBuilder.group({
			first_name: ['', [Validators.required]],
			last_name: ['', [Validators.required]],
			email: ['', [Validators.required, Validators.email]],
			description: ['', [Validators.required]],
		});
	}

	onSubmit() {
		this.isSubmitted = true;

		if (!this.contactUsForm || this.contactUsForm.invalid) {
			return;
		}

		const firstName = this.f['first_name'].value;
		const lastName = this.f['last_name'].value;
		const email = this.f['email'].value;
		const description = this.f['description'].value;

		this.http
			.post<{}>(
				this.apiUrl + '/contact/submit',
				{
					firstName,
					lastName,
					email,
					description,
				},
				this.authService.getHttpHeaders()
			)
			.subscribe({
				next: () => {
					this.toastService.addToast({
						type: ToastType.SUCCESS,
						title: 'Message Received!',
						message: 'Thank you for contacting us. We have received your message and will respond shortly.',
					});
					this.router.navigate(['/']);
				},
				error: (error) => {
					console.log('Error sending contact form:', error);
					this.toastService.addToast({
						type: ToastType.ERROR,
						title: 'Oops! Something went wrong.',
						message:
							'We apologize, but there was an issue sending your message. Please try again later or contact our customer support for assistance.',
					});
				},
			});
	}
}
