import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastType } from 'src/app/interfaces/ToastMessage';

@Component({
	selector: 'app-sfx-toast',
	templateUrl: './sfx-toast.component.html',
	styleUrls: ['./sfx-toast.component.scss'],
})
export class SfxToastComponent implements OnInit {
	@Input() title!: string;
	@Input() message!: string;
	@Input() type!: ToastType;

	@Output() disposeEvent = new EventEmitter();

	ngOnInit() {}

	hide() {
		this.disposeEvent.emit();
	}
}
