import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timeout } from 'rxjs';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
	constructor() {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const timeoutValue = 10000; // set a default timeout of 10 seconds

		const timeoutRequest = request.clone({});

		return next.handle(timeoutRequest).pipe(timeout(timeoutValue));
	}
}
