<app-sfx-carousel></app-sfx-carousel>

<div class="container sfx-top-spacing">
	<h1>Featured</h1>
	<div *ngIf="!loaded">
		<app-sfx-loading-spinner></app-sfx-loading-spinner>
	</div>

	<div class="row p-5">
		<div class="col">
			<div class="row">
				<div *ngFor="let product of products" class="col-12 col-sm-6 col-md-4 col-lg-3">
					<app-sfx-product-listing [product]="product"></app-sfx-product-listing>
				</div>
			</div>
		</div>
	</div>
</div>
