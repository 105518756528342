<h4>Instruments</h4>
<fieldset [formGroup]="form">
	<div class="row mt-1">
		<div class="col form-group">
			<app-sfx-multiselect formControlName="instrumentIds" [dropdownList]="select_instruments" itemId="instrumentId" itemText="instrumentName"></app-sfx-multiselect>
		</div>
		<div class="col col-auto form-group">
			<button (click)="addInstrument()" (focus)="markAsTouched()" class="btn btn-secondary" type="button"><span class="bi-plus"></span> Add Detail</button>
		</div>
	</div>
	<div class="row form-text">
		<a (click)="openInstrumentsModal()" class="link-info small" role="button">Manage Instruments</a>
	</div>
	<div class="mt-1" formArrayName="detailedInstruments">
		<div *ngFor="let instrument of detailedInstruments().controls; let i = index" [formGroupName]="i" class="d-flex align-items-end mt-2">
			<div class="w-100">
				<div class="row">
					<div class="col col-sm-3 col-12 form-group">
						<label for="instrument-{{ i }}">Instrument</label>
						<select (focus)="markAsTouched()" class="form-control form-select" formControlName="instrumentTypeId" id="instrument-{{ i }}">
							<option disabled selected value="">Select...</option>
							<option *ngFor="let x of selected_instruments" [value]="x.instrumentId">
								{{ x.instrumentName }}
							</option>
						</select>
					</div>

					<div class="col col-sm-3 col-12 form-group">
						<label for="model-{{ i }}">Model</label>
						<app-sfx-suggestions formControlName="instrumentModel" id="model-{{ i }}" [suggestions]="suggestedModels" [placeholder]="suggestedModels[0] + '...'" (focus)="markAsTouched()"></app-sfx-suggestions>
					</div>

					<div class="col col-sm-3 col-12 form-group">
						<label for="technique-{{ i }}">Technique</label>
						<app-sfx-suggestions formControlName="playTechnique" id="technique-{{ i }}" [suggestions]="suggestedTechniques" [placeholder]="suggestedTechniques[0] + '...'" (focus)="markAsTouched()"></app-sfx-suggestions>
					</div>

					<div class="col col-sm-3 col-12 form-group">
						<label for="effect-{{ i }}">Effect</label>
						<app-sfx-suggestions formControlName="effect" id="effect-{{ i }}" [suggestions]="suggestedEffects" [placeholder]="suggestedEffects[0] + '...'" (focus)="markAsTouched()"></app-sfx-suggestions>
					</div>
				</div>
			</div>
			<div class="form-group">
				<a (click)="removeInstrument(i)" class="link-danger btn" role="button"><i class="bi-x-circle-fill"></i></a>
			</div>
		</div>
	</div>
</fieldset>
