import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { CartItem } from 'src/app/interfaces/CartItem';
import { CartService } from 'src/app/services/cart.service';
import { NgxSpinnerService } from 'src/app/services/ngx-spinner.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-sfx-detailed-cart',
	templateUrl: './sfx-detailed-cart.component.html',
	styleUrls: ['./sfx-detailed-cart.component.scss'],
})
export class SfxDetailedCartComponent implements OnInit {
	@Input() isEditable: boolean = true;

	cartItems: CartItem[] = [];
	selectedItem: CartItem;
	modalRef?: BsModalRef;
	cartItems$: Observable<CartItem[]> = this.cartService.cartItemsSubject.asObservable();
	private readonly storageUrl: string = environment.storage_url;

	constructor(private modalService: BsModalService, private spinner: NgxSpinnerService, private cartService: CartService) {
		this.cartService.getAllItems();
	}

	ngOnInit() {
		this.cartItems$.subscribe((cartItems) => {
			this.cartItems = cartItems;
		});
	}

	openConfirmationModal(item: CartItem, confirmationModal: TemplateRef<any>) {
		this.selectedItem = item;
		this.modalRef = this.modalService.show(confirmationModal);
	}

	getFullFileURL(item: CartItem): string {
		return `${this.storageUrl}/${encodeURIComponent(item.imageFileUrl || '')}`;
	}

	async removeItem(item: CartItem) {
		this.spinner.show();
		await this.cartService.removeItem(item);
		this.spinner.hide();
		this.modalRef?.hide();
	}

	async onAddSampleItem() {
		const item: CartItem = {
			productId: (Math.floor(Math.random() * 7) + 1).toString(),
			productQuantity: 1,
		};

		await this.cartService.addItem(item);
	}

	onOptionSelected(quantity: number, product: CartItem) {
		this.cartService.updateItemQuantity(product, quantity);
	}

	getItemSubtotal(singlePrice?: string, quantity?: number): number {
		return (parseFloat(singlePrice || '0') || 0) * (quantity || 1);
	}
}
