import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-checkout-success',
	templateUrl: './checkout-success.component.html',
	styleUrls: ['./checkout-success.component.scss'],
})
export class CheckoutSuccessComponent implements OnInit {
	constructor(private readonly titleService: Title) {}

	ngOnInit(): void {
		this.titleService.setTitle('Checkout Success! | SoundFormX');
	}
}
