import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom, map, Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Category } from '../interfaces/Category';
import { Merchandise } from '../interfaces/Merchandise';
import { Pack } from '../interfaces/Pack';
import { Product } from '../interfaces/Product';
import { ProductWithFilterTree } from '../interfaces/ProductWithFilterTree';
import { SoundPackProduct } from '../interfaces/SoundPackProduct';
import { Tutorial } from '../interfaces/Tutorial';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class ProductService {
	public readonly apiUrl: string = environment.base_url;

	constructor(public readonly http: HttpClient, public readonly authService: AuthService) {}

	public async getProductById(productId: string): Promise<Product> {
		return await firstValueFrom(this.http.get<Product>(this.apiUrl + `/products/${productId}`, this.authService.getHttpHeaders()));
	}

	addProduct(product: Product): Observable<any> {
		return this.http.post<{ token: string }>(this.apiUrl + '/products/add', product, this.authService.getHttpHeaders());
	}

	addSoundPack(product: Product, pack: Pack): Observable<any> {
		const body = {
			productOptions: product,
			soundPackOptions: pack,
		};
		return this.http.post<{ token: string }>(this.apiUrl + '/soundPacks/addProduct', body, this.authService.getHttpHeaders());
	}

	updateSoundPack(product: Product, pack: Pack): Observable<any> {
		const body = {
			productOptions: product,
			soundPackOptions: pack,
		};
		return this.http.put<{ token: string }>(this.apiUrl + '/soundPacks/update', body, this.authService.getHttpHeaders());
	}

	addMerchandise(product: Product, merch: Merchandise): Observable<any> {
		const body = {
			productOptions: product,
			merchandiseOptions: merch,
		};
		return this.http.post<{ token: string }>(this.apiUrl + '/merchandise/add', body, this.authService.getHttpHeaders());
	}

	updateMerchandise(product: Product, merch: Merchandise): Observable<any> {
		const body = {
			productOptions: product,
			merchandiseOptions: merch,
		};
		return this.http.put<{ token: string }>(this.apiUrl + '/merchandise/update', body, this.authService.getHttpHeaders());
	}

	updateProduct(product: Product): Observable<any> {
		return this.http.put<{ token: string }>(this.apiUrl + '/products/update', product, this.authService.getHttpHeaders());
	}

	getProduct(id: string): Observable<Product> {
		return this.http.get<Product>(this.apiUrl + '/products/' + id, this.authService.getHttpHeaders());
	}

	getAllProducts(): Observable<Product[]> {
		return this.http.get<Product[]>(this.apiUrl + '/products/all', {
			params: { limit: 200, sortBy: 'DESC', sortField: 'dateModified' },
			headers: this.authService.getHttpHeadersObject(),
		});
	}

	getAllProductsNonPaginated(): Observable<Product[]> {
		return this.http.get<Product[]>(this.apiUrl + '/products/all/non-pagination', this.authService.getHttpHeaders());
	}

	getAllPacks() {
		return this.http.get<SoundPackProduct>(this.apiUrl + '/soundPacks/all', this.authService.getHttpHeaders());
	}

	getAllPacksFeatured() {
		return this.http.get<SoundPackProduct>(this.apiUrl + '/soundPacks/all', this.authService.getHttpHeaders());
	}

	getAllPacksSorted(sortBy: string, sortField: string) {
		const h = this.authService.getHttpHeadersObject();
		return this.http.get<SoundPackProduct>(this.apiUrl + '/soundPacks/all', {
			params: { sortBy: sortBy, sortField: sortField },
			headers: h,
		});
	}

	getBatchAllProductsSorted(sortBy: string, sortField: string, page: number, limit: number) {
		return this.http.get<Product[]>(this.apiUrl + `/products/all`, {
			params: { sortBy, sortField, page, limit },
			headers: this.authService.getHttpHeadersObject(),
			observe: 'response',
		});
	}

	getBatchFeaturedProductsSorted(page: number, limit: number) {
		return this.http.get<Product[]>(this.apiUrl + `/products/featured`, {
			params: { page, limit },
			headers: this.authService.getHttpHeadersObject(),
			observe: 'response',
		});
	}

	getBatchProductsSorted(
		productType: string,
		sortBy: string,
		sortField: string,
		page: number,
		limit: number,
		filters: { [p: string]: string },
		searchQuery: string
	) {
		if (searchQuery) {
			return this.http.get<ProductWithFilterTree>(this.apiUrl + `/search/${this.productTypeToEndpointPath(productType)}`, {
				params:
					sortField === 'featured' ? { page, limit, searchQuery, ...filters } : { sortBy, sortField, page, limit, searchQuery, ...filters },
				headers: this.authService.getHttpHeadersObject(),
				observe: 'response',
			});
		}

		return this.http.get<ProductWithFilterTree>(this.apiUrl + `/${this.productTypeToEndpointPath(productType)}/all`, {
			params: sortField === 'featured' ? { page, limit, ...filters } : { sortBy, sortField, page, limit, ...filters },
			headers: this.authService.getHttpHeadersObject(),
			observe: 'response',
		});
	}

	productTypeToEndpointPath(productType: string) {
		if (productType.toLowerCase() === 'merchandise') {
			return 'merchandise';
		} else if (productType.toLowerCase() === 'soundpack') {
			return 'soundPacks';
		} else if (productType.toLowerCase() === 'tutorials') {
			return 'tutorials';
		}
		return 'products';
	}

	getPackDetailed(productId: string) {
		return this.http.get<SoundPackProduct>(
			this.apiUrl + '/soundPacks/' + productId + '?isDetailed=TRUE',
			this.authService.getHttpHeaders()
		);
	}

	getAllMerchandiseFeatured() {
		return this.http.get<SoundPackProduct>(this.apiUrl + '/merchandise/all', this.authService.getHttpHeaders());
	}

	getAllMerchandiseSorted(sortBy: string, sortField: string) {
		const h = this.authService.getHttpHeadersObject();
		return this.http.get<Merchandise>(this.apiUrl + '/merchandise/all', {
			params: { sortBy: sortBy, sortField: sortField },
			headers: h,
		});
	}

	getStandaloneTutorial(productId: string) {
		return this.http.get<Product>(this.apiUrl + '/tutorials/standalone/' + productId, this.authService.getHttpHeaders());
	}

	getLinkedTutorial(productId: string) {
		return this.http.get<Product>(this.apiUrl + '/tutorials/linked/' + productId, this.authService.getHttpHeaders());
	}

	getAllCategories(): Observable<Category> {
		return this.http.get<Category>(this.apiUrl + '/categories/all', this.authService.getHttpHeaders());
	}

	getAdminSoundPack(id: string): Observable<Pack> {
		return this.http.get<Pack>(this.apiUrl + '/soundPacks/' + id + '/admin', this.authService.getHttpHeaders());
	}

	getAdminMerchandise(id: string) {
		return this.http.get<Pack>(
			// for now only one url for merchandise
			this.apiUrl + '/merchandise/' + id,
			this.authService.getHttpHeaders()
		);
	}

	deleteProduct(id: string): Observable<any> {
		return this.http.delete<any>(this.apiUrl + '/products/' + id, this.authService.getHttpHeaders());
	}

	addTutorial(product: Product, tutorial: Tutorial): Observable<any> {
		const body = {
			productOptions: product,
			tutorialOptions: tutorial,
		};
		return this.http.post<{ token: string }>(this.apiUrl + '/tutorials/add', body, this.authService.getHttpHeaders());
	}

	updateTutorial(product: Product, tutorial: Tutorial): Observable<any> {
		const body = {
			productOptions: product,
			tutorialOptions: tutorial,
		};
		return this.http.put<{ token: string }>(this.apiUrl + '/tutorials/update', body, this.authService.getHttpHeaders());
	}

	getAdminTutorial(id: string) {
		return this.http.get<Pack>(this.apiUrl + '/tutorials/standalone/' + id + '/admin', this.authService.getHttpHeaders());
	}

	async getSoundpackSuggestions(type: string): Promise<string[]> {
		return lastValueFrom(
			this.http
				.get<string[]>(this.apiUrl + '/soundPacks/suggestions', {
					params: { distinctOn: type },
					headers: this.authService.getHttpHeadersObject(),
				})
				.pipe(map((x: string[]) => x.sort()))
				.pipe(retry(1))
		);
	}

	async getMerchandiseSuggestions(type: string) {
		return lastValueFrom(
			this.http
				.get<string[]>(this.apiUrl + '/merchandise/suggestions', {
					params: { distinctOn: type },
					headers: this.authService.getHttpHeadersObject(),
				})
				.pipe(map((x: string[]) => x.sort()))
				.pipe(retry(1))
		);
	}
}
