<div class="container-fluid sfx-top-spacing">
	<div class="sfx-center-container">
		<div class="form-wrapper text-center text-md-start row">
			<h1>Great to have you back!</h1>
			<p class="text-muted">You can sign in with your existing SoundFormX account.</p>
		</div>

		<div class="form-wrapper bg-white p-3 shadow">
			<form (ngSubmit)="onSubmit()" [formGroup]="signInForm">
				<h4 class="text-center text-md-start mb-4">Sign In</h4>

				<div class="form-group mt-3">
					<label for="sfx-email">Email</label>
					<input
						[ngClass]="{
							'is-invalid': isSubmitted && f['email'].errors,
							'is-valid': !f['email'].errors
						}"
						class="form-control mt-2"
						formControlName="email"
						id="sfx-email"
						placeholder="Email"
						type="email"
					/>
				</div>

				<div class="form-group mt-3">
					<label for="sfx-password">Password</label>
					<div class="input-group mt-2">
						<input
							[ngClass]="{
								'is-invalid': isSubmitted && f['password'].errors,
								'is-valid': !f['password'].errors
							}"
							[type]="showPassword ? 'text' : 'password'"
							class="form-control"
							formControlName="password"
							id="sfx-password"
							placeholder="Password"
							type="password"
						/>

						<div class="input-group-append">
							<span (click)="showPassword = !showPassword" class="input-group-text">
								<i
									[ngClass]="{
										'bi-eye': !showPassword,
										'bi-eye-slash': showPassword
									}"
									class="bi bi-eye"
								></i>
							</span>
						</div>
					</div>
				</div>

				<div class="form-group text-center mt-3">
					<button class="btn w-100 text-white bg-info" type="submit">Sign In</button>
				</div>

				<div class="form-group text-center mt-1 my-lg-2">
					<small>
						<span>Forgot password?</span>
						<a class="text-decoration-none text-sfx-info m-1" routerLink="/reset-password">Reset here</a>
					</small>
				</div>

				<div class="form-group text-center create-new mt-lg-2">
					<small>
						<span>New here?</span>
						<a class="text-decoration-none text-sfx-info m-1" routerLink="/sign-up">Create a SoundFormX account</a>
					</small>
				</div>
			</form>
		</div>
	</div>
</div>
