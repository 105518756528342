import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-sfx-user-nav',
	templateUrl: './sfx-user-nav.component.html',
	styleUrls: ['./sfx-user-nav.component.scss'],
})
export class SfxUserNavComponent implements OnInit {
	userid: string;

	constructor(private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.userid = '0';
	}
}
