<div *ngIf="label && (min !== undefined || max !== undefined)">
	<a class="filter-button" (click)="clearValue()" role="button">
		<span class="bi bi-arrow-left-short"></span>
		<span>Any {{ label }}</span>
	</a>
</div>
<div *ngFor="let defaultOption of defaultOptions; let i = index">
	<a [ngClass]="{ 'fw-bold': min === defaultOption.min && max === defaultOption.max }" class="filter-button" (click)="setValue(defaultOption.min, defaultOption.max)" role="button">
		{{ defaultOption.label }}
	</a>
</div>
<form class="filter-button" [formGroup]="form" (submit)="submit()">
	<span class="input-group">
		<input formControlName="min" type="number" placeholder="Min" class="form-control form-control-sm" />
		<span class="input-group-text">to</span>
		<input formControlName="max" type="number" placeholder="Max" class="form-control form-control-sm" />
		<button type="submit" class="btn btn-sm btn-info text-white">Go</button>
	</span>
</form>
