import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SFXFileType } from 'src/app/enums/SFXFileType';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-sfx-file-upload',
	templateUrl: './sfx-file-upload.component.html',
	styleUrls: ['./sfx-file-upload.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: SfxFileUploadComponent,
		},
	],
})
export class SfxFileUploadComponent implements OnInit, ControlValueAccessor {
	apiUrl: string = environment.base_url;

	@Input() accept: string;
	@Input() sfxFileType: SFXFileType;
	@Output() fileUrl = new EventEmitter<string>();
	@Input() disabled = false;

	percentage = 0;
	uploading = false;
	uploadSuccess = false;
	uploadFailure = false;
	uploadFileName = '';
	uploadFileUrl = '';
	uploadFileSize = '';
	touched = false;
	storageUrl = environment.storage_url;

	constructor(private storageService: StorageService) {}

	onChange = (_: string) => {};

	onTouched = () => {};

	ngOnInit() {
		this.resetToDefault();
	}

	resetToDefault() {
		this.percentage = 0;
		this.uploading = false;
		this.uploadSuccess = false;
		this.uploadFailure = false;
		this.uploadFileName = '';
		this.uploadFileUrl = '';
		this.uploadFileSize = '';
	}

	uploadFile(event: Event) {
		this.markAsTouched();

		if (this.disabled) {
			return;
		}

		const files = (event.target as HTMLInputElement).files;

		if (!files || files.length === 0) {
			return;
		}

		this.resetToDefault();
		const file = files[0];
		this.uploadFileName = file.name;

		this.uploading = true;
		this.storageService.uploadFile(file, this.sfxFileType).subscribe({
			next: (status) => {
				this.percentage = status.percentage;
				this.uploadFileSize = status.size;
				console.log(status);

				if (status.filePath) {
					console.log(status.filePath);
					this.fileUrl.emit(status.filePath);
					this.onChange(status.filePath);
					this.uploadFileUrl = status.filePath;
					this.uploadSuccess = true;
					this.uploading = false;
				}
			},
			error: (_) => {
				this.uploadFailure = true;
				this.uploading = false;
				this.onChange('');
			},
		});
	}

	registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any): void {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	writeValue(fileUrl: string): void {
		this.uploadFileUrl = fileUrl;
		let fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
		this.uploadFileName = decodeURI(fileName.replace(/\d+_/, ''));
		this.uploadSuccess = true;
	}

	isImage() {
		return this.uploadFileUrl.match(/\.(jpg|jpeg|png|gif)$/i);
	}
}
