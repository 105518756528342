import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FileFormat } from 'src/app/interfaces/FileFormat';
import { ToastType } from '../../../../interfaces/ToastMessage';
import { RefDataService } from '../../../../services/ref-data.service';
import { ToastService } from '../../../../services/toast.service';

@Component({
	selector: 'app-formats-modal',
	templateUrl: './formats-modal.component.html',
	styleUrls: ['./formats-modal.component.scss'],
})
export class FormatsModalComponent implements OnInit {
	formats: FileFormat[];
	formatForm: FormGroup;
	currentFormat: FileFormat | null = null;
	isEditMode = false;

	constructor(
		public bsModalRef: BsModalRef,
		private formBuilder: FormBuilder,
		private refDataService: RefDataService,
		private toastService: ToastService
	) {}

	async ngOnInit() {
		this.bsModalRef.setClass('modal-lg');
		this.initializeForm();
		await this.loadFormats();
	}

	async loadFormats() {
		this.formats = await this.refDataService.getAllFileFormats();
	}

	initializeForm() {
		this.formatForm = this.formBuilder.group({
			formatName: ['', Validators.required],
		});
	}

	async onSave() {
		if (this.formatForm.valid) {
			const format: FileFormat = {
				formatName: this.formatForm.get('formatName')?.value,
			};

			if (this.isEditMode && this.currentFormat?.formatId) {
				format.formatId = this.currentFormat.formatId;
				console.log(format);
				this.refDataService.updateFormat(format).subscribe({
					next: async (_: any) => {
						this.toastService.addToast({
							title: 'Format updated!',
							type: ToastType.SUCCESS,
							message: format.formatName + ' updated successfully.',
						});
						await this.resetFormAndReload();
					},
					error: (error: any) => {
						this.toastService.addToast({
							title: 'Failed to update format.',
							type: ToastType.ERROR,
							message: format.formatName + ' had errors: ' + error?.message,
						});
					},
				});
			} else {
				await this.refDataService.createFormat(format).subscribe({
					next: async (_: any) => {
						this.toastService.addToast({
							title: 'Format added!',
							type: ToastType.SUCCESS,
							message: format.formatName + ' added successfully.',
						});
						await this.resetFormAndReload();
					},
					error: (error: any) => {
						this.toastService.addToast({
							title: 'Failed to add format.',
							type: ToastType.ERROR,
							message: format.formatName + ' had errors: ' + error?.message,
						});
					},
				});
			}
		}
	}

	private async resetFormAndReload() {
		this.formatForm.reset();
		this.currentFormat = null;
		this.isEditMode = false;
		await this.loadFormats();
	}

	onEdit(format: FileFormat) {
		this.isEditMode = true;
		this.currentFormat = format;
		this.formatForm.patchValue({
			formatName: format.formatName || '',
		});
	}

	onCancel() {
		this.formatForm.reset();
		this.currentFormat = null;
		this.isEditMode = false;
	}
}
