<div *ngIf="product" class="container sfx-top-spacing">
	<div class="row">
		<div class="col-12 col-xl-4 col-lg-5 col-md-6 col-sm-12">
			<div class="d-flex p-5 p-md-0">
				<img [alt]="product.productName" [src]="getFullImageFileURL(product)" class="product-image w-100 img-fluid" />
			</div>
		</div>
		<div class="col-12 col-xl-8 col-lg-7 col-md-6 col-sm-12 p-2">
			<b>
				<h1>{{ product.productName }}</h1>
			</b>
			<h4>{{ this.productType }}</h4>
			<b>
				<h2 class="pt-3 mt-2">{{ getFormattedPrice(product.price) }}</h2>
			</b>
			<div *ngIf="productPack?.previewFileName">
				<audio class="pt-3 pb-3" controls>
					<source [src]="getFullPreviewFileUrl(productPack)" type="audio/mpeg" />
					Your browser does not support the audio element.
				</audio>
			</div>
			<div *ngIf="productType === 'Merchandise'">
				<div class="col-12 col-md-3 pt-3 pb-3">
					<div class="input-group">
						<div class="input-group-prepend">
							<button class="btn btn-outline-secondary" type="button" (click)="decrementValue()">-</button>
						</div>
						<input type="number" class="form-control" [(ngModel)]="quantity" value="1" min="1" max="10" id="quantityInput" />
						<div class="input-group-append">
							<button class="btn btn-outline-secondary" type="button" (click)="incrementValue()">+</button>
						</div>
					</div>
				</div>
			</div>
			<button id="addToCartButton" (click)="addProductToCartIfNotOwned()" class="btn btn-primary btn-lg btn-rounded float-start pt-2 pb-2 mt-3" type="button">Add to Cart <span class="ms-3 bi bi-cart-plus-fill"></span></button>
		</div>
	</div>
	<div class="row mt-3">
		<div *ngIf="productPack" class="product-info">
			<div class="button-container">
				<div *ngIf="productPack.category" class="button-item category-button">
					<button>{{ productPack.category }}</button>
				</div>

				<ng-container *ngIf="productPack.genres">
					<div class="button-item genre-button" *ngFor="let genre of productPack.genres">
						<button>{{ genre }}</button>
					</div>
				</ng-container>

				<ng-container *ngIf="productPack.instruments">
					<div class="button-item instrument-button" *ngFor="let instrument of productPack.instruments">
						<button>{{ instrument.instrumentType }}</button>
					</div>
				</ng-container>

				<div *ngIf="productPack.bpm" class="button-item bpm-button">
					<button>{{ productPack.bpm }} BPM</button>
				</div>

				<ng-container *ngIf="productPack.formats">
					<div class="button-item format-button" *ngFor="let format of productPack.formats">
						<button>{{ format }}</button>
					</div>
				</ng-container>

				<div *ngIf="productPack.resolution" class="button-item resolution-button">
					<button>{{ productPack.resolution }}</button>
				</div>
			</div>
		</div>
	</div>
	<div class="row mt-3" *ngIf="product.description">
		<h3>Description</h3>
		<p [innerHTML]="product.description"></p>
	</div>
	<div class="row mt-3" *ngIf="instrumentDetails.length > 0">
		<h3>Featured Instruments</h3>
		<div>
			<table class="table table-bordered">
				<thead>
					<tr>
						<th>Type</th>
						<th>Model</th>
						<th>Technique</th>
						<th>Effect</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let instrument of instrumentDetails">
						<td>{{ instrument.instrumentType }}</td>
						<td>{{ instrument.instrumentModel }}</td>
						<td>
							{{ instrument.playTechnique }}
						</td>
						<td>
							{{ instrument.effect }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="row" *ngIf="productPack?.previewFileName">
		<div class="col-12">
			<p>
				<b><em>Note: </em></b>
				The audio preview provided is a shorter and lower-quality sample for demonstration purposes, and upon purchase, customers will receive the complete advertised audio files with the specified format and resolution.
			</p>
		</div>
	</div>
	<div class="row" *ngIf="productType === 'Tutorial' && relatedProducts.length > 0">
		<hr />
		<h3>Related Products</h3>
		<div *ngFor="let product of relatedProducts" class="mt-3 col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
			<app-sfx-product-listing [product]="product"></app-sfx-product-listing>
		</div>
		<hr />
	</div>
</div>
