import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

interface DefaultRangeOptions {
	label: string;
	min: number;
	max: number;
}

@Component({
	selector: 'app-sfx-filter-range',
	templateUrl: './sfx-filter-range.component.html',
	styleUrls: ['./sfx-filter-range.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: SfxFilterRangeComponent,
		},
	],
})
export class SfxFilterRangeComponent implements OnInit, ControlValueAccessor {
	@Input() defaultOptions: DefaultRangeOptions[];
	@Input() identifier: string;
	@Input() label: string;

	min: number | undefined;
	max: number | undefined;

	onChange = (_: [number?, number?] | []) => {};
	onTouched = () => {};

	form: FormGroup = this.formBuilder.group({
		min: [],
		max: [],
	});

	constructor(private formBuilder: FormBuilder) {}

	writeValue(value: [number?, number?] | []): void {
		if (!value || value.length == 0) {
			this.max = undefined;
			this.min = undefined;
		} else {
			this.min = value[0];
			this.max = value[1];
		}

		let matchesDefault = false;
		for (const defaultOption of this.defaultOptions) {
			if (defaultOption.min == this.min && defaultOption.max == this.max) {
				matchesDefault = true;
			}
		}

		if (!matchesDefault) {
			this.form.patchValue({
				min: this.min,
				max: this.max,
			});
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	ngOnInit(): void {}

	setValue(min: number | undefined, max: number | undefined) {
		if (min == undefined && max == undefined) {
			this.onChange([]);
		} else {
			this.min = min;
			this.max = max;
			this.onChange([min, max]);
		}
	}

	clearValue() {
		this.min = undefined;
		this.max = undefined;

		this.form.patchValue({
			min: undefined,
			max: undefined,
		});

		this.onChange([]);
	}

	submit() {
		let min = this.form.get('min')?.value ?? 0;
		let max = this.form.get('max')?.value ?? undefined;
		this.setValue(min, max);
	}
}
