<div class="container-xl container-fluid sfx-top-spacing">
	<div class="sfx-center-container flex-lg-row justify-content-lg-around">
		<div class="form-wrapper text-center text-md-start">
			<h1>SoundFormX</h1>
			<p class="text-muted">SoundFormX is a platform that simplifies the search and procurement process of high-quality audio files for musicians, while also providing educational resources and community support.</p>
		</div>

		<div class="form-wrapper">
			<div class="bg-white p-3 shadow">
				<form (ngSubmit)="onSubmit()" [formGroup]="contactUsForm">
					<h4 class="text-center text-md-start" style="font-weight: bold">GET IN TOUCH</h4>
					<p class="text-center text-md-start mb-4" style="color: gray">24/7 We will answer your questions and problems</p>

					<div class="row">
						<div class="col">
							<div class="form-group">
								<label for="sfx-first-name">First Name</label>
								<input
									[ngClass]="{
										'is-invalid': isSubmitted && f['first_name'].errors,
										'is-valid': !f['first_name'].errors
									}"
									class="form-control mt-2"
									formControlName="first_name"
									id="sfx-first-name"
									placeholder="John"
									type="text"
								/>
							</div>
						</div>
						<div class="col">
							<div class="form-group">
								<label for="sfx-last-name">Last Name</label>
								<input
									[ngClass]="{
										'is-invalid': isSubmitted && f['last_name'].errors,
										'is-valid': !f['last_name'].errors
									}"
									class="form-control mt-2"
									formControlName="last_name"
									id="sfx-last-name"
									placeholder="Doe"
									type="text"
								/>
							</div>
						</div>
					</div>

					<div class="form-group mt-3">
						<label for="sfx-email">Email</label>
						<input
							[ngClass]="{
								'is-invalid': isSubmitted && f['email'].errors,
								'is-valid': !f['email'].errors
							}"
							class="form-control mt-2"
							formControlName="email"
							id="sfx-email"
							placeholder="Email"
							type="email"
						/>
					</div>

					<div class="form-group mt-3">
						<label for="sfx-phone">Description</label>
						<textarea
							[ngClass]="{
								'is-invalid': isSubmitted && f['description'].errors,
								'is-valid': !f['description'].errors
							}"
							class="form-control mt-2"
							formControlName="description"
							id="sfx-description"
							placeholder="Describe your issue"
							style="min-height: 150px"
						></textarea>
					</div>

					<div class="form-group text-center mt-3">
						<button class="btn w-100 text-white bg-info" type="submit">Send</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
