import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { lastValueFrom } from 'rxjs';
import { Order } from 'src/app/interfaces/Order';
import { OrderService } from 'src/app/services/order.service';
import { ToastType } from '../../../interfaces/ToastMessage';
import { ToastService } from '../../../services/toast.service';

@Component({
	selector: 'app-manage-orders',
	templateUrl: './manage-orders.component.html',
	styleUrls: ['./manage-orders.component.scss'],
})
export class ManageOrdersComponent implements OnInit {
	@ViewChild('dt1') dt1: Table;

	userOrders: Order[] = [];
	orderId: string;
	showModal: boolean = false;
	showModalId: string | null;
	@ViewChild('orderModal') orderModal: ElementRef;

	loading: boolean = true;
	totalOrders = 0;
	rowPerPage = 10;

	currentSortField = 'orderDate';
	currentSortOrder = 'DESC';
	orderStatusForm: FormGroup = this.formBuilder.group({
		deliveryStatus: ['', Validators.required],
	});

	constructor(private readonly orderService: OrderService, private formBuilder: FormBuilder, private toastService: ToastService) {}

	ngOnInit(): void {
		this.loadOrdersWithStartIndex(0);
	}

	openModal(orderId: string) {
		this.showModalId = orderId;
		this.showModal = true;
	}

	onCloseModal() {
		this.showModalId = null;
		this.showModal = false;
	}

	exportPDF() {
		// Get the modal content
		const modalContent = this.orderModal.nativeElement.innerHTML;
		const modalBody = this.orderModal.nativeElement.querySelector('.modal-body');
		const modalBodyRect = modalBody.getBoundingClientRect();
		const modalBodyWidth = modalBodyRect.width;
		const modalBodyHeight = modalBodyRect.height;

		// Create a new jsPDF instance
		const doc = new jsPDF();

		// Render the modal body as a canvas element using html2canvas
		html2canvas(modalBody).then((canvas) => {
			// Add the canvas element to the PDF document
			const imgData = canvas.toDataURL('image/png');
			const imgProps = doc.getImageProperties(imgData);
			const pdfWidth = doc.internal.pageSize.getWidth();
			const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
			doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

			// Get all the child elements of the modal body
			const childElements = Array.from(modalBody.children);

			// Loop through the child elements and add images and div background images to the PDF document
			childElements.forEach((element) => {
				if (element instanceof HTMLImageElement) {
					// If the element is an image, get its dimensions and position relative to the modal body
					const imgElement = element as HTMLImageElement;
					const imgRect = imgElement.getBoundingClientRect();
					const imgWidth = imgRect.width;
					const imgHeight = imgRect.height;
					const imgPosX = imgRect.left - modalBodyRect.left;
					const imgPosY = imgRect.top - modalBodyRect.top;

					// Add the image to the PDF document using the same relative dimensions and position
					doc.addImage(imgElement, 'JPEG', imgPosX, imgPosY, imgWidth, imgHeight);
				} else if (window.getComputedStyle(element as Element).backgroundImage !== 'none') {
					// If the element is a div with a background image, get its dimensions and position relative to the modal body
					const divRect = (element as Element).getBoundingClientRect();
					const divWidth = divRect.width;
					const divHeight = divRect.height;
					const divPosX = divRect.left - modalBodyRect.left;
					const divPosY = divRect.top - modalBodyRect.top;

					// Add the div background image to the PDF document using the same relative dimensions and position
					const bgImageUrl = window
						.getComputedStyle(element as Element)
						.backgroundImage.replace(/url\((['"])?(.*?)\1\)/gi, '$2')
						.split(',')[0];
					const bgImage = new Image();
					bgImage.src = bgImageUrl;
					console.log(bgImageUrl);
					bgImage.onload = function () {
						doc.addImage(bgImage, 'JPEG', divPosX, divPosY, divWidth, divHeight);
					};
				}
			});
			doc.save(`SoundFormX-OrderInvoice-${this.showModalId}.${new Date().getTime()}.pdf`);
		});
	}

	exportPDF2() {
		console.log('here');
		// Get the modal content
		const modalContent = this.orderModal.nativeElement.innerHTML;
		const modalBody = this.orderModal.nativeElement.querySelector('.modal-body');
		const modalBodyRect = modalBody.getBoundingClientRect();
		const modalBodyWidth = modalBodyRect.width;
		const modalBodyHeight = modalBodyRect.height;

		// Create a new jsPDF instance
		const doc = new jsPDF();

		// Keep track of the number of images that need to be loaded
		let imagesLoaded = 0;
		let totalImages = 0;

		// Render the modal body as a canvas element using html2canvas
		html2canvas(modalBody).then((canvas) => {
			// Add the canvas element to the PDF document
			const imgData = canvas.toDataURL('image/png');
			const imgProps = doc.getImageProperties(imgData);
			const pdfWidth = doc.internal.pageSize.getWidth();
			const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
			doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

			// Get all the child elements of the modal body
			const childElements = Array.from(modalBody.children);

			// Loop through the child elements and add images and div background images to the PDF document
			childElements.forEach((element) => {
				if (element instanceof HTMLImageElement) {
					// If the element is an image, get its dimensions and position relative to the modal body
					const imgElement = element as HTMLImageElement;
					const imgRect = imgElement.getBoundingClientRect();
					const imgWidth = imgRect.width;
					const imgHeight = imgRect.height;
					const imgPosX = imgRect.left - modalBodyRect.left;
					const imgPosY = imgRect.top - modalBodyRect.top;

					// Add the image to the PDF document using the same relative dimensions and position
					doc.addImage(imgElement, 'JPEG', imgPosX, imgPosY, imgWidth, imgHeight);
					totalImages++;
				} else if (window.getComputedStyle(element as Element).backgroundImage !== 'none') {
					// If the element is a div with a background image, get its dimensions and position relative to the modal body
					const divRect = (element as Element).getBoundingClientRect();
					const divWidth = divRect.width;
					const divHeight = divRect.height;
					const divPosX = divRect.left - modalBodyRect.left;
					const divPosY = divRect.top - modalBodyRect.top;

					// Add the div background image to the PDF document using the same relative dimensions and position
					const bgImageUrl = window
						.getComputedStyle(element as Element)
						.backgroundImage.replace(/url\((['"])?(.*?)\1\)/gi, '$2')
						.split(',')[0];
					const bgImage = new Image();
					bgImage.src = bgImageUrl;
					console.log(bgImageUrl);
					bgImage.onload = function () {
						doc.addImage(bgImage, 'JPEG', divPosX, divPosY, divWidth, divHeight);
						imagesLoaded++;
						if (imagesLoaded === totalImages) {
							doc.save(`SoundFormX-OrderInvoice-.${new Date().getTime()}.pdf`);
						}
					};
					totalImages++;
				}
			});

			// Save the PDF document if there are no images to load
			if (totalImages === 0) {
				doc.save(`no images SoundFormX-OrderInvoice-${this.showModalId}.${new Date().getTime()}.pdf`);
			}
		});
	}

	onSearchHandle(event: any) {
		const text = event.target.value || '';
		this.dt1.filterGlobal(text, 'contains');
	}

	loadOrdersWithStartIndex(index: number) {
		const page = Math.floor(index / this.rowPerPage) + 1;
		this.loading = true;

		this.orderService
			.getBatchAllOrdersSorted(this.currentSortOrder, this.currentSortField, page, -1)
			.subscribe((resp: HttpResponse<Order[]>) => {
				this.totalOrders = Number(resp.headers.get('X-Total-Count')) || 0;
				let orders = resp.body || [];

				orders.forEach((order: Order) => {
					order.deliveryStatus = order.deliveryStatus || 'Delivered';
				});

				this.userOrders = orders;

				this.loading = false;
			});
	}

	getSeverity(status: string) {
		switch (status.toLowerCase()) {
			case 'delivered':
				return 'success';
			default:
				return 'warning';
		}
	}

	onPageChanged(event: LazyLoadEvent) {
		this.currentSortField = event.sortField || 'orderDate';
		this.currentSortOrder = (event.sortOrder || -1) === 1 ? 'ASC' : 'DESC';
		this.loadOrdersWithStartIndex(event.first || 0);
	}

	async updateOrderStatus() {
		if (this.orderStatusForm.invalid || this.showModalId === null) return;

		let orderId = this.showModalId;
		let orderStatus = this.orderStatusForm.get('deliveryStatus')?.value;

		try {
			await lastValueFrom(this.orderService.updateOrderStatus(orderId, orderStatus));
			this.toastService.addToast({
				type: ToastType.SUCCESS,
				title: 'Order successfully updated!',
				message: 'Order updated to ' + orderStatus,
			});
		} catch (e) {
			this.toastService.addToast({
				type: ToastType.ERROR,
				title: 'Failed to update order.',
				message: 'Could not update order.',
			});
			return;
		}

		this.onCloseModal();
		this.loadOrdersWithStartIndex(0);
	}
}
