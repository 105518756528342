<div class="container mt-5">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item"><a class="text-info" routerLink="/admin">Dashboard</a></li>
			<li class="breadcrumb-item active" aria-current="page">Coupons</li>
		</ol>
	</nav>

	<h1>Coupons</h1>
	<hr />

	<p-table #couponTable [value]="coupons" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50, 100]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} coupons" [globalFilterFields]="['couponId', 'discount', 'expirationDate']" selectionMode="single">
		<ng-template pTemplate="caption">
			<div class="d-flex align-items-stretch gap-2">
				<div class="input-group flex-grow-1">
					<span class="input-group-text">
						<i class="bi bi-search"></i>
					</span>

					<input type="text" class="form-control" id="sfx-datatable-search" placeholder="Search for coupons" (input)="onSearchHandle($event)" />
				</div>

				<a class="flex-shrink-0 btn btn-primary" routerLink="/admin/coupons/add">Add Coupon</a>
			</div>
		</ng-template>
		<ng-template pTemplate="header">
			<tr>
				<th pSortableColumn="couponId">Coupon <p-sortIcon field="code"></p-sortIcon></th>
				<th pSortableColumn="isPercentage">Type <p-sortIcon field="code"></p-sortIcon></th>
				<th pSortableColumn="discount">Description <p-sortIcon field="code"></p-sortIcon></th>
				<th pSortableColumn="expirationDate">Expiration Date <p-sortIcon field="code"></p-sortIcon></th>
				<th pSortableColumn="productName"></th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-coupon>
			<tr>
				<td>{{ coupon.couponId }}</td>
				<td *ngIf="coupon.isPercentage">Percentage</td>
				<td *ngIf="!coupon.isPercentage">Fixed discount</td>
				<td *ngIf="coupon.isPercentage">{{ coupon.discount }}% off</td>
				<td *ngIf="!coupon.isPercentage">{{ coupon.discount | currency }} off</td>
				<td>{{ (coupon.expirationDate | date) || "Never" }}</td>
				<td class="icon-cell">
					<a class="text-info" routerLink="/admin/coupons/edit/{{ coupon.couponId }}">
						<span class="bi bi-pencil-square"></span>
					</a>
				</td>
			</tr>
		</ng-template>

		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="5">No Coupons found.</td>
			</tr>
		</ng-template>
	</p-table>
</div>
