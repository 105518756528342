<fieldset *ngIf="filter" [formGroup]="form">
	<div class="filter-button">
		<label class="filter-check" [for]="'filter-' + identifier + filter.id" role="button">
			<span class="form-check">
				<input class="form-check-input" formControlName="filter" type="checkbox" [id]="'filter-' + identifier + filter.id" role="button" (change)="onItemChecked($event)" [indeterminate]="hasChildrenValues" />

				<span>{{ filter.name }}</span>
				<span *ngIf="shouldShowCount"> ({{ filter.count }})</span>
			</span>
		</label>
		<span class="filter-expand" *ngIf="filter.children && filter.children.length > 0" role="button" (click)="toggleChildren()">
			<span *ngIf="!shouldShowChildren" class="bi bi-plus"></span>
			<span *ngIf="shouldShowChildren" class="bi bi-dash"></span>
		</span>
	</div>
	<div class="children" *ngIf="filter.children && filter.children.length > 0" [@slideInOut]="shouldShowChildren">
		<app-sfx-filter-tree formControlName="children" [identifier]="identifier" [filters]="filter.children" (change)="onChildChange($event)" [shouldShowCount]="shouldShowCount" [shouldUnselectChildren]="shouldUnselectChildren" [shouldUnselectParent]="shouldUnselectParent"></app-sfx-filter-tree>
	</div>
</fieldset>
