import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SFXHTMLContent } from '../interfaces/SFXHTMLContent';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class StaticPagesService {
	apiUrl: string = environment.base_url;

	constructor(private readonly http: HttpClient, private readonly authService: AuthService) {}

	getStaticContent(url: string): Observable<SFXHTMLContent> {
		return this.http.get<SFXHTMLContent>(this.apiUrl + '/static/page/' + url, this.authService.getHttpHeaders()).pipe(retry(1));
	}

	addStaticContent(staticContent: SFXHTMLContent): Observable<any> {
		return this.http.post<any>(this.apiUrl + '/static/add', staticContent, this.authService.getHttpHeaders());
	}

	updateStaticContent(url: string, staticContent: SFXHTMLContent): Observable<any> {
		return this.http.put<any>(this.apiUrl + '/static/update/' + url, staticContent, this.authService.getHttpHeaders());
	}

	deleteStaticContent(url: string): Observable<any> {
		return this.http.delete<any>(this.apiUrl + '/static/' + url, this.authService.getHttpHeaders());
	}

	async getAllStaticPages(): Promise<SFXHTMLContent[]> {
		return await lastValueFrom(this.http.get<SFXHTMLContent[]>(this.apiUrl + '/static/all', this.authService.getHttpHeaders()));
	}
}
