import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SFXFileType } from '../enums/SFXFileType';
import { SFXFileUploadStatus } from '../interfaces/SFXFileUploadStatus';
import { SFXUploadSingleFileResponse } from '../interfaces/SFXUploadSingleFileResponse';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class StorageService {
	private apiUrl: string = environment.base_url;

	constructor(private http: HttpClient, private authService: AuthService) {}

	uploadFile(file: File, fileType: SFXFileType): Observable<SFXFileUploadStatus> {
		return new Observable((observer) => {
			const formData = new FormData();
			formData.append('uploadFile', file);

			let size = '0KB';
			this.http
				.post<SFXUploadSingleFileResponse>(`${this.apiUrl}/file/upload/single/${fileType}`, formData, {
					reportProgress: true,
					observe: 'events',
					headers: this.authService.getNonJSONHttpHeadersObject(),
				})
				.subscribe({
					next: (event) => {
						if (event.type === HttpEventType.UploadProgress) {
							const percentage = Math.round((100 * event.loaded) / event.total!);
							size = this.formatFileSize(event.loaded, 1);
							observer.next({
								percentage,
								size,
								filePath: '',
							});
						} else if (event.type === HttpEventType.Response) {
							if (event.body) {
								const filePath = event.body.filePath;
								observer.next({
									percentage: 100,
									size,
									filePath,
								});
								observer.complete();
							}
						}
					},
					error: (error) => {
						observer.error(error);
					},
				});
		});
	}

	private formatFileSize(bytes: number, decimalPoint: number): string {
		// https://www.codexworld.com/how-to/convert-file-size-bytes-kb-mb-gb-javascript/#:~:text=The%20formatFileSize()%20function%20converts,%2C%20ZB%2C%20YB%20using%20Javascript.
		if (bytes == 0) return '0 Bytes';
		const k = 1000,
			dm = decimalPoint || 2,
			sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
			i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
	}
}
