<div class="container mt-5">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item"><a class="text-info" routerLink="/admin">Dashboard</a></li>
			<li class="breadcrumb-item"><a class="text-info" routerLink="/admin/coupons">Coupons</a></li>
			<li *ngIf="!isEditMode" class="breadcrumb-item active" aria-current="page">Add Coupon</li>
			<li *ngIf="isEditMode" class="breadcrumb-item active" aria-current="page">Edit Coupon</li>
		</ol>
	</nav>

	<form [formGroup]="addCouponForm" (ngSubmit)="addCoupon()" [ngClass]="{ 'invalid-form': showErrors }">
		<div class="float-end">
			<button type="submit" class="btn btn-primary" [disabled]="!(addCouponForm.touched && addCouponForm.dirty)">{{ isEditMode ? "Save Changes" : "Add Coupon" }}</button>
			<button type="button" class="btn btn-danger ms-2" *ngIf="isEditMode" (click)="deleteCoupon()">Delete</button>
		</div>
		<h1 *ngIf="!isEditMode">Add Coupon</h1>
		<h1 *ngIf="isEditMode">Edit Coupon</h1>
		<hr />
		<div *ngIf="showErrors && addCouponForm.invalid" class="alert alert-danger">Error: Invalid coupon. Check below for errors.</div>

		<div class="row align-items-start">
			<div class="col col-sm-6 col-md-4 col-12 form-group">
				<label for="coupon-code">Coupon Code *</label>
				<input formControlName="couponId" type="text" class="form-control text-uppercase" id="coupon-code" placeholder="SFX25..." required [readOnly]="isEditMode" />
				<div class="invalid-feedback">
					<div *ngIf="addCouponForm.get('couponId')?.hasError('required')">Required.</div>
					<div *ngIf="addCouponForm.get('couponId')?.hasError('notExisting')">That coupon code already exists.</div>
					<div *ngIf="addCouponForm.get('couponId')?.hasError('notInactive')">Sorry, coupon can not have the same name as a coupon that has been previously redeemed.</div>
				</div>
			</div>
		</div>

		<div class="row align-items-start mt-3">
			<div class="col col-sm-6 col-md-4 col-12 form-group">
				<label for="discount-type">Discount Type</label>
				<select formControlName="isPercentage" id="discount-type" class="form-control form-select">
					<option value="percentage">Percentage discount</option>
					<option value="fixed">Fixed amount discount</option>
				</select>
			</div>

			<div class="col col-sm-6 col-md-4 col-12 form-group">
				<label for="discount-amount">Discount Amount *</label>
				<input formControlName="discount" placeholder="25" id="discount-amount" class="form-control" type="number" required />
				<div class="invalid-feedback">Required.</div>
			</div>

			<h3 class="mt-4">Limitations</h3>
		</div>

		<div class="row align-items-end">
			<div class="col col-12 col-lg-8 col-md-6 form-group">
				<label for="product-list">Apply only to products</label>
				<app-sfx-multiselect formControlName="associatedProductIds" [dropdownList]="select_products" id="product-list" placeholder="All products" itemId="productId" itemText="productName"></app-sfx-multiselect>
			</div>

			<div class="col col-sm-6 col-md-4 col-12 form-group">
				<label for="expiration-date">Expiration Date</label>
				<input formControlName="expirationDate" id="expiration-date" placeholder="Never" class="form-control" type="text" bsDatepicker [bsConfig]="{ containerClass: 'theme-dark-blue', showClearButton: true, clearPosition: 'right' }" />
			</div>

			<div class="col col-sm-6 col-md-4 col-12 form-group">
				<label for="max-redemptions">Maximum redemptions</label>
				<input formControlName="maxRedemptions" id="max-redemptions" placeholder="No limit" class="form-control" type="number" />
			</div>

			<div class="col col-sm-6 col-md-4 col-12 form-group">
				<label for="minimum-order-amount">Minimum order amount</label>
				<input formControlName="minimumTotalAmount" id="minimum-order-amount" placeholder="0.00" class="form-control" type="number" />
			</div>

			<div class="col col-sm-6 col-md-4 col-12 form-group">
				<div class="form-check pb-1">
					<input formControlName="firstPurchaseOnly" class="form-check-input" id="first-purchase-only" type="checkbox" />
					<label class="form-check-label" for="first-purchase-only">First-time purchases only</label>
				</div>
			</div>
		</div>

		<div class="row mt-5 mb-5">
			<div class="col">
				<button [disabled]="!addCouponForm.touched" class="btn btn-primary w-100" type="submit">
					{{ isEditMode ? "Save Changes" : "Add Coupon" }}
				</button>
			</div>
		</div>
	</form>
</div>
