import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { lastValueFrom } from 'rxjs';
import { Product } from '../../../interfaces/Product';
import { ProductType } from '../../../interfaces/ProductType';
import { ProductService } from '../../../services/product.service';
import { RefDataService } from '../../../services/ref-data.service';

@Component({
	selector: 'app-products',
	templateUrl: './manage-products.component.html',
	styleUrls: ['./manage-products.component.scss'],
})
export class ManageProductsComponent implements OnInit {
	@ViewChild('dt1') dt1: Table;

	productTypes: ProductType[];
	products: Product[];

	loading: boolean = true;

	totalProducts = 0;
	rowPerPage = 10;

	currentSortField = 'dateModified';
	currentSortOrder = 'DESC';

	constructor(private productService: ProductService, private refDataService: RefDataService) {}

	async ngOnInit() {
		this.productTypes = await lastValueFrom(this.refDataService.getAllProductTypes());
		this.products = await lastValueFrom(this.productService.getAllProducts());
		this.loadProductsWithStartIndex(0);
	}

	getProductType(productTypeId: string | undefined) {
		let productType = this.productTypes.find((x) => x.productTypeId == productTypeId);
		return productType?.productTypeName;
	}

	onSearchHandle(event: any) {
		const text = event.target.value || '';
		this.dt1.filterGlobal(text, 'contains');
	}

	loadProductsWithStartIndex(index: number) {
		const page = Math.floor(index / this.rowPerPage) + 1;
		this.loading = true;

		// TODO: Use actual pagination by changing limit when search is implemented
		this.productService
			.getBatchAllProductsSorted(this.currentSortOrder, this.currentSortField, page, -1)
			.subscribe((resp: HttpResponse<Product[]>) => {
				this.totalProducts = Number(resp.headers.get('X-Total-Count')) || 0;
				this.products = resp.body || [];
				this.loading = false;
			});
	}

	onPageChanged(event: LazyLoadEvent) {
		this.currentSortField = event.sortField || 'dateModified';
		this.currentSortOrder = (event.sortOrder || 1) === 1 ? 'DESC' : 'ASC';
		this.loadProductsWithStartIndex(event.first || 0);
	}
}
