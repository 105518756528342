<div class="container sfx-top-spacing">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item"><a class="text-info" routerLink="/admin">Dashboard</a></li>
			<li aria-current="page" class="breadcrumb-item active">Pages</li>
		</ol>
	</nav>

	<h1>Pages</h1>
	<hr />
	<p-table #pageTable [value]="staticPages" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50, 100]" [globalFilterFields]="['pageTitle', 'pageURL']">
		<ng-template pTemplate="caption">
			<div class="d-flex align-items-stretch gap-2">
				<div class="input-group flex-grow-1">
					<span class="input-group-text">
						<i class="bi bi-search"></i>
					</span>
					<input type="text" class="form-control" id="sfx-datatable-search" placeholder="Search for pages" (input)="onSearchHandle($event)" />
				</div>
				<a class="flex-shrink-0 btn btn-primary" routerLink="/admin/pages/add">Add Page</a>
			</div>
		</ng-template>

		<ng-template pTemplate="header">
			<tr>
				<th scope="col">Page Name</th>
				<th scope="col">URL</th>
				<th scope="col"></th>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-page>
			<tr>
				<td>{{ page.pageTitle }}</td>
				<td>/{{ page.pageURL }}</td>
				<td class="icon-cell" *ngIf="page.pageTitle !== 'Home Page'">
					<a class="text-info" role="button" routerLink="/admin/pages/edit/{{ page.pageURL }}">
						<i class="bi bi-pencil-square"></i>
					</a>
				</td>
				<td class="icon-cell" *ngIf="page.pageTitle === 'Home Page'">
					<a class="text-info" role="button" routerLink="/admin/pages/home">
						<i class="bi bi-pencil-square"></i>
					</a>
				</td>
			</tr>
		</ng-template>
	</p-table>
</div>
