import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { SFXHTMLContent } from '../../../interfaces/SFXHTMLContent';
import { StaticPagesService } from '../../../services/static-pages.service';

@Component({
	selector: 'app-manage-pages',
	templateUrl: './manage-pages.component.html',
	styleUrls: ['./manage-pages.component.scss'],
})
export class ManagePagesComponent implements OnInit {
	@ViewChild('pageTable') pageTable: Table;
	staticPages: SFXHTMLContent[];

	constructor(private staticPagesService: StaticPagesService) {}

	async ngOnInit(): Promise<void> {
		const homePage = { pageTitle: 'Home Page', pageURL: '', htmlContent: '' };
		this.staticPages = [homePage, ...(await this.staticPagesService.getAllStaticPages())];
	}

	onSearchHandle(event: any) {
		const text = event.target.value || '';
		this.pageTable.filterGlobal(text, 'contains');
	}
}
