<div class="container sfx-top-spacing">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item"><a class="text-info" routerLink="/admin">Dashboard</a></li>
			<li aria-current="page" class="breadcrumb-item active">Customers</li>
		</ol>
	</nav>

	<div class="mb-4 mt-4">
		<h1>Customers</h1>
		<hr />

		<p-table #customersTable [value]="filteredUsers" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50, 100]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} customers" [globalFilterFields]="['firstName', 'lastName', 'email', 'dateJoined']" selectionMode="single">
			<ng-template pTemplate="caption">
				<div class="d-flex align-items-stretch gap-2">
					<div class="input-group flex-grow-1">
						<span class="input-group-text">
							<i class="bi bi-search"></i>
						</span>

						<input type="text" class="form-control" id="sfx-datatable-search" placeholder="Search for customers" (input)="onSearchHandle($event)" />
					</div>
				</div>
			</ng-template>
			<ng-template pTemplate="header">
				<tr>
					<th pSortableColumn="firstName">First Name <p-sortIcon field="code"></p-sortIcon></th>
					<th pSortableColumn="lastName">Last Name <p-sortIcon field="code"></p-sortIcon></th>
					<th pSortableColumn="email">Email <p-sortIcon field="code"></p-sortIcon></th>
					<th pSortableColumn="dateJoined">Date Joined <p-sortIcon field="code"></p-sortIcon></th>
					<th scope="col"></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-user>
				<tr>
					<td>{{ user.firstName }}</td>
					<td>{{ user.lastName }}</td>
					<td>{{ user.email }}</td>
					<td>{{ user.dateJoined }}</td>
					<td class="icon-cell">
						<a class="text-info" [routerLink]="['/admin', 'customers', user.userId]">
							<span class="bi bi-eye-fill"></span>
						</a>
					</td>
				</tr>
			</ng-template>

			<ng-template pTemplate="emptymessage">
				<tr>
					<td colspan="5">No customers found.</td>
				</tr>
			</ng-template>
		</p-table>

		<div *ngIf="filteredUsers.length > usersPerPageLimit">
			<app-sfx-pagination></app-sfx-pagination>
		</div>
	</div>
</div>
