import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DeliveryMethod } from '../../../../interfaces/DeliveryMethod';
import { ToastType } from '../../../../interfaces/ToastMessage';
import { RefDataService } from '../../../../services/ref-data.service';
import { ToastService } from '../../../../services/toast.service';

@Component({
	selector: 'app-delivery-method-modal',
	templateUrl: './delivery-method-modal.component.html',
	styleUrls: ['./delivery-method-modal.component.scss'],
})
export class DeliveryMethodModalComponent implements OnInit {
	deliveryMethods: DeliveryMethod[];
	deliveryMethodForm: FormGroup;
	currentDeliveryMethod: DeliveryMethod | null = null;
	isEditMode = false;

	constructor(
		public bsModalRef: BsModalRef,
		private formBuilder: FormBuilder,
		private refDataService: RefDataService,
		private toastService: ToastService
	) {}

	async ngOnInit() {
		this.bsModalRef.setClass('modal-lg');
		this.initializeForm();
		await this.loadDeliveryMethods();
	}

	async loadDeliveryMethods() {
		this.deliveryMethods = await this.refDataService.getAllDeliveryMethods();
	}

	initializeForm() {
		this.deliveryMethodForm = this.formBuilder.group({
			deliveryTypeName: ['', Validators.required],
			deliveryPrice: ['', Validators.required],
		});
	}

	async onSave() {
		if (this.deliveryMethodForm.valid) {
			const deliveryMethod: DeliveryMethod = {
				deliveryTypeName: this.deliveryMethodForm.get('deliveryTypeName')?.value,
				deliveryPrice: this.deliveryMethodForm.get('deliveryPrice')?.value.toString(),
			};

			if (this.isEditMode && this.currentDeliveryMethod) {
				deliveryMethod.deliveryTypeId = this.currentDeliveryMethod.deliveryTypeId;
				console.log(deliveryMethod);
				this.refDataService.updateDeliveryMethod(deliveryMethod).subscribe({
					next: async (_) => {
						this.toastService.addToast({
							title: 'Delivery method updated!',
							type: ToastType.SUCCESS,
							message: deliveryMethod.deliveryTypeName + ' updated successfully.',
						});

						await this.resetFormAndReload();
					},
					error: (error) => {
						this.toastService.addToast({
							title: 'Failed to update delivery method.',
							type: ToastType.ERROR,
							message: deliveryMethod.deliveryTypeName + ' had errors: ' + error?.message,
						});
					},
				});
			} else {
				await this.refDataService.createDeliveryMethod(deliveryMethod).subscribe({
					next: async (_) => {
						this.toastService.addToast({
							title: 'Delivery method added!',
							type: ToastType.SUCCESS,
							message: deliveryMethod.deliveryTypeName + ' added successfully.',
						});

						await this.resetFormAndReload();
					},
					error: (error) => {
						this.toastService.addToast({
							title: 'Failed to add delivery method.',
							type: ToastType.ERROR,
							message: deliveryMethod.deliveryTypeName + ' had errors: ' + error?.message,
						});
					},
				});
			}
		}
	}

	private async resetFormAndReload() {
		this.deliveryMethodForm.reset();
		this.currentDeliveryMethod = null;
		this.isEditMode = false;
		await this.loadDeliveryMethods();
	}

	onEdit(deliveryMethod: DeliveryMethod) {
		this.isEditMode = true;
		this.currentDeliveryMethod = deliveryMethod;
		this.deliveryMethodForm.patchValue({
			deliveryTypeName: deliveryMethod.deliveryTypeName || '',
			deliveryPrice: deliveryMethod.deliveryPrice || 0,
		});
	}

	onCancel() {
		this.deliveryMethodForm.reset();
		this.currentDeliveryMethod = null;
		this.isEditMode = false;
	}
}
