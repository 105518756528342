<div *ngIf="!dataLoaded">
	<app-sfx-loading-spinner></app-sfx-loading-spinner>
</div>
<div class="invoice-container" *ngIf="dataLoaded">
	<div class="invoice-header">
		<img class="invoice-logo" src="https://stg-audio-library-bucket.s3.amazonaws.com/preview_image/SoundFormX_Example_Header.png" alt="SoundFormX Logo" />
	</div>
	<div class="invoice-title" *ngIf="this.order.orderDate">
		<div class="invoice-info">Invoice</div>
		<div class="invoice-date">{{ this.order.orderDate }}</div>
		<div class="invoice-date">Order Id: {{ this.order.orderId }}</div>
	</div>

	<div class="invoice-address">
		<div class="invoice-billing-address" *ngIf="order.billingName">
			<h5>Billing Address</h5>
			<div>{{ this.order.billingName }}</div>
			<div>{{ this.order.billingLine1 }}</div>
			<div>{{ this.order.billingLine2 }}</div>
			<div>{{ this.order.billingCity }}, {{ this.order.billingState }} {{ this.order.billingPostalCode }}</div>
			<div>{{ this.order.billingCountry }}</div>
		</div>
		<div class="invoice-shipping-address" *ngIf="order.shippingName">
			<h5>Shipping Address</h5>
			<div>{{ this.order.shippingName }}</div>
			<div>{{ this.order.shippingLine1 }}</div>
			<div>{{ this.order.shippingLine2 }}</div>
			<div>{{ this.order.shippingCity }}, {{ this.order.shippingState }} {{ this.order.shippingPostalCode }}</div>
			<div>{{ this.order.shippingCountry }}</div>
		</div>
	</div>
	<div class="invoice-items" *ngFor="let item of order.orderItems">
		<div class="invoice-item">
			<div class="invoice-item-image" [style.background-image]="'url(' + getFullImageFileURL(item.imageFileUrl) + ')'"></div>
			<div class="invoice-item-details">
				<div class="invoice-item-name">{{ item.productName }}</div>
				<div class="invoice-item-quantity">{{ item.productQuantity }} x {{ item.price | currency }}</div>
				<div class="invoice-item-price">{{ Number(item.productQuantity) * item.price | currency }}</div>
				<div class="invoice-item-delivery">Delivery Method: {{ item.deliveryTypeName }}</div>
			</div>
		</div>
	</div>
	<div class="invoice-coupon" *ngIf="this.order.couponId !== null">
		<div>Coupon</div>
		<div>{{ this.order.couponId }}</div>
	</div>
	<div class="invoice-coupon" *ngIf="this.order.couponId !== null">
		<div>Coupon Savings</div>
		<div>{{ this.order.couponSavings | currency }}</div>
	</div>
	<div class="invoice-subtotal">
		<div>Subtotal</div>
		<div>{{ this.order.subTotal | currency }}</div>
	</div>
	<div class="invoice-tax">
		<div>Tax</div>
		<div>{{ this.order.estimatedTax | currency }}</div>
	</div>
	<div class="invoice-total">
		<div>Total</div>
		<div>{{ this.order.orderTotal | currency }}</div>
	</div>
</div>
