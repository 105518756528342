import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FilterTree } from '../../interfaces/Filters';

@Component({
	selector: 'app-sfx-filter-tree',
	templateUrl: './sfx-filter-tree.component.html',
	styleUrls: ['./sfx-filter-tree.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: SfxFilterTreeComponent,
		},
	],
})
export class SfxFilterTreeComponent implements OnInit, OnChanges, ControlValueAccessor, OnDestroy {
	@Input() filters: FilterTree[] = [];
	@Input() shouldShowCount: boolean = false;
	@Input() shouldUnselectChildren: boolean = false;
	@Input() shouldUnselectParent: boolean = true;
	@Input() identifier: string = '';

	subscription: Subscription;
	onChange = (_: string[]) => {};
	onTouched = () => {};
	form = this.formBuilder.group({
		fields: new FormArray([]),
	});

	get fieldsArray() {
		return this.form.controls.fields as unknown as FormArray;
	}

	constructor(private formBuilder: FormBuilder) {}

	ngOnInit(): void {
		this.filters?.forEach(() => this.fieldsArray.push(new FormControl([]), { emitEvent: false }));

		this.subscription = this.form.valueChanges.subscribe((_) => {
			let selectedItemIds = [].concat.apply([], this.form.value.fields ?? []);
			this.onChange(selectedItemIds);
		});
	}

	ngOnChanges(): void {
		let selectedItemIds = [].concat.apply([], this.form.value.fields ?? []);

		if (this.fieldsArray.length !== this.filters.length) {
			this.form.setControl('fields', new FormArray([]), { emitEvent: false });
			this.filters?.forEach(() => this.fieldsArray.push(new FormControl([]), { emitEvent: false }));
		}

		if (selectedItemIds) this.writeValue(selectedItemIds);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	writeValue(selectedFilters: string[] | undefined): void {
		if (!selectedFilters || selectedFilters.length === 0) return;
		console.log(selectedFilters);

		for (let i = 0; i < this.filters.length; i++) {
			let childrenFilters = [];
			for (const selectedFilter of selectedFilters) {
				if (this.filters[i].id === selectedFilter || this.hasMatchingChild(this.filters[i], selectedFilter)) {
					childrenFilters.push(selectedFilter);
				}
			}

			if (childrenFilters.length > 0) {
				this.fieldsArray.at(i).setValue(childrenFilters, { emitEvent: false });
			}
		}
	}

	registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any): void {
		this.onTouched = onTouched;
	}

	private hasMatchingChild(tree: FilterTree, id: string): boolean {
		if (tree.children === null || tree.children === undefined) {
			return false;
		}

		for (const child of tree.children) {
			if (child.id === id || this.hasMatchingChild(child, id)) {
				return true;
			}
		}

		return false; // If no children matched, return false.
	}
}
