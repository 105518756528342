import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/services/auth.service';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
	modalRef?: BsModalRef;
	resetPasswordForm: FormGroup;
	isSubmitted = false;

	constructor(
		private readonly titleService: Title,
		private readonly formBuilder: FormBuilder,
		private readonly authService: AuthService,
		private modalService: BsModalService,
		private router: Router
	) {}

	// convenience getter for easy access to form fields
	get f() {
		return this.resetPasswordForm.controls;
	}

	ngOnInit() {
		this.titleService.setTitle('Reset Password | SoundFormX');

		this.resetPasswordForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
		});
	}

	popupConfirmation(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template);
	}

	goToLogin() {
		this.router.navigate(['/sign-in']);
	}

	onSubmit(template: TemplateRef<any>) {
		this.isSubmitted = true;

		if (!this.resetPasswordForm || this.resetPasswordForm.invalid) {
			return;
		}

		const email = this.f['email'].value;
		this.authService.resetPassword(email).subscribe({
			next: (_) => {
				console.log('Request reset password successful');
				this.popupConfirmation(template);
			},
			error: (error) => {
				console.log('Error resetting password:', error);
			},
		});
	}
}
