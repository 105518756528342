import { Component, Input, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-sfx-suggestions',
	templateUrl: './sfx-suggestions.component.html',
	styleUrls: ['./sfx-suggestions.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: SfxSuggestionsComponent,
		},
	],
})
export class SfxSuggestionsComponent implements OnChanges, ControlValueAccessor {
	@Input() suggestions: string[] = [];
	@Input() placeholder: string = '';
	@Input() multiple: boolean = false;
	result: string | string[] = '';
	filteredSuggestions: string[];
	onChange = (_: any) => {};
	onTouched = () => {};
	touched: boolean = false;

	constructor() {}

	ngOnChanges(): void {
		if (this.multiple && !Array.isArray(this.result)) this.result = [];
		else if (Array.isArray(this.result)) this.result = '';
	}

	search(event: any) {
		let filtered: any[] = [];
		let query = event.query;

		for (let i = 0; i < this.suggestions.length; i++) {
			let item = this.suggestions[i];
			if (item.toLowerCase().indexOf(query.toLowerCase()) == 0) {
				filtered.push(item);
			}
		}

		this.filteredSuggestions = filtered;
		this.markAsTouched();
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	onKeyUp(event: KeyboardEvent) {
		if (event.key == 'Enter') {
			this.addToMultipleList(event);
		}
	}

	addToMultipleList(event: UIEvent) {
		if (this.multiple) {
			event.preventDefault();
			event.stopPropagation();
			let tokenInput = event.srcElement as any;
			if (tokenInput.value && tokenInput.value.replace(/^\s+|\s+$/g, '').length > 0) {
				if (Array.isArray(this.result)) this.result.push(tokenInput.value.replace(/^\s+|\s+$/g, ''));
				else this.result = [tokenInput.value];
				tokenInput.value = '';
			}
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	writeValue(obj: any): void {
		this.result = obj;
	}
}
