import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/services/auth.service';
import { PasswordValidator } from 'src/app/validators/password.validator';

@Component({
	selector: 'app-create-new-password',
	templateUrl: './create-new-password.component.html',
	styleUrls: ['./create-new-password.component.scss'],
})
export class CreateNewPasswordComponent implements OnInit {
	modalRef?: BsModalRef;
	createPasswordForm: FormGroup;
	newPassword: string;
	confirmPassword: string;
	token: string;
	showPassword = false;
	isSubmitted = false;

	constructor(
		private readonly titleService: Title,
		private readonly formBuilder: FormBuilder,
		private readonly authService: AuthService,
		private readonly router: Router,
		private route: ActivatedRoute,
		private modalService: BsModalService
	) {}

	// convenience getter for easy access to form fields
	get f() {
		return this.createPasswordForm.controls;
	}

	ngOnInit() {
		this.titleService.setTitle('Create New Password | SoundFormX');

		// Extract the token from the query parameters and store it in the component
		this.route.queryParams.subscribe((params) => {
			this.token = params['token'] || '';
		});

		this.createPasswordForm = this.formBuilder.group(
			{
				newPassword: ['', [Validators.required, PasswordValidator.complexity]],
				confirmPassword: ['', [Validators.required, PasswordValidator.complexity]],
			},
			{ validator: this.checkPasswords }
		);

		this.authService.logout(false);
	}

	popupConfirmation(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template);
	}

	goToLogin() {
		this.router.navigate(['/sign-in']);
	}

	checkPasswords(group: FormGroup) {
		const newPassword = group.get('newPassword')!.value;
		const confirmPassword = group.get('confirmPassword')!.value;

		return newPassword === confirmPassword ? null : { notSame: true };
	}

	onSubmit(template: TemplateRef<any>) {
		this.isSubmitted = true;

		if (!this.createPasswordForm || this.createPasswordForm.invalid || this.newPassword !== this.confirmPassword || !this.token) {
			return;
		}

		const newPassword = this.f['newPassword'].value;
		this.authService.createNewPassword(newPassword, this.token).subscribe({
			next: (_) => {
				console.log('Password updated successful');
				this.popupConfirmation(template);
			},
			error: (error) => {
				console.log('Error creating password:', error);
			},
		});
	}
}
