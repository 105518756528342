import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ToastType } from 'src/app/interfaces/ToastMessage';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { Address } from '../../../interfaces/Address';

@Component({
	selector: 'app-address-details',
	templateUrl: './address-details.component.html',
	styleUrls: ['./address-details.component.scss'],
})
export class AddressDetailsComponent implements OnInit, AfterViewChecked {
	addAddressForm: FormGroup;
	editAddressForm: FormGroup;

	addresses: Address[] = [];

	dataLoaded: boolean = false;

	@ViewChildren('shippingCheckbox') shippingCheckboxes: QueryList<HTMLInputElement>;

	constructor(
		private readonly titleService: Title,
		private readonly userService: UserService,
		private readonly formBuilder: FormBuilder,
		private toastService: ToastService,
		private cdr: ChangeDetectorRef
	) {}

	// convenience getter for easy access to form fields
	get addForm() {
		return this.addAddressForm.controls;
	}

	get editForm() {
		return this.editAddressForm.controls;
	}

	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}

	ngOnInit(): void {
		this.titleService.setTitle('Address Details | SoundFormX');

		this.getAddressData();

		this.addAddressForm = this.formBuilder.group({
			firstName: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
			lastName: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
			address: ['', Validators.required],
			unitNumber: [null],
			city: ['', Validators.required],
			state: ['', Validators.required],
			zip: ['', [Validators.required, Validators.pattern('[0-9]{5}')]],
			country: ['', Validators.required],
			checkDefaultShipping: [''],
		});

		this.editAddressForm = this.formBuilder.group({
			firstName: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
			lastName: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
			address: ['', Validators.required],
			unitNumber: [null],
			city: ['', Validators.required],
			state: ['', Validators.required],
			zip: ['', [Validators.required, Validators.pattern('[0-9]{5}')]],
			country: ['', Validators.required],
			checkDefaultShipping: [''],
		});
	}

	onAddAddressSubmit() {
		const firstName = this.addForm['firstName'].value;
		const lastName = this.addForm['lastName'].value;
		const streetAddress = this.addForm['address'].value;
		const unitNumber = this.addForm['unitNumber'].value;
		const city = this.addForm['city'].value;
		const state = this.addForm['state'].value;
		const zipcode = this.addForm['zip'].value;
		const country = this.addForm['country'].value;
		const isDefaultShipping = this.addForm['checkDefaultShipping'].value !== '';

		if (!firstName || !lastName || !streetAddress || !city || !state || !zipcode || !country) {
			this.toastService.addToast({
				type: ToastType.ERROR,
				title: 'Add Address Failed',
				message: 'Please fill in all required fields with valid inputs.',
			});
			return;
		}

		this.userService
			.addAddress(firstName, '', lastName, streetAddress, unitNumber, city, state, zipcode, country, isDefaultShipping)
			.subscribe({
				next: (_) => {
					this.toastService.addToast({
						type: ToastType.SUCCESS,
						title: 'Add Address Successful',
						message: 'Address was successfully added.',
					});
				},
				error: (_) => {
					this.toastService.addToast({
						type: ToastType.ERROR,
						title: 'Add Address Failed',
						message: 'Error adding address.',
					});
				},
			});
	}

	onEditAddressSubmit(addressId: String, address: Address) {
		const firstName = this.editForm['firstName'].value || address.firstName;
		const lastName = this.editForm['lastName'].value || address.lastName;
		const streetAddress = this.editForm['address'].value || address.streetAddress;
		const unitNumber = this.editForm['unitNumber'].value || address.unitNumber;
		const city = this.editForm['city'].value || address.city;
		const state = this.editForm['state'].value || address.state;
		const zipcode = this.editForm['zip'].value || address.zip;
		const country = this.editForm['country'].value || address.country;
		const isDefaultShipping = this.editForm['checkDefaultShipping'].value;

		if (!firstName || !lastName || !streetAddress || !city || !state || !zipcode || !country) {
			this.toastService.addToast({
				type: ToastType.ERROR,
				title: 'Edit Address Failed',
				message: 'Please fill in all required fields with valid inputs.',
			});
			return;
		}

		this.userService
			.updateAddress(firstName, lastName, streetAddress, unitNumber, city, state, zipcode, country, isDefaultShipping, addressId)
			.subscribe({
				next: (_) => {
					this.toastService.addToast({
						type: ToastType.SUCCESS,
						title: 'Update Address Successful',
						message: 'Address was successfully updated.',
					});
				},
				error: (_) => {
					this.toastService.addToast({
						type: ToastType.ERROR,
						title: 'Update Address Failed',
						message: 'Error updating address.',
					});
				},
			});
	}

	private getAddressData() {
		this.userService.getAllAddresses().subscribe({
			next: (res: any) => {
				//set addresses
				(res['userResp'] as Array<Address>).forEach((element: Address) => {
					this.addresses.push(element as Address);
				});
				this.dataLoaded = true;
			},
			error: (error) => {
				console.log('Error getting address data', error);
			},
		});
	}

	deleteAddress(addressId: string) {
		this.userService.deleteAddress(addressId).subscribe({
			next: (_) => {
				this.toastService.addToast({
					type: ToastType.SUCCESS,
					title: 'Delete Address Successful',
					message: 'Address was successfully deleted.',
				});
				this.addresses = [];
				this.getAddressData();
			},
			error: (_) => {
				this.toastService.addToast({
					type: ToastType.ERROR,
					title: 'Delete Address Failed',
					message: 'Error deleting address.',
				});
			},
		});
	}
}
