import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilterTree } from '../../interfaces/Filters';

@Component({
	selector: 'app-sfx-filter-tree-field',
	templateUrl: './sfx-filter-tree-field.component.html',
	styleUrls: ['./sfx-filter-tree-field.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: SfxFilterTreeFieldComponent,
		},
	],
	animations: [
		trigger('slideInOut', [
			state('true', style({ height: '*' })),
			state('false', style({ height: '0px' })),
			transition('false => true', [style({ height: 0, opacity: 0 }), animate(150)]),
			transition('true => false', [animate(150, style({ height: 0, opacity: 0 }))]),
		]),
	],
})
export class SfxFilterTreeFieldComponent implements OnInit, OnChanges, ControlValueAccessor {
	@Input() filter: FilterTree;
	@Input() shouldShowCount: boolean = false;
	@Input() shouldUnselectChildren: boolean = true;
	@Input() shouldUnselectParent: boolean = true;
	@Input() identifier: string = '';
	shouldShowChildren: boolean = false;

	onChange = (_: string[]) => {};
	onTouched = () => {};
	form = this.formBuilder.group({
		filter: [false],
		children: [<string[]>[]],
	});
	hasChildrenValues: boolean = false;

	constructor(private formBuilder: FormBuilder) {}

	ngOnInit(): void {}

	ngOnChanges(): void {}

	toggleChildren() {
		this.shouldShowChildren = !this.shouldShowChildren;
	}

	onItemChecked(event: any) {
		let isChecked = event.currentTarget.checked;
		this.shouldShowChildren = isChecked;
		let result: string[] = [];

		// add on check
		if (isChecked) {
			// remove children when a parent item is selected
			let currentChildren = this.form.get('children')?.value ?? [];
			if (this.shouldUnselectChildren) {
				this.hasChildrenValues = false;
				if (currentChildren.length > 0) this.form.get('children')?.setValue([], { emitEvent: false });
				result = [this.filter.id];
			} else {
				result = [this.filter.id, ...currentChildren];
			}
		}

		this.onChange(result);
	}

	onChildChange(_: any) {
		let currentChildren = this.form.get('children')?.value ?? [];
		let result: string[];
		this.hasChildrenValues = currentChildren.length > 0;
		if (this.shouldUnselectParent) {
			this.form.get('filter')?.setValue(false, { emitEvent: false });
			result = [...currentChildren];
		} else {
			result = [this.filter.id, ...currentChildren];
		}

		this.onChange(result);
	}

	writeValue(selectedFilters: string[]): void {
		let currentChildren = [];
		let isChecked = false;
		for (const selectedFilter of selectedFilters) {
			if (selectedFilter === this.filter.id) {
				isChecked = true;
				this.shouldShowChildren = true;
				this.form.get('filter')?.setValue(true, { emitEvent: false });
			} else if (this.hasMatchingChild(this.filter, selectedFilter)) {
				currentChildren.push(selectedFilter);
			}
		}

		if (!isChecked && this.form.get('filter')?.value) {
			this.form.get('filter')?.setValue(false, { emitEvent: false });
		}
		this.form.get('children')?.setValue(currentChildren, { emitEvent: false });

		if (currentChildren.length > 0) {
			this.hasChildrenValues = currentChildren.length > 0;
			this.shouldShowChildren = true;
		}
	}

	registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any): void {
		this.onTouched = onTouched;
	}

	private hasMatchingChild(tree: FilterTree, id: string): boolean {
		if (tree.children === null) {
			return false; // If there are no children, there can be no match.
		}

		for (const child of tree.children) {
			if (child.id === id || this.hasMatchingChild(child, id)) {
				return true; // If the current child has the id or a matching child, return true.
			}
		}

		return false; // If no children matched, return false.
	}
}
