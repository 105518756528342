export enum SFXUserGroup {
	Unauthenticated = -1,
	Any = 0,
	Admin = 1,
	Account = 2,
}

export function parseStringToUserGroupRole(s: string): SFXUserGroup {
	switch (s) {
		case '2':
			return SFXUserGroup.Account;
		case '1':
			return SFXUserGroup.Admin;
		default:
			return SFXUserGroup.Unauthenticated;
	}
}

export function parseUserGroupRoleToString(userGroupRole: SFXUserGroup): string {
	switch (userGroupRole) {
		case SFXUserGroup.Account:
			return '2';
		case SFXUserGroup.Admin:
			return '1';
		default:
			return '-1';
	}
}
