import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { Coupon } from '../../../interfaces/Coupon';
import { CouponService } from '../../../services/coupon.service';

@Component({
	selector: 'app-manage-coupons',
	templateUrl: './manage-coupons.component.html',
	styleUrls: ['./manage-coupons.component.scss'],
})
export class ManageCouponsComponent implements OnInit {
	coupons: Coupon[];
	@ViewChild('couponTable') couponTable: Table;

	constructor(private couponService: CouponService) {}

	async ngOnInit(): Promise<void> {
		this.coupons = await this.couponService.getAllCoupons();
	}

	onSearchHandle(event: any) {
		const text = event.target.value || '';
		this.couponTable.filterGlobal(text, 'contains');
	}
}
