import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SFXUserGroup } from '../enums/UserGroup';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(private authService: AuthService, private router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const url: string = state.url;
		return this.checkUserLogin(route, url);
	}

	checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
		if (route.data['role'] && route.data['role'].indexOf(SFXUserGroup.Any) >= 0) {
			return true;
		}
		const userRole = this.authService.getUserRole();

		if (route.data['role'] && route.data['role'].indexOf(userRole) === -1) {
			if (route.data['role'].indexOf(SFXUserGroup.Unauthenticated) > -1) {
				if (userRole === SFXUserGroup.Admin) {
					this.router.navigate(['/admin']);
					return false;
				} else {
					this.router.navigate(['/']);
					return false;
				}
			}

			this.router.navigate(['/sign-in']);
			return false;
		}
		return true;
	}
}
