import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class NgxSpinnerService {
	private spinnerSubject = new Subject<boolean>();
	spinnerState = this.spinnerSubject.asObservable();

	constructor() {}

	show() {
		this.spinnerSubject.next(true);
	}

	hide() {
		this.spinnerSubject.next(false);
	}
}
