import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-sfx-button',
	templateUrl: './sfx-button.component.html',
	styleUrls: ['./sfx-button.component.scss'],
})
export class SfxButtonComponent {
	@Input() text: string = '';
	@Input() tooltipText: string = '';
}
