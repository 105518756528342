<div class="sfx-main-container">
	<div class="sfx-inner-container">
		<app-sfx-navbar></app-sfx-navbar>
		<div class="error-page-container justify-content-center align-content-center w-100 h-100 mx-md-auto">
			<img class="error-gif" alt="SFX Error Gif" src="./assets/error.gif" />

			<div class="d-flex flex-column w-100 justify-content-center align-content-center">
				<p class="error-page-title">404: Page Not Found</p>
				<p>Oops, it looks like you stumbled upon our ultra-rare 'Silent Sound Effects' collection. Unfortunately, it seems that these sounds are so silent that even our website can't hear them! Please try searching for something a bit louder, or <a routerLink="/contact-us">contact our support team</a> for assistance.</p>
			</div>
		</div>
	</div>
	<app-sfx-footer></app-sfx-footer>
</div>
