import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Campaign } from '../interfaces/Campaign';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class NewsletterService {
	private readonly apiUrl: string = environment.base_url;

	constructor(private readonly http: HttpClient, private readonly authService: AuthService) {}

	getCampaigns(): Observable<Campaign[]> {
		return this.http.get<Campaign[]>(this.apiUrl + '/newsletters/lists/all', this.authService.getHttpHeaders()).pipe(retry(1));
	}

	unsubscribeUser(email: string, id: string): Observable<any> {
		const body = { email, id };
		console.log(body);
		return this.http.delete<any>(`${this.apiUrl}/newsletters/unsubscribe`, { body, ...this.authService.getHttpHeaders() });
	}

	subscribeUser(email: string, id: string): Observable<any> {
		const body = { email, id };
		console.log(body);
		return this.http.post(`${this.apiUrl}/newsletters/subscribe`, body);
	}

	doesSubscriberExist(email: string, id: string): Observable<any> {
		const params = { id };
		return this.http.get<any>(`${this.apiUrl}/newsletters/subscriber/exist?email=${encodeURIComponent(email)}`, { params });
	}
}
