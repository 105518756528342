<div class="container-fluid products-page sfx-top-spacing">
	<div class="row px-3 px-md-5">
		<div class="col-12 col-md-3 col-lg-2 mb-3">
			<div *ngIf="filters.productTypeIds && searchQuery">
				<article>
					<div *ngFor="let type of filters.productTypeIds">
						<a *ngIf="type.id == '2'" class="type-button btn btn-light d-block mb-2" [routerLink]="['/products', 'soundpack']" [queryParams]="{ keyword: searchQuery }">Sound Packs</a>
						<a *ngIf="type.id == '4'" class="type-button btn btn-light d-block mb-2" [routerLink]="['/products', 'tutorials']" [queryParams]="{ keyword: searchQuery }">Tutorials</a>
						<a *ngIf="type.id == '3'" class="type-button btn btn-light d-block mb-2" [routerLink]="['/products', 'merchandise']" [queryParams]="{ keyword: searchQuery }">Merch</a>
					</div>
				</article>
			</div>
			<div *ngIf="!filters.productTypeIds && productType && searchQuery">
				<article>
					<div>
						<a class="type-button btn btn-light d-block mb-2" [routerLink]="['/products', 'products']" [queryParams]="{ keyword: searchQuery }"><span class="bi bi-arrow-left-short"></span> Search All</a>
						<div *ngIf="productType === 'soundpack'" class="current-type btn btn-light d-block mb-2">Sound Packs</div>
						<div *ngIf="productType === 'tutorials'" class="current-type btn btn-light d-block mb-2">Tutorials</div>
						<div *ngIf="productType === 'merchandise'" class="current-type btn btn-light d-block mb-2">Merch</div>
					</div>
				</article>
			</div>

			<app-sfx-product-filter [filterTrees]="filters" [rangeFilters]="rangeFilters" [(ngModel)]="selectedFilters" (ngModelChange)="onFilterChange()"></app-sfx-product-filter>
		</div>
		<div class="col-12 col-md-9 col-lg-10">
			<div class="row justify-content-end pb-3">
				<div class="col">
					<div class="form-inline">
						<app-sfx-product-sort (sortOptionChanged)="onSortOptionChanged($event)"></app-sfx-product-sort>
					</div>
				</div>
			</div>

			<div *ngIf="!loaded">
				<app-sfx-loading-spinner></app-sfx-loading-spinner>
			</div>

			<div class="row" [hidden]="!loaded">
				<div *ngFor="let product of products" class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
					<div *ngIf="product" class="card border-0">
						<app-sfx-product-listing [product]="product"></app-sfx-product-listing>
					</div>
				</div>
			</div>

			<div class="row mt-5" [hidden]="!loaded">
				<div class="d-flex w-100 flex-column justify-center items-center text-center">
					<p class="text-center">You've viewed {{ products.length }} of {{ totalProducts }} products</p>
					<div class="w-100">
						<progressbar class="m-auto" [max]="totalProducts" [value]="products.length" style="max-width: 21rem; height: 3px"></progressbar>
					</div>

					<div *ngIf="products.length !== totalProducts" class="mt-3">
						<app-sfx-button (click)="loadNextBatch(productType, currentSortOrder, currentSortField)" text="LOAD MORE"></app-sfx-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
