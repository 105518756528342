import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Merchandise } from '../../../../interfaces/Merchandise';
import { ProductService } from '../../../../services/product.service';

@Component({
	selector: 'app-merchandise-form',
	templateUrl: './merchandise-form.component.html',
	styleUrls: ['./merchandise-form.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: MerchandiseFormComponent,
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: MerchandiseFormComponent,
		},
	],
})
export class MerchandiseFormComponent implements ControlValueAccessor, OnInit, OnDestroy, Validator {
	form: FormGroup = this.formBuilder.group({
		merchType: [''],
		size: [''],
		color: [''],
		stock: [''],
	});
	onChangeSubs: Subscription[] = [];
	touched: Boolean = false;

	constructor(private formBuilder: FormBuilder, private productService: ProductService) {}

	onTouched: Function = () => {};
	suggestedType: string[] = [];
	suggestedSize: string[] = [];
	suggestedColor: string[] = [];

	async ngOnInit() {
		let sub = this.form.valueChanges.subscribe((_) => {
			if (this.form.touched) {
				this.markAsTouched();
			}
		});

		[this.suggestedType, this.suggestedColor, this.suggestedSize] = await Promise.all([
			this.productService.getMerchandiseSuggestions('merchType'),
			this.productService.getMerchandiseSuggestions('color'),
			this.productService.getMerchandiseSuggestions('size'),
		]);

		this.onChangeSubs.push(sub);
	}

	ngOnDestroy() {
		for (let sub of this.onChangeSubs) {
			sub.unsubscribe();
		}
	}

	registerOnChange(onChange: any) {
		const sub = this.form.valueChanges.subscribe((_) => {
			let merch: Merchandise = {};

			merch.merchType = this.form.get('merchType')?.value || null;
			merch.size = this.form.get('size')?.value || null;
			merch.color = this.form.get('color')?.value || null;
			if (this.form.get('stock')?.value && !isNaN(this.form.get('stock')?.value)) merch.stock = this.form.get('stock')?.value.toString();
			else merch.stock = null;

			onChange(merch);
		});

		this.onChangeSubs.push(sub);
	}

	registerOnTouched(onTouched: Function) {
		this.onTouched = onTouched;
	}

	setDisabledState(disabled: boolean) {
		if (disabled) {
			this.form.disable();
		} else {
			this.form.enable();
		}
	}

	writeValue(merch: Merchandise) {
		if (merch) {
			this.form.setValue(
				{
					merchType: merch.merchType || '',
					size: merch.size || '',
					color: merch.color || '',
					stock: merch.stock || '',
				},
				{ emitEvent: false }
			);
		}
	}

	validate(_: AbstractControl) {
		if (this.form.valid) {
			return null;
		}

		let errors: any = {};

		errors = this.addControlErrors(errors, 'merchType');
		errors = this.addControlErrors(errors, 'size');
		errors = this.addControlErrors(errors, 'color');
		errors = this.addControlErrors(errors, 'stock');

		return errors;
	}

	addControlErrors(allErrors: any, controlName: string) {
		const errors = { ...allErrors };
		const controlErrors = this.form.controls[controlName].errors;

		if (controlErrors) {
			errors[controlName] = controlErrors;
		}
		return errors;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}
}
