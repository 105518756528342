import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Category } from '../interfaces/Category';
import { DeliveryMethod } from '../interfaces/DeliveryMethod';
import { FileFormat } from '../interfaces/FileFormat';
import { Genre } from '../interfaces/Genre';
import { Instrument } from '../interfaces/Instrument';
import { ProductType } from '../interfaces/ProductType';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class RefDataService {
	apiUrl: string = environment.base_url;

	constructor(private readonly http: HttpClient, private authService: AuthService) {}

	async getAllGenres(): Promise<Genre[]> {
		return await lastValueFrom(
			this.http
				.get<any>(this.apiUrl + '/genres/all')
				.pipe(map((x: Genre[]) => x.sort((a, b) => a.genreName.localeCompare(b.genreName))))
				.pipe(retry(1))
		);
	}

	async getAllInstruments(): Promise<Instrument[]> {
		return await lastValueFrom(
			this.http
				.get<any>(this.apiUrl + '/instruments/all')
				.pipe(map((x: Instrument[]) => x.sort((a, b) => a.instrumentName.localeCompare(b.instrumentName))))
				.pipe(retry(1))
		);
	}

	getAllCategories(): Promise<Category[]> {
		return lastValueFrom(
			this.http
				.get<any>(this.apiUrl + '/categories/all')

				.pipe(map((x: Category[]) => x.sort((a, b) => a.categoryName.localeCompare(b.categoryName))))
				.pipe(retry(1))
		);
	}

	getAllProductTypes(): Observable<ProductType[]> {
		return this.http.get<any>(this.apiUrl + '/productTypes/all').pipe(retry(1));
	}

	getAllDeliveryMethods(): Promise<DeliveryMethod[]> {
		return lastValueFrom(
			this.http
				.get<any>(this.apiUrl + '/deliveryMethods/all')
				.pipe(map((x: DeliveryMethod[]) => x.sort((a, b) => a.deliveryTypeName.localeCompare(b.deliveryTypeName))))
				.pipe(retry(1))
		);
	}

	getAllFileFormats(): Promise<FileFormat[]> {
		return lastValueFrom(this.http.get<any>(this.apiUrl + '/formats/all').pipe(retry(1)));
	}

	addGenre(genre: Genre): Observable<any> {
		return this.http.post<any>(this.apiUrl + '/genres/add', genre, this.authService.getHttpHeaders());
	}

	updateGenre(genre: Genre): Observable<any> {
		return this.http.put<any>(this.apiUrl + '/genres/update', genre, this.authService.getHttpHeaders());
	}

	addInstrument(instrument: Instrument): Observable<any> {
		return this.http.post<any>(this.apiUrl + '/instrument/add', instrument, this.authService.getHttpHeaders());
	}

	updateInstrument(instrument: Instrument): Observable<any> {
		return this.http.put<any>(this.apiUrl + '/instrument/update', instrument, this.authService.getHttpHeaders());
	}

	createDeliveryMethod(deliveryMethod: DeliveryMethod): Observable<any> {
		return this.http.post<any>(this.apiUrl + '/deliveryMethods/add', deliveryMethod, this.authService.getHttpHeaders());
	}

	updateDeliveryMethod(deliveryMethod: DeliveryMethod): Observable<any> {
		return this.http.put<any>(this.apiUrl + '/deliveryMethods/update', deliveryMethod, this.authService.getHttpHeaders());
	}

	createCategory(category: Category): Observable<any> {
		return this.http.post<any>(this.apiUrl + '/categories/add', category, this.authService.getHttpHeaders());
	}

	updateCategory(categoryId: string, category: Category): Observable<any> {
		return this.http.put<any>(this.apiUrl + '/categories/' + categoryId, category, this.authService.getHttpHeaders());
	}

	createFormat(format: FileFormat): Observable<any> {
		return this.http.post<any>(this.apiUrl + '/formats/add', format, this.authService.getHttpHeaders());
	}

	updateFormat(format: FileFormat): Observable<any> {
		return this.http.put<any>(this.apiUrl + '/formats/update', format, this.authService.getHttpHeaders());
	}
}
