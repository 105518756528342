import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
	constructor(private readonly titleService: Title) {}

	ngOnInit(): void {
		this.titleService.setTitle('404: Page Not Found | SoundFormX');
	}
}
