import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CarouselItem } from '../../interfaces/CarouselItem';
import { CarouselService } from '../../services/carousel.service';

@Component({
	selector: 'app-sfx-carousel',
	templateUrl: './sfx-carousel.component.html',
	styleUrls: ['./sfx-carousel.component.scss'],
})
export class SfxCarouselComponent implements OnInit {
	carouselItems: CarouselItem[];
	storageUrl: string = environment.storage_url;

	constructor(private carouselService: CarouselService) {}

	async ngOnInit() {
		this.carouselItems = await this.carouselService.getAllCarouselItems();
	}
}
