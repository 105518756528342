<div class="container-md sfx-top-spacing">
	<div class="row">
		<h2>Checkout</h2>
	</div>

	<div class="row mt-3">
		<div class="col-12 col-md-8">
			<div *ngIf="hasPhysicalProduct">
				<h4>Shipping Address</h4>

				<div style="height: 1px; background-color: gainsboro" class="w-100"></div>

				<form [formGroup]="shippingAddressForm" [ngClass]="{ 'invalid-form': showErrors }">
					<div class="row align-items-end mt-3">
						<div class="col col-sm-6 col-md-4 col-12 form-group">
							<label for="first-name">First Name<sup aria-label="required">*</sup></label>
							<input
								[ngClass]="{
									'is-invalid': isAddressSubmitted && af['firstName'].errors,
									'is-valid': !af['firstName'].errors
								}"
								class="form-control"
								formControlName="firstName"
								id="first-name"
								placeholder="First name"
								required
								type="text"
							/>
						</div>

						<div class="col col-sm-6 col-md-3 col-12 form-group">
							<label for="first-name">Middle Name</label>
							<input class="form-control" formControlName="middleName" id="middle-name" placeholder="Middle name" type="text" />
						</div>

						<div class="col col-sm-6 col-md-3 col-12 form-group">
							<label for="last-name">Last Name<sup aria-label="required">*</sup></label>
							<input
								[ngClass]="{
									'is-invalid': isAddressSubmitted && af['lastName'].errors,
									'is-valid': !af['lastName'].errors
								}"
								class="form-control"
								formControlName="lastName"
								id="last-name"
								placeholder="Last name"
								required
								type="text"
							/>
						</div>

						<div class="col col-sm-6 col-md-5 col-12 form-group">
							<label for="shipping-street">Line 1<sup aria-label="required">*</sup></label>
							<input
								[ngClass]="{
									'is-invalid': isAddressSubmitted && af['shippingStreet'].errors,
									'is-valid': !af['shippingStreet'].errors
								}"
								class="form-control"
								formControlName="shippingStreet"
								id="shipping-street"
								placeholder="Street address"
								required
								type="text"
							/>
						</div>

						<div class="col col-sm-6 col-md-2 col-12 form-group">
							<label for="shipping-street2">Line 2</label>
							<input class="form-control" formControlName="shippingStreet2" id="shipping-street2" placeholder="Apt." type="text" />
						</div>

						<div class="col col-sm-6 col-md-3 col-12 form-group mt-2">
							<label for="shipping-city">City<sup aria-label="required">*</sup></label>
							<input
								[ngClass]="{
									'is-invalid': isAddressSubmitted && af['shippingCity'].errors,
									'is-valid': !af['shippingCity'].errors
								}"
								class="form-control"
								formControlName="shippingCity"
								id="shipping-city"
								placeholder="City"
								required
								type="text"
							/>
						</div>

						<div class="col col-sm-6 col-md-3 col-12 form-group">
							<label for="shipping-state">State<sup aria-label="required">*</sup></label>
							<select
								[ngClass]="{
									'is-invalid': isAddressSubmitted && af['shippingState'].errors,
									'is-valid': !af['shippingState'].errors
								}"
								class="form-control form-select"
								formControlName="shippingState"
								id="shipping-state"
								required
							>
								<option disabled selected value="">Select...</option>
								<option *ngFor="let x of _US_STATES" [value]="x.id">
									{{ x.name }}
								</option>
							</select>
						</div>

						<div class="col col-sm-6 col-md-2 col-12 form-group">
							<label for="shipping-zip-code">ZIP Code<sup aria-label="required">*</sup></label>
							<input
								[ngClass]="{
									'is-invalid': isAddressSubmitted && af['shippingZipCode'].errors,
									'is-valid': !af['shippingZipCode'].errors
								}"
								class="form-control"
								formControlName="shippingZipCode"
								id="shipping-zip-code"
								placeholder="12345"
								required
								type="number"
							/>
						</div>

						<div class="d-flex flex-row align-items-end gap-3">
							<div class="form-group mt-3">
								<div class="form-check pb-1">
									<input class="form-check-input" formControlName="shouldSaveShipping" id="save-shipping" type="checkbox" />
									<label class="form-check-label" for="save-shipping">Save Address</label>
								</div>
							</div>

							<div class="form-group">
								<div class="form-check pb-1">
									<input class="form-check-input" formControlName="isDefaultShipping" id="default-shipping-address" type="checkbox" />
									<label class="form-check-label" for="default-shipping-address">Set Default</label>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>

			<h4 class="mt-5">Review Items</h4>
			<div style="height: 1px; background-color: gainsboro" class="w-100 mb-5"></div>
			<app-sfx-detailed-cart [isEditable]="false"></app-sfx-detailed-cart>
			<button class="btn btn-primary mt-5" type="button" (click)="onBackToShoppingCart()">
				<i class="bi bi-arrow-left"></i>
				<span class="m-2">Return to Shopping Cart</span>
			</button>
		</div>

		<div class="col-12 col-md-4">
			<div class="subtotal">
				<h5>Order Summary</h5>
				<div class="d-flex flex-row justify-content-between align-items-center w-100">
					<p>Subtotal:</p>
					<p>{{ subtotal | currency }}</p>
				</div>

				<div *ngIf="appliedCoupon">
					<div class="d-flex flex-row justify-content-between align-items-center w-100">
						<p style="font-weight: bold">Coupon applied:</p>
						<div *ngIf="appliedCoupon.isPercentage; else monetaryDiscount">
							<p style="font-weight: bold">{{ appliedCoupon.couponId }} ({{ appliedCoupon.discount }}%)</p>
						</div>

						<ng-template #monetaryDiscount>
							<p style="font-weight: bold">{{ appliedCoupon.couponId }}</p>
						</ng-template>
					</div>

					<div class="d-flex flex-row justify-content-between align-items-center w-100">
						<p style="font-weight: bold">Discount value:</p>
						<p style="font-weight: bold">-{{ discountValue | currency }}</p>
					</div>
				</div>

				<div class="d-flex flex-row justify-content-between align-items-center w-100">
					<div class="d-flex justify-content-center align-items-center h-100">Sales tax:</div>
					<div class="d-flex justify-content-center align-items-center">
						<i class="bi bi-info-circle" tooltip="Tax will be calculated on Stripe"></i>
					</div>
				</div>

				<div class="d-flex flex-row justify-content-between align-items-center w-100 mt-3">
					<p style="font-weight: bold">Total:</p>
					<p style="font-weight: bold">{{ total | currency }}</p>
				</div>

				<form (ngSubmit)="onCouponSubmit()" [formGroup]="checkoutForm" class="input-group mb-3 mt-1">
					<input
						[ngClass]="{
							'is-invalid': isCouponSubmitted && cf['couponId'].errors,
							'is-valid': !cf['couponId'].errors
						}"
						class="form-control"
						placeholder="Coupon code"
						formControlName="couponId"
						type="text"
						[attr.disabled]="total === 0 ? true : null"
					/>
					<div class="input-group-append">
						<button class="btn text-white bg-primary" type="submit" [disabled]="total === 0">APPLY</button>
					</div>
				</form>

				<button (click)="onCheckout()" class="btn text-white bg-primary mb-5 bg-infoCheckout w-100 mt-1">Continue Checkout...</button>
			</div>
		</div>
	</div>
</div>
