import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { Coupon } from '../../../interfaces/Coupon';
import { Product } from '../../../interfaces/Product';
import { ToastPayload, ToastType } from '../../../interfaces/ToastMessage';
import { CouponService } from '../../../services/coupon.service';
import { ProductService } from '../../../services/product.service';
import { ToastService } from '../../../services/toast.service';
import { couponExists } from '../../../validators/coupon-exists.validator';

@Component({
	selector: 'app-add-coupon',
	templateUrl: './add-coupon.component.html',
	styleUrls: ['./add-coupon.component.scss'],
})
export class AddCouponComponent implements OnInit {
	showErrors: boolean = false;
	isEditMode: boolean = false;
	code: string;
	select_products: Product[];
	existing_coupons: Coupon[];
	inactive_coupons: Coupon[];

	public addCouponForm: FormGroup = this.formBuilder.group({
		couponId: ['', Validators.required],
		isPercentage: ['percentage'],
		discount: ['', Validators.required],
		associatedProductIds: [[]],
		expirationDate: [''],
		maxRedemptions: [''],
		minimumTotalAmount: [''],
		firstPurchaseOnly: [false],
	});

	constructor(
		private formBuilder: FormBuilder,
		private productService: ProductService,
		private couponService: CouponService,
		private route: ActivatedRoute,
		private router: Router,
		private toastService: ToastService
	) {}

	async ngOnInit(): Promise<void> {
		this.code = this.route.snapshot.params['code'];
		if (this.code) this.isEditMode = true;

		this.select_products = await lastValueFrom(this.productService.getAllProductsNonPaginated());
		await this.loadEditForm();

		await this.validateExistingCoupons();
	}

	async validateExistingCoupons() {
		if (!this.isEditMode) {
			this.existing_coupons = await this.couponService.getAllCoupons();

			let couponControl = this.addCouponForm.get('couponId');
			couponControl?.clearValidators();
			couponControl?.addValidators([Validators.required, couponExists(this.existing_coupons)]);
			couponControl?.updateValueAndValidity();
		}
	}

	async loadEditForm() {
		if (this.isEditMode) {
			let coupon = await this.couponService.getCoupon(this.code);
			console.log(coupon);
			this.addCouponForm.patchValue({
				couponId: coupon.couponId,
				isPercentage: coupon.isPercentage ? 'percentage' : 'fixed',
				discount: coupon.discount,
				associatedProductIds: coupon.associatedProductIds || [],
				expirationDate: coupon.expirationDate ? formatDate(<string>coupon.expirationDate, 'MM/dd/yyyy', 'en-us') : '',
				maxRedemptions: coupon.maxRedemptions || '',
				minimumTotalAmount: coupon.minimumTotalAmount || '',
				firstPurchaseOnly: coupon.firstPurchaseOnly || false,
			});
		}
	}

	async addCoupon() {
		await this.validateExistingCoupons();

		if (!this.addCouponForm.valid) {
			this.showErrors = true;
			return;
		}

		let coupon = this.getCoupon();

		if (!this.isEditMode) {
			this.couponService.addCoupon(coupon).subscribe({
				next: this.alertSuccess(coupon.couponId, 'created'),
				error: this.alertFailure(coupon.couponId, 'create'),
			});
		} else {
			this.couponService.updateCoupon(coupon).subscribe({
				next: this.alertSuccess(coupon.couponId, 'updated'),
				error: this.alertFailure(coupon.couponId, 'update'),
			});
		}
	}

	deleteCoupon() {
		let shouldDelete = confirm('Are you sure you want to delete this coupon?');

		if (shouldDelete && this.code) {
			this.couponService.deleteCoupon(this.code).subscribe({
				next: this.alertSuccess(this.code, 'deleted'),
				error: this.alertFailure(this.code, 'delete'),
			});
		}
	}

	private getCoupon() {
		let coupon: Coupon = {};
		coupon.couponId = this.addCouponForm.get('couponId')?.value.toUpperCase();
		coupon.discount = this.addCouponForm.get('discount')?.value;
		coupon.isPercentage = this.addCouponForm.get('isPercentage')?.value === 'percentage';

		let expDate = this.addCouponForm.get('expirationDate')?.value;
		if (expDate && expDate.toString() !== '') {
			console.log(expDate);
			let d = new Date(expDate);
			// set the time to very end of the day
			d.setHours(47, 59, 59);
			d.setTime(d.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
			coupon.expirationDate = d.toISOString();
		} else coupon.expirationDate = null;

		let maxRedemptions = this.addCouponForm.get('maxRedemptions')?.value;
		if (maxRedemptions && maxRedemptions.toString() !== '') coupon.maxRedemptions = maxRedemptions;
		else coupon.maxRedemptions = null;

		let minimumTotalAmount = this.addCouponForm.get('minimumTotalAmount')?.value;
		if (minimumTotalAmount && minimumTotalAmount.toString() !== '') coupon.minimumTotalAmount = minimumTotalAmount;
		else coupon.minimumTotalAmount = null;

		coupon.firstPurchaseOnly = this.addCouponForm.get('firstPurchaseOnly')?.value === true;

		let associatedProductIds = this.addCouponForm.get('associatedProductIds')?.value;
		if (associatedProductIds?.length > 0) coupon.associatedProductIds = associatedProductIds;
		else coupon.associatedProductIds = [];

		console.log(coupon);
		return coupon;
	}

	private alertFailure(couponId: string | undefined, method: string) {
		return (error: any) => {
			let toast: ToastPayload = {
				title: `Coupon failed to ${method}.`,
				type: ToastType.ERROR,
				message: couponId + ' had errors: ' + error?.message,
			};
			this.toastService.addToast(toast);
		};
	}

	private alertSuccess(couponId: string | undefined, status: string) {
		return async (_: any) => {
			let toast: ToastPayload = {
				title: `Coupon ${status}!`,
				type: ToastType.SUCCESS,
				message: couponId + ` was ${status} successfully.`,
			};
			this.toastService.addToast(toast);
			await this.router.navigateByUrl('/admin/coupons');
		};
	}
}
