import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Genre } from '../../../../interfaces/Genre';
import { ToastPayload, ToastType } from '../../../../interfaces/ToastMessage';
import { RefDataService } from '../../../../services/ref-data.service';
import { ToastService } from '../../../../services/toast.service';

@Component({
	selector: 'app-genres-modal',
	templateUrl: './genres-modal.component.html',
	styleUrls: ['./genres-modal.component.scss'],
})
export class GenresModalComponent implements OnInit {
	genres: Genre[];
	genresWithSubGenres: Genre[];
	isEditMode: boolean = false;
	editGenreId: string | undefined;
	manageGenresForm: FormGroup;

	constructor(
		public bsModalRef: BsModalRef,
		private refDataService: RefDataService,
		private formBuilder: FormBuilder,
		private toastService: ToastService
	) {}

	async ngOnInit() {
		this.manageGenresForm = this.formBuilder.group({
			genreName: ['', Validators.required],
			parentGenre: [''],
		});

		await this.loadGenres();

		this.bsModalRef.setClass('modal-lg');
	}

	async loadGenres() {
		this.genres = await this.refDataService.getAllGenres();
		this.genresWithSubGenres = this.sortItems(this.genres);
	}

	getGenreName(id: number | string | null | undefined) {
		return this.genres.find((x) => x.genreId == id)?.genreName ?? '';
	}

	showEditMenu(genre: Genre) {
		this.isEditMode = true;
		this.editGenreId = genre.genreId;
		this.manageGenresForm.patchValue({
			genreName: genre.genreName || '',
			parentGenre: genre.relatedGenreId || '',
		});
	}

	cancelEditMode() {
		this.isEditMode = false;
		this.editGenreId = undefined;
		this.manageGenresForm.patchValue({
			genreName: '',
			parentGenre: '',
		});
	}

	sortItems(genres: Genre[]) {
		const sorted = genres.filter((x) => x.relatedGenreId === null);

		for (const item of genres.reverse()) {
			if (item.relatedGenreId !== null) {
				const index = sorted.findIndex((x) => x.genreId == item.relatedGenreId);
				sorted.splice(index + 1, 0, item);
			}
		}

		return sorted;
	}

	async addGenre() {
		if (this.manageGenresForm.valid) {
			let genre: Genre = {
				genreName: this.manageGenresForm.get('genreName')?.value,
			};
			let parent = this.manageGenresForm.get('parentGenre')?.value;
			if (parent && parent != '') genre.relatedGenreId = parent;
			else genre.relatedGenreId = null;

			if (!this.isEditMode) {
				this.refDataService.addGenre(genre).subscribe({
					next: (_) => {
						let toast: ToastPayload = {
							title: 'Genre added!',
							type: ToastType.SUCCESS,
							message: genre.genreName + ' added successfully.',
						};
						this.toastService.addToast(toast);
					},
					error: (error: any) => {
						let toast: ToastPayload = {
							title: 'Failed to add genre.',
							type: ToastType.ERROR,
							message: genre.genreName + ' had errors: ' + error?.message,
						};
						this.toastService.addToast(toast);
					},
				});
			} else {
				genre.genreId = this.editGenreId;
				this.refDataService.updateGenre(genre).subscribe({
					next: (_) => {
						let toast: ToastPayload = {
							title: 'Genre updated!',
							type: ToastType.SUCCESS,
							message: genre.genreName + ' updated successfully.',
						};
						this.toastService.addToast(toast);
					},
					error: (error: any) => {
						let toast: ToastPayload = {
							title: 'Failed to update genre.',
							type: ToastType.ERROR,
							message: genre.genreId + ' had errors: ' + error?.message,
						};
						this.toastService.addToast(toast);
					},
				});
				this.cancelEditMode();
			}

			this.manageGenresForm.reset();

			// Wait a second and then update genres
			// This decreases likelihood of it using a cached call
			await new Promise((r) => setTimeout(r, 100));
			await this.loadGenres();
		}
	}
}
