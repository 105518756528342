import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AvailableFilterTrees, RangeFilters, SelectedFilters } from '../../interfaces/Filters';

@Component({
	selector: 'app-sfx-product-filter',
	templateUrl: './sfx-product-filter.component.html',
	styleUrls: ['./sfx-product-filter.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: SfxProductFilterComponent,
			multi: true,
		},
	],
})
export class SfxProductFilterComponent implements OnInit, ControlValueAccessor {
	@Input() filterTrees: AvailableFilterTrees = {};
	@Input() rangeFilters: RangeFilters = {};
	selectedFilters: SelectedFilters;
	shouldShowCount: boolean = false;
	bpmOptions: any[] = [];
	priceOptions: any[] = [];

	onChange = (_: SelectedFilters) => {};
	onTouched = () => {};

	constructor(private renderer: Renderer2) {}

	ngOnInit(): void {
		this.loadStaticFilters();
	}

	loadStaticFilters(): void {
		this.bpmOptions = [
			{
				label: 'Under 76 BPM',
				min: 0,
				max: 76,
			},
			{
				label: '76 – 120 BPM',
				min: 76,
				max: 120,
			},
			{
				label: '120 – 168 BPM',
				min: 120,
				max: 168,
			},
			{
				label: '168 – 200 BPM',
				min: 168,
				max: 200,
			},
		];

		this.priceOptions = [
			{
				label: 'Free',
				min: 0,
				max: 0,
			},
			{
				label: 'Under $10',
				min: 0,
				max: 10,
			},
			{
				label: '$10 to $20',
				min: 10,
				max: 20,
			},
			{
				label: '$20 to $30',
				min: 20,
				max: 30,
			},
			{
				label: '$30 & Above',
				min: 30,
				max: undefined,
			},
		];
	}

	toggleCollapse(event: Event, target: HTMLElement) {
		event.preventDefault();
		let collapsed = target.getAttribute('aria-expanded') === 'false';

		if (collapsed) {
			this.renderer.addClass(target, 'show');
			target.setAttribute('aria-expanded', 'true');
		} else {
			this.renderer.removeClass(target, 'show');
			target.setAttribute('aria-expanded', 'false');
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	writeValue(obj: any): void {
		this.selectedFilters = obj;
	}

	onFilterChange() {
		this.shouldShowCount =
			this.selectedFilters.genres.length > 0 || this.selectedFilters.instruments.length > 0 || this.selectedFilters.formats.length > 0;
		this.onChange(this.selectedFilters);
	}
}
