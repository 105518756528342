import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Table } from 'primeng/table';
import { Order } from 'src/app/interfaces/Order';
import { User } from 'src/app/interfaces/User';
import { AuthService } from 'src/app/services/auth.service';
import { OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';
import { PasswordValidator } from 'src/app/validators/password.validator';

@Component({
	selector: 'app-view-customer',
	templateUrl: './view-customer.component.html',
	styleUrls: ['./view-customer.component.scss'],
})
export class ViewCustomerComponent implements OnInit {
	@ViewChild('customerTable') dt1: Table;
	loading: boolean = true;

	userId: string;
	editDetailsForm: FormGroup;
	userOrders: Order[] = [];
	user: User;

	showModal: boolean = false;
	showModalId: string | null;
	@ViewChild('orderModal') orderModal: ElementRef;

	constructor(
		private readonly formBuilder: FormBuilder,
		private readonly userService: UserService,
		private readonly authService: AuthService,
		private readonly orderService: OrderService,
		private route: ActivatedRoute
	) {}

	// convenience getter for easy access to form fields
	get f() {
		return this.editDetailsForm.controls;
	}

	ngOnInit(): void {
		this.route.params.subscribe((params) => {
			this.userId = params['id'];
		});

		this.editDetailsForm = this.formBuilder.group({
			firstName: [''],
			lastName: [''],
			email: ['', [Validators.email]],
			phone: ['', Validators.pattern('[- +()0-9]+')],
			currentPassword: [''],
			newPassword: ['', [PasswordValidator.complexity]],
			confirmPassword: ['', [PasswordValidator.complexity]],
		});

		this.userService.getCustomerByUserId(this.userId).subscribe({
			next: (res: User) => {
				this.user = res;

				this.editDetailsForm.patchValue({
					firstName: res.firstName,
					lastName: res.lastName,
					email: res.email,
					phone: res.phone,
				});
			},
			error: (error) => {
				console.log('Error getting address data', error);
			},
		});

		this.loading = true;
		this.orderService.getOrdersForUser(this.userId).subscribe({
			next: (res: any) => {
				this.userOrders = [];
				const temp: Order[] = [];
				res.forEach((order: Order) => {
					temp.push(order);
				});
				this.userOrders = temp;
				this.loading = false;
			},
		});
	}

	resetPassword(): void {
		const email = this.f['email'].value;
		this.authService.resetPassword(email).subscribe({
			next: (_) => {
				console.log('Request reset password successful');
			},
			error: (error) => {
				console.log('Error resetting password:', error);
			},
		});
	}

	onSubmit() {
		const firstName = this.f['firstName'].value;
		const lastName = this.f['lastName'].value;
		const email = this.f['email'].value;
		const phone = this.f['phone'].value;

		if (this.editDetailsForm.valid) {
			this.userService.updateCustomer(this.userId, firstName, lastName, email, phone).subscribe({
				next: (_) => {
					console.log('Update customer successful');
				},
				error: (error) => {
					console.log('Error updating user:', error);
				},
			});
		}
	}

	openModal(orderId: string) {
		this.showModalId = orderId;
		this.showModal = true;
	}

	onCloseModal() {
		this.showModalId = null;
		this.showModal = false;
	}

	exportPDF() {
		console.log('here');
		// Get the modal content
		const modalContent = this.orderModal.nativeElement.innerHTML;
		const modalBody = this.orderModal.nativeElement.querySelector('.modal-body');
		const modalBodyRect = modalBody.getBoundingClientRect();
		const modalBodyWidth = modalBodyRect.width;
		const modalBodyHeight = modalBodyRect.height;

		// Create a new jsPDF instance
		const doc = new jsPDF();

		// Render the modal body as a canvas element using html2canvas
		html2canvas(modalBody).then((canvas) => {
			// Add the canvas element to the PDF document
			const imgData = canvas.toDataURL('image/png');
			const imgProps = doc.getImageProperties(imgData);
			const pdfWidth = doc.internal.pageSize.getWidth();
			const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
			doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

			// Get all the child elements of the modal body
			const childElements = Array.from(modalBody.children);

			// Loop through the child elements and add images and div background images to the PDF document
			childElements.forEach((element) => {
				if (element instanceof HTMLImageElement) {
					// If the element is an image, get its dimensions and position relative to the modal body
					const imgElement = element as HTMLImageElement;
					const imgRect = imgElement.getBoundingClientRect();
					const imgWidth = imgRect.width;
					const imgHeight = imgRect.height;
					const imgPosX = imgRect.left - modalBodyRect.left;
					const imgPosY = imgRect.top - modalBodyRect.top;

					// Add the image to the PDF document using the same relative dimensions and position
					doc.addImage(imgElement, 'JPEG', imgPosX, imgPosY, imgWidth, imgHeight);
				} else if (window.getComputedStyle(element as Element).backgroundImage !== 'none') {
					// If the element is a div with a background image, get its dimensions and position relative to the modal body
					const divRect = (element as Element).getBoundingClientRect();
					const divWidth = divRect.width;
					const divHeight = divRect.height;
					const divPosX = divRect.left - modalBodyRect.left;
					const divPosY = divRect.top - modalBodyRect.top;

					// Add the div background image to the PDF document using the same relative dimensions and position
					const bgImageUrl = window
						.getComputedStyle(element as Element)
						.backgroundImage.replace(/url\((['"])?(.*?)\1\)/gi, '$2')
						.split(',')[0];
					const bgImage = new Image();
					bgImage.src = bgImageUrl;
					console.log(bgImageUrl);
					bgImage.onload = function () {
						doc.addImage(bgImage, 'JPEG', divPosX, divPosY, divWidth, divHeight);
					};
				}
			});
			doc.save(`SoundFormX-OrderInvoice-${this.showModalId}.${new Date().getTime()}.pdf`);
		});
	}

	viewOrder(orderId: string) {
		//placeholder until invoices api is complete
		const order = this.orderService.getOrder(orderId);
		//show order invoice
	}

	onSearchHandle(event: any) {
		const text = event.target.value || '';
		this.dt1.filterGlobal(text, 'contains');
	}
}
