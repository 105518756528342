import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-sfx-pagination',
	templateUrl: './sfx-pagination.component.html',
	styleUrls: ['./sfx-pagination.component.scss'],
})
export class SfxPaginationComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
