import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProductWithFilterTree } from 'src/app/interfaces/ProductWithFilterTree';
import { ProductService } from 'src/app/services/product.service';
import { AvailableFilterTrees, RangeFilters, SelectedFilters } from '../../../interfaces/Filters';

@Component({
	selector: 'app-browse-products',
	templateUrl: './browse-products.component.html',
	styleUrls: ['./browse-products.component.scss'],
})
export class BrowseProductsComponent implements OnInit, OnDestroy {
	@Input() selectedSortOption = 'Featured';
	products: any[] = [];
	productType: any;
	loaded: boolean = false;

	batch = 20;
	currentPage = 0;
	totalPages = 9999;
	totalProducts = 0;
	currentSortField = 'featured';
	currentSortOrder = 'ASC';
	filters: AvailableFilterTrees = {};
	selectedFilters: SelectedFilters = {
		categoryIds: [],
		genres: [],
		instruments: [],
		formats: [],
		bpm: [],
		price: [],
	};

	subscription: Subscription;
	rangeFilters: RangeFilters = {};

	searchQuery: string = '';

	constructor(
		private readonly titleService: Title,
		private readonly productsService: ProductService,
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.titleService.setTitle('Shop | SoundFormX');

		this.activatedRoute.params.subscribe((params) => {
			if (params['productType']) {
				this.productType = params['productType'];

				this.rangeFilters.price = true;
				if (this.productType == 'soundpack') this.rangeFilters.bpm = true;

				this.activatedRoute.queryParams.subscribe((params) => {
					if (params['category']) {
						this.selectedFilters.categoryIds = params['category'].split(',');
						// console.log(this.selectedFilters);
					} else {
						this.selectedFilters.categoryIds = [];
					}

					if (params['genres']) {
						this.selectedFilters.genres = params['genres'].split(',');
					} else {
						this.selectedFilters.genres = [];
					}

					if (params['instruments']) {
						this.selectedFilters.instruments = params['instruments'].split(',');
					} else {
						this.selectedFilters.instruments = [];
					}

					if (params['formats']) {
						this.selectedFilters.formats = params['formats'].split(',');
					} else {
						this.selectedFilters.formats = [];
					}

					if (params['minBpm'] || params['maxBpm']) {
						let min = params['minBpm'] || undefined;
						let max = params['maxBpm'] || undefined;

						this.selectedFilters.bpm = [min, max];
					} else {
						this.selectedFilters.bpm = [];
					}

					if (params['maxPrice'] || params['minPrice'] || params['maxPrice'] === '0') {
						let min = Number.parseInt(params['minPrice']) || 0;
						let max = params['maxPrice'] === '0' ? 0 : Number.parseInt(params['maxPrice']) || undefined;

						this.selectedFilters.price = [min, max];
					} else {
						this.selectedFilters.price = [];
					}

					this.activatedRoute.queryParams.subscribe((params) => {
						if (params['keyword']) {
							this.searchQuery = params['keyword'];
						} else {
							this.searchQuery = '';
						}

						this.loadNextBatch(this.productType, this.currentSortOrder, this.currentSortField, true, this.searchQuery);
					});
				});
			}
		});
	}

	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
	}

	updateProductSortType() {
		this.currentPage = 0;
		this.totalPages = 9999;
		this.totalProducts = 0;

		if (this.productType === 'soundpack') {
			this.products = [];
			if (this.selectedSortOption === 'Featured') {
				this.currentSortField = 'featured';
				this.currentSortOrder = 'ASC';
			} else if (this.selectedSortOption === 'Alphabetical') {
				this.currentSortField = 'productName';
				this.currentSortOrder = 'ASC';
			} else if (this.selectedSortOption === 'Price: Low to High') {
				this.currentSortField = 'price';
				this.currentSortOrder = 'ASC';
			} else if (this.selectedSortOption === 'Price: High to Low') {
				this.currentSortField = 'price';
				this.currentSortOrder = 'DESC';
			}
		} else if (this.productType == 'merchandise') {
			this.products = [];
			if (this.selectedSortOption === 'Featured') {
				this.currentSortField = 'featured';
				this.currentSortOrder = 'ASC';
			}
			if (this.selectedSortOption === 'Alphabetical') {
				this.currentSortField = 'productName';
				this.currentSortOrder = 'ASC';
			}
			if (this.selectedSortOption === 'Price: Low to High') {
				this.currentSortField = 'price';
				this.currentSortOrder = 'ASC';
			}
			if (this.selectedSortOption === 'Price: High to Low') {
				this.currentSortField = 'price';
				this.currentSortOrder = 'DESC';
			}
		} else if (this.productType == 'tutorials') {
			this.products = [];
			if (this.selectedSortOption === 'Featured') {
				this.currentSortField = 'featured';
				this.currentSortOrder = 'ASC';
			}
			if (this.selectedSortOption === 'Alphabetical') {
				this.currentSortField = 'productName';
				this.currentSortOrder = 'ASC';
			}
			if (this.selectedSortOption === 'Price: Low to High') {
				this.currentSortField = 'price';
				this.currentSortOrder = 'ASC';
			}
			if (this.selectedSortOption === 'Price: High to Low') {
				this.currentSortField = 'price';
				this.currentSortOrder = 'DESC';
			}
		}

		this.loadNextBatch(this.productType, this.currentSortOrder, this.currentSortField, false, this.searchQuery);
	}

	onSortOptionChanged(selectedSortOption: any) {
		this.selectedSortOption = selectedSortOption;
		this.loaded = false;
		this.updateProductSortType();
	}

	loadNextBatch(productType: string, sortBy: string, sortField: string, reset: boolean = false, searchQuery = '') {
		this.loaded = false;

		let nextPage;
		if (reset) {
			this.currentPage = 0;
			nextPage = this.currentPage + 1;
			this.totalPages = 9999;
			this.totalProducts = 0;
			this.products = [];
		} else {
			nextPage = this.currentPage + 1;
			this.currentPage += 1;
			if (nextPage > this.totalPages) {
				this.loaded = true;
				return;
			}
		}

		if (this.subscription) {
			this.subscription.unsubscribe();
		}

		let productsSub = this.productsService
			.getBatchProductsSorted(productType, sortBy, sortField, nextPage, this.batch, this.getFilterQueryParams(), searchQuery)
			.subscribe((resp: HttpResponse<ProductWithFilterTree>) => {
				this.totalPages = Number(resp.headers.get('X-Total-Pages')) || 0;
				this.totalProducts = Number(resp.headers.get('X-Total-Count')) || 0;
				const data = resp.body?.items || [];
				this.products = [...this.products, ...data];
				this.loaded = true;

				if (resp.body) this.filters = resp.body.filterTree;
			});

		this.subscription = productsSub;
	}

	onFilterChange() {
		const queryParams: Params = this.getUserFacingQueryParams();
		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams: queryParams,
		});
	}

	getFilterQueryParams(): { [p: string]: any } {
		let results: { [p: string]: any } = {};

		let categoryIds = this.selectedFilters.categoryIds;
		if (categoryIds) results['categoryId[in]'] = categoryIds;

		let genreIds = this.selectedFilters.genres;
		if (genreIds) results['genreIds[exists]'] = genreIds;

		let instrumentIds = this.selectedFilters.instruments;
		if (instrumentIds) results['instrumentTypeIds[exists]'] = instrumentIds;

		let formatIds = this.selectedFilters.formats;
		if (formatIds) results['formatIds[exists]'] = formatIds;

		let bpm = this.selectedFilters.bpm;
		if (bpm) {
			if (bpm[0]) results['bpm[gte]'] = bpm[0];
			if (bpm[1]) results['bpm[lte]'] = bpm[1];
		}

		let price = this.selectedFilters.price;
		if (price) {
			if (price[0]) results['price[gte]'] = price[0];
			if (price[1] || price[1] === 0) results['price[lte]'] = price[1];
		}

		return results;
	}

	getUserFacingQueryParams() {
		let results: { [p: string]: any } = {};

		let categoryIds = this.selectedFilters.categoryIds.join(',');
		if (categoryIds) results['category'] = categoryIds;

		let genreIds = this.selectedFilters.genres.join(',');
		if (genreIds) results['genres'] = genreIds;

		let instrumentIds = this.selectedFilters.instruments.join(',');
		if (instrumentIds) results['instruments'] = instrumentIds;

		let formatIds = this.selectedFilters.formats.join(',');
		if (formatIds) results['formats'] = formatIds;

		let bpm = this.selectedFilters.bpm;
		if (bpm) {
			if (bpm[0]) results['minBpm'] = bpm[0];
			if (bpm[1]) results['maxBpm'] = bpm[1];
		}

		let price = this.selectedFilters.price;
		if (price) {
			if (price[0]) results['minPrice'] = price[0];
			if (price[1] || price[1] === 0) results['maxPrice'] = price[1];
		}

		return results;
	}
}
