<h3>Merchandise Details</h3>
<fieldset [formGroup]="form">
	<div class="row">
		<div class="col col-sm-6 col-lg-3 col-12 form-group">
			<label for="merch-type">Merch Type</label>
			<app-sfx-suggestions formControlName="merchType" id="merchType" [suggestions]="suggestedType" [placeholder]="suggestedType[0] + '...'" (focus)="markAsTouched()" required></app-sfx-suggestions>
			<div class="invalid-feedback">Required.</div>
		</div>

		<div class="col col-sm-6 col-lg-3 col-12 form-group">
			<label for="stock">Stock</label>
			<input (focus)="markAsTouched()" class="form-control" formControlName="stock" id="stock" placeholder="0" type="number" />
		</div>

		<div class="col col-sm-6 col-lg-3 col-12 form-group">
			<label for="color">Color</label>
			<app-sfx-suggestions formControlName="color" id="color" [suggestions]="suggestedColor" [placeholder]="suggestedColor[0] + '...'" (focus)="markAsTouched()"></app-sfx-suggestions>
		</div>

		<div class="col col-sm-6 col-lg-3 col-12 form-group">
			<label for="size">Size</label>
			<app-sfx-suggestions formControlName="size" id="size" [suggestions]="suggestedSize" [placeholder]="suggestedSize[0] + '...'" (focus)="markAsTouched()"></app-sfx-suggestions>
		</div>
	</div>
</fieldset>
