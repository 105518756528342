import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { User } from 'src/app/interfaces/User';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-customers',
	templateUrl: './customers.component.html',
	styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit {
	@ViewChild('customersTable') customerTable: Table;

	users: User[] = [];
	searchTerm: string = '';
	//need to define number of users to be down per page
	usersPerPageLimit: number = 20;

	constructor(private readonly userService: UserService, private readonly router: Router) {}

	get filteredUsers() {
		return this.users.filter((user) => {
			return (
				user.email.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
				user.firstName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
				user.lastName.toLowerCase().includes(this.searchTerm.toLowerCase())
			);
		});
	}

	ngOnInit(): void {
		this.userService.getAllUsers().subscribe({
			next: (res: User[]) => {
				res.forEach((element: User) => {
					this.users.push(element);
				});
			},
			error: (error) => {
				console.log('Error getting address data', error);
			},
		});
	}

	onSearchHandle(event: any) {
		const text = event.target.value || '';
		this.customerTable.filterGlobal(text, 'contains');
	}
}
