import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { take } from 'rxjs';
import { CartItem } from 'src/app/interfaces/CartItem';
import { Product } from 'src/app/interfaces/Product';
import { SoundPackProduct } from 'src/app/interfaces/SoundPackProduct';
import { ToastType } from 'src/app/interfaces/ToastMessage';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { ProductService } from 'src/app/services/product.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { InstrumentData } from '../../interfaces/InstrumentData';

@Component({
	selector: 'app-sfx-product-detailed',
	templateUrl: './sfx-product-detailed.component.html',
	styleUrls: ['./sfx-product-detailed.component.scss'],
})
export class SfxProductDetailedComponent implements OnInit {
	productId: string;
	product: Product;
	productPack: SoundPackProduct;
	instrumentDetails: InstrumentData[] = [];
	productTypes: Record<number, string> = {
		1: 'Bundle',
		2: 'Sound Pack',
		3: 'Merchandise',
		4: 'Tutorial',
		5: 'Webinar',
	};
	productDefaultQuantities: Record<number, number> = {
		1: 1,
		2: 1,
		3: 1,
		4: 1,
		5: 1,
	};
	private readonly storage_url: String = environment.storage_url;
	productType: string;
	quantity: number = 1;
	relatedProducts: Product[] = [];

	constructor(
		private cartService: CartService,
		private toastService: ToastService,
		private productService: ProductService,
		private activatedRouter: ActivatedRoute,
		private authService: AuthService,
		private userService: UserService
	) {}

	ngOnInit(): void {
		this.activatedRouter.params.subscribe((params) => {
			console.log(params);
			if (params[`productId`]) {
				this.productId = params['productId'];

				this.productService.getProduct(this.productId).subscribe({
					next: (res: Product) => {
						this.product = res;

						if (this.product.productTypeId != undefined) {
							this.productType = this.productTypes[this.product.productTypeId as unknown as number];
						}

						if (this.productType === 'Sound Pack') {
							this.productService.getPackDetailed(this.productId).subscribe({
								next: (res: Product) => {
									this.productPack = res;

									if (this.productPack.instruments) {
										this.instrumentDetails = this.productPack.instruments.filter((x) => x.instrumentModel || x.effect || x.playTechnique);
									}
								},
								error: (error) => {
									console.log('Error getting product data', error);
								},
							});
						} else if (this.productType === 'Tutorial') {
							this.productService.getStandaloneTutorial(this.productId).subscribe({
								next: (res: Product) => {
									this.product = res;
									if (this.product?.relatedProductIds !== undefined) {
										console.log(this.product.relatedProductIds);
										this.product.relatedProductIds.forEach((id) => {
											this.productService.getStandaloneTutorial(id).subscribe({
												next: (result: any) => {
													this.relatedProducts.push(result as Product);
												},
											});
										});
									}
								},
							});
						}
					},
					error: (error) => {
						console.log('Error getting product data', error);
					},
				});
			}
		});
	}

	getFullImageFileURL(item: Product): string {
		return `${this.storage_url}/${encodeURIComponent(item.imageFileUrl || '')}`;
	}

	getFullPreviewFileUrl(item: Product) {
		return `${this.storage_url}/${encodeURIComponent(item.previewFileName || '')}`;
	}

	async addProductToCartIfNotOwned(): Promise<void> {
		const item: CartItem = {
			productId: this.product.productId,
			productQuantity: this.productDefaultQuantities[this.product.productTypeId as unknown as number] == 1 ? 1 : 0,
			deliveryTypeName: this.product.deliveryTypeName,
		};

		if (!this.cartService.canAddDigitalProductToCart(item)) {
			this.toastService.addToast({
				type: ToastType.INFO,
				title: 'Product Already In Cart!',
				message: 'Oops! This product is already in your cart.',
			});
			return;
		}

		if (this.authService.isAuthenticated()) {
			this.userService
				.checkUserOwnsProduct(this.product.productId || '')
				.pipe(take(1))
				.subscribe(async (res) => {
					if (res.isOwned) {
						this.toastService.addToast({
							type: ToastType.INFO,
							title: 'Product Already Owned!',
							message: 'Oops! This product is already in your library.',
						});
					} else {
						await this.addProductToCart();
					}
				});
		} else {
			await this.addProductToCart();
		}
	}

	async addProductToCart(): Promise<void> {
		const item: CartItem = {
			productId: this.product.productId,
			productQuantity: this.quantity,
		};

		await this.cartService.addItem(item);
		this.toastService.addToast({
			type: ToastType.SUCCESS,
			title: 'Success',
			message: this.product.productName + ' added to cart.',
		});
	}

	incrementValue(): void {
		if (this.quantity < 10) {
			this.quantity++;
		}
	}

	decrementValue(): void {
		if (this.quantity > 1) {
			this.quantity--;
		}
	}

	getFormattedPrice(price: string | undefined) {
		if (!price) return 'Free';
		const priceInDollars = Number(this.product.price);
		if (priceInDollars == 0) {
			return 'Free';
		}
		return priceInDollars.toLocaleString('en-US', {
			style: 'currency',
			currency: 'USD',
		});
	}
}
