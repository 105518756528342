import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ProductService } from 'src/app/services/product.service';

@Component({
	selector: 'app-product-details',
	templateUrl: './product-details.component.html',
	styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit {
	constructor(private readonly titleService: Title) {}

	ngOnInit(): void {
		this.titleService.setTitle('Product Details | SoundFormX');
	}
}
