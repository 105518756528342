import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Category } from 'src/app/interfaces/Category';
import { ToastType } from '../../../../interfaces/ToastMessage';
import { RefDataService } from '../../../../services/ref-data.service';
import { ToastService } from '../../../../services/toast.service';

@Component({
	selector: 'app-categories-modal',
	templateUrl: './categories-modal.component.html',
	styleUrls: ['./categories-modal.component.scss'],
})
export class CategoriesModalComponent implements OnInit {
	categories: Category[];
	categoryForm: FormGroup;
	currentCategory: Category | null = null;
	isEditMode = false;

	constructor(
		public bsModalRef: BsModalRef,
		private formBuilder: FormBuilder,
		private refDataService: RefDataService,
		private toastService: ToastService
	) {}

	async ngOnInit() {
		this.bsModalRef.setClass('modal-lg');
		this.initializeForm();
		await this.loadCategories();
	}

	async loadCategories() {
		this.categories = await this.refDataService.getAllCategories();
	}

	initializeForm() {
		this.categoryForm = this.formBuilder.group({
			categoryName: ['', Validators.required],
		});
	}

	async onSave() {
		if (this.categoryForm.valid) {
			const category: Category = {
				categoryName: this.categoryForm.get('categoryName')?.value,
			};

			if (this.isEditMode && this.currentCategory?.categoryId) {
				let categoryId = this.currentCategory.categoryId;
				console.log(category);
				this.refDataService.updateCategory(categoryId, category).subscribe({
					next: async (_: any) => {
						this.toastService.addToast({
							title: 'Category updated!',
							type: ToastType.SUCCESS,
							message: category.categoryName + ' updated successfully.',
						});
						await this.resetFormAndReload();
					},
					error: (error: any) => {
						this.toastService.addToast({
							title: 'Failed to update category.',
							type: ToastType.ERROR,
							message: category.categoryName + ' had errors: ' + error?.message,
						});
					},
				});
			} else {
				await this.refDataService.createCategory(category).subscribe({
					next: async (_: any) => {
						this.toastService.addToast({
							title: 'Category added!',
							type: ToastType.SUCCESS,
							message: category.categoryName + ' added successfully.',
						});
						await this.resetFormAndReload();
					},
					error: (error: any) => {
						this.toastService.addToast({
							title: 'Failed to add category.',
							type: ToastType.ERROR,
							message: category.categoryName + ' had errors: ' + error?.message,
						});
					},
				});
			}
		}
	}

	private async resetFormAndReload() {
		this.categoryForm.reset();
		this.currentCategory = null;
		this.isEditMode = false;
		await this.loadCategories();
	}

	onEdit(category: Category) {
		this.isEditMode = true;
		this.currentCategory = category;
		this.categoryForm.patchValue({
			categoryName: category.categoryName || '',
		});
	}

	onCancel() {
		this.categoryForm.reset();
		this.currentCategory = null;
		this.isEditMode = false;
	}
}
