<div class="row justify-content-end">
	<div class="col-auto">
		<div class="form-inline">
			<label class="mr-2" for="sortDropdown">Sort By: &nbsp; </label>
			<div (isOpenChange)="onOpenChange($event)" class="btn-group" dropdown>
				<button aria-controls="dropdown-state-change" class="btn btn-light dropdown-toggle" dropdownToggle id="sortDropdown" type="button">
					<span class="caret">{{ selectedSortOption }}</span>
				</button>
				<ul *dropdownMenu aria-labelledby="sortDropdown" class="dropdown-menu dropdown-menu-end" id="dropdown-state-change" role="menu">
					<li *ngFor="let option of options" role="menuitem">
						<a (click)="onSortOptionChange(option)" [class.active]="option === selectedSortOption" class="dropdown-item">{{ option }}</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
