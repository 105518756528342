import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Coupon } from '../interfaces/Coupon';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class CouponService {
	apiUrl: string = environment.base_url;

	constructor(private readonly http: HttpClient, private authService: AuthService) {}

	getAllCoupons(): Promise<Coupon[]> {
		return lastValueFrom(
			this.http
				.get<Coupon[]>(this.apiUrl + '/coupons/all', {
					params: { page: -1 },
					headers: this.authService.getHttpHeadersObject(),
				})
				.pipe(retry(1))
		);
	}

	addCoupon(coupon: Coupon): Observable<any> {
		return this.http.post<{ token: string }>(this.apiUrl + '/coupons/add', coupon, this.authService.getHttpHeaders());
	}

	getCoupon(code: String): Promise<Coupon> {
		return lastValueFrom(
			this.http.get<Coupon>(this.apiUrl + '/coupons/couponId/' + code, this.authService.getHttpHeaders()).pipe(retry(1))
		);
	}

	updateCoupon(coupon: Coupon): Observable<any> {
		return this.http.put<any>(this.apiUrl + '/coupons/update', coupon, this.authService.getHttpHeaders());
	}

	deleteCoupon(code: string): Observable<any> {
		return this.http.delete<any>(this.apiUrl + '/coupons/' + code, this.authService.getHttpHeaders());
	}
}
