import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-connect',
	templateUrl: './connect.component.html',
	styleUrls: ['./connect.component.scss'],
})
export class ConnectComponent implements OnInit {
	constructor(private readonly titleService: Title) {}

	ngOnInit(): void {
		this.titleService.setTitle('Connect | SoundFormX');
	}
}
