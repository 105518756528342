<div class="container sfx-top-spacing">
	<div class="row">
		<div class="col-md-3">
			<app-sfx-user-nav></app-sfx-user-nav>
		</div>
		<div class="col-md-9">
			<h1 class="mt-md-0 mt-3">Account Details</h1>
			<form (ngSubmit)="onSubmit()" [formGroup]="editDetailsForm">
				<div class="row mt-4">
					<div class="col-sm-12 col-md-6">
						<div class="card shadow-sm">
							<div class="card-body">
								<h3 class="card-title">Edit Profile</h3>
								<div class="row">
									<div class="col mb-3 form-group">
										<label class="form-label" for="firstName">First Name</label>
										<input id="firstName" class="form-control" formControlName="firstName" type="text" />
									</div>
									<div class="col mb-3 form-group">
										<label class="form-label" for="lastName">Last Name</label>
										<input id="lastName" class="form-control" formControlName="lastName" type="text" />
									</div>
								</div>
								<div class="form-group mb-3">
									<label class="form-label" for="email">Email</label>
									<input id="email" readonly class="form-control" formControlName="email" type="email" />
								</div>
								<div class="form-group mb-3">
									<label class="form-label" for="phone">Phone Number</label>
									<input id="phone" class="form-control" formControlName="phone" type="tel" />
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-md-6">
						<div class="card shadow-sm">
							<div class="card-body">
								<h3 class="card-title">Edit Password</h3>
								<div class="form-group mb-3">
									<label class="form-label" for="currentPassword">Current Password</label>
									<input id="currentPassword" class="form-control" formControlName="currentPassword" type="password" />
								</div>
								<div class="form-group mb-3">
									<label class="form-label" for="newPassword">New Password</label>
									<input id="newPassword" class="form-control" formControlName="newPassword" type="password" />
								</div>
								<div class="form-group mb-0">
									<label class="form-label" for="confirmPassword">Confirm Password</label>
									<input id="confirmPassword" class="form-control" formControlName="confirmPassword" type="password" />
								</div>
								<div class="mb-1">
									<div>
										<small class="text-muted">Use 8 or more characters with a mix of letters, numbers and symbols.</small>
									</div>
								</div>
							</div>
						</div>
						<div class="mb-3 mt-4 d-flex justify-content-end">
							<button class="btn btn-primary ms-3" type="submit">Save</button>
							<button (click)="deleteUser()" class="btn btn-danger ms-3" type="submit">Delete Account</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
