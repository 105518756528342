<div class="container-md sfx-top-spacing">
	<div class="row">
		<h2>Shopping Cart</h2>
	</div>

	<div class="row mt-3">
		<div class="col-12 col-md-8">
			<app-sfx-detailed-cart></app-sfx-detailed-cart>
			<button *ngIf="itemsInCart > 0" class="btn btn-danger mt-3" type="button" (click)="openConfirmationModal(confirmationModal)">
				<i class="bi bi-trash"></i>
				<span class="m-2" style="margin-right: 0 !important">Remove All</span>
			</button>
		</div>

		<div class="col-12 col-md-4">
			<div class="subtotal">
				<div class="d-flex flex-row justify-content-between align-items-center w-100">
					<h3>Subtotal:</h3>
					<h3>{{ subtotal | currency }}</h3>
				</div>

				<small class="text-muted w-100">Applicable taxes and shipping will be calculated at checkout.</small>
				<!-- <div class="input-group mb-3 mt-1">
					<input class="form-control" disabled placeholder="Coupon code" type="text" />
					<div class="input-group-append">
						<button class="btn text-white bg-info" disabled type="button">APPLY</button>
					</div>
				</div> -->
				<button (click)="onCheckout()" class="btn text-white bg-info mb-5 bg-infoCheckout w-100 mt-1">Continue to Checkout</button>
			</div>
		</div>
	</div>

	<!-- Confirmation Modal -->
	<ng-template #confirmationModal>
		<div class="modal-header">
			<h5 class="modal-title pull-left">Are you sure?</h5>
			<button (click)="modalRef?.hide()" class="btn text-white bg-info btn text-white bg-info-close close pull-right" type="button">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<p>Are you sure you want to remove all items from your cart?</p>
		</div>
		<div class="modal-footer">
			<button (click)="modalRef?.hide()" class="btn text-white bg-info" type="button">Cancel</button>
			<button (click)="onClearCart(); modalRef?.hide()" class="btn text-white bg-info" type="button">Remove</button>
		</div>
	</ng-template>
</div>
