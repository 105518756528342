import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				try {
					if (event instanceof HttpResponse) {
						if (event.headers.get('content-type')!.indexOf('application/json; charset=utf-8') > -1) {
							if (event.body.message !== 'Success') {
								throw new Error(`Error: ${event.body.message}`);
							}

							event = event.clone({ body: event.body.data });
						}
					}

					return event;
				} catch (err) {
					return event;
				}
			}),
			catchError((error: HttpErrorResponse) => {
				if (error.error instanceof ErrorEvent) {
					// A client-side or network error occurred. Handle it accordingly.
					console.error('An error occurred:', error.error.message);
				} else {
					// The backend returned an unsuccessful response code.
					// The response body may contain clues as to what went wrong,
					console.error(`Backend returned code ${error.status}, body was: ${error.error?.message || error.error}`);
					if (error.status === 401) {
						const alertMessage = 'Your session has expired. Please log in again.';
						alert(alertMessage);
						this.authService.logout();
					}

					if (error.status === 406) {
						// Coupon error
						return throwError(() => error);
					}

					if (error.error?.data) {
						let details = '';
						for (const datum of error.error?.data) {
							details += datum + '\n';
						}
						console.error(details);
					}
				}
				// return an observable with a user-facing error message
				return throwError(() => new Error('Something bad happened; please try again later.'));
			})
		);
	}
}
