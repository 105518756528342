import { Component, OnInit, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { CartItem } from 'src/app/interfaces/CartItem';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
	selector: 'app-shopping-cart',
	templateUrl: './shopping-cart.component.html',
	styleUrls: ['./shopping-cart.component.scss'],
})
export class ShoppingCartComponent implements OnInit {
	modalRef?: BsModalRef;
	subtotal: number;

	cartItems$: Observable<CartItem[]> = this.cartService.cartItemsSubject.asObservable();
	itemsInCart = 0;

	constructor(
		private modalService: BsModalService,
		private titleService: Title,
		private cartService: CartService,
		private authService: AuthService,
		private readonly router: Router
	) {}

	openConfirmationModal(confirmationModal: TemplateRef<any>) {
		this.modalRef = this.modalService.show(confirmationModal);
	}

	onCheckout(): void {
		if (!this.authService.isAuthenticated()) {
			this.router.navigate(['/sign-in']);
			return;
		}

		this.router.navigate(['/user/checkout']);
	}

	ngOnInit(): void {
		this.titleService.setTitle('Shopping Cart | SoundFormX');

		this.cartItems$.subscribe((cartItems) => {
			let subtotal = 0;
			for (const item of cartItems) {
				const price = Number(item.price) || 0;
				const quantity = Number(item.productQuantity) || 1;
				const itemSubPrice = price * quantity;
				subtotal += itemSubPrice;
			}
			this.subtotal = subtotal;
			this.itemsInCart = cartItems.length;
		});
	}

	async onClearCart(): Promise<void> {
		await this.cartService.clearCart();
	}
}
