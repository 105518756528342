<div class="container-fluid sfx-top-spacing">
	<ng-template #template>
		<div class="modal-header">
			<h4 class="modal-title pull-left">Success</h4>
			<button (click)="modalRef?.hide()" aria-label="Close" class="btn-close close pull-right" type="button">
				<span aria-hidden="true" class="visually-hidden">&times;</span>
			</button>
		</div>
		<div class="modal-body">Password reset successfully.</div>
		<div class="modal-footer">
			<button (click)="modalRef?.hide(); goToLogin()" class="btn btn-success text-white btn-default" type="button">Go to login</button>
		</div>
	</ng-template>

	<div class="sfx-center-container">
		<div class="form-wrapper text-center text-md-start">
			<h1>Create new password</h1>
			<p class="text-muted">Please enter a password with 8 or more characters and a mix of letters, numbers, and symbols.</p>
		</div>

		<div class="form-wrapper">
			<div class="bg-white p-3 w-100 shadow col-12 col-md-8 col-lg-6">
				<form (ngSubmit)="onSubmit(template)" [formGroup]="createPasswordForm">
					<h4 class="text-center mb-4">New Password</h4>

					<div class="form-group mt-3">
						<label for="sfx-new-password">New Password</label>
						<div class="input-group mt-2">
							<input
								[(ngModel)]="newPassword"
								[ngClass]="{
									'is-invalid': isSubmitted && f['newPassword'].errors,
									'is-valid': !f['newPassword'].errors
								}"
								[type]="showPassword ? 'text' : 'password'"
								class="form-control"
								formControlName="newPassword"
								id="sfx-new-password"
								placeholder="Enter a new password"
							/>

							<div class="input-group-append">
								<span (click)="showPassword = !showPassword" class="input-group-text">
									<i
										[ngClass]="{
											'bi-eye': !showPassword,
											'bi-eye-slash': showPassword
										}"
										class="bi bi-eye"
									></i>
								</span>
							</div>
						</div>
					</div>

					<div class="form-group mt-3">
						<label for="sfx-confirm-password">Confirm New Password</label>
						<div class="input-group mt-2">
							<input
								[(ngModel)]="confirmPassword"
								[ngClass]="{
									'is-invalid': isSubmitted && f['confirmPassword'].errors,
									'is-valid': !f['confirmPassword'].errors
								}"
								class="form-control"
								formControlName="confirmPassword"
								id="sfx-confirm-password"
								placeholder="Confirm your new password"
								type="password"
							/>
						</div>
					</div>

					<div class="form-group text-center mt-3">
						<button class="btn w-100 text-white bg-info" type="submit">Reset Password</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
