export enum ToastType {
	SUCCESS = 'success',
	INFO = 'info',
	WARNING = 'warning',
	ERROR = 'danger',
}

export interface ToastPayload {
	type: ToastType;
	title: string;
	message: string;
}
