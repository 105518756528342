import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { Order } from 'src/app/interfaces/Order';
import { AuthService } from 'src/app/services/auth.service';
import { OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-my-orders',
	templateUrl: './my-orders.component.html',
	styleUrls: ['./my-orders.component.scss'],
})
export class MyOrdersComponent implements OnInit {
	@ViewChild('dt1') dt1: Table;
	@ViewChild('orderModal') orderModal: ElementRef;

	userOrders: Order[] = [];
	userId: any;

	loading: boolean = true;

	totalPages = 0;
	totalOrders = 0;
	rowPerPage = 10;

	currentSortField = 'orderDate';
	currentSortOrder = 'DESC';
	showModal: boolean = false;
	showModalId: string | null;

	constructor(
		private readonly titleService: Title,
		private readonly userService: UserService,
		private readonly orderService: OrderService,
		private readonly authService: AuthService,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.titleService.setTitle('My Orders | SoundFormX');

		this.loadOrdersWithStartIndex(0);
	}

	loadOrdersWithStartIndex(index: number) {
		this.userId = this.authService.getUserId();

		const page = Math.floor(index / this.rowPerPage) + 1;
		this.loading = true;
		this.orderService
			.getBatchUserOrdersSorted(this.currentSortOrder, this.currentSortField, page, this.rowPerPage)
			.subscribe((resp: HttpResponse<Order[]>) => {
				this.totalPages = Number(resp.headers.get('X-Total-Pages')) || 0;
				this.totalOrders = Number(resp.headers.get('X-Total-Count')) || 0;
				let orders = resp.body || [];

				orders.forEach((order: Order) => {
					order.deliveryStatus = order.deliveryStatus || 'Delivered';
				});

				this.userOrders = orders;
				this.loading = false;
			});
	}

	getSeverity(status: string) {
		switch (status.toLowerCase()) {
			case 'delivered':
				return 'success';
			default:
				return 'warning';
		}
	}

	openModal(orderId: string) {
		this.showModalId = orderId;
		this.showModal = true;
	}

	onCloseModal() {
		this.showModalId = null;
		this.showModal = false;
	}

	exportPDF() {
		console.log('here');
		// Get the modal content
		const modalContent = this.orderModal.nativeElement.innerHTML;
		const modalBody = this.orderModal.nativeElement.querySelector('.modal-body');
		const modalBodyRect = modalBody.getBoundingClientRect();
		const modalBodyWidth = modalBodyRect.width;
		const modalBodyHeight = modalBodyRect.height;

		// Create a new jsPDF instance
		const doc = new jsPDF();

		// Render the modal body as a canvas element using html2canvas
		html2canvas(modalBody).then((canvas) => {
			// Add the canvas element to the PDF document
			const imgData = canvas.toDataURL('image/png');
			const imgProps = doc.getImageProperties(imgData);
			const pdfWidth = doc.internal.pageSize.getWidth();
			const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
			doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

			// Get all the child elements of the modal body
			const childElements = Array.from(modalBody.children);

			// Loop through the child elements and add images and div background images to the PDF document
			childElements.forEach((element) => {
				if (element instanceof HTMLImageElement) {
					// If the element is an image, get its dimensions and position relative to the modal body
					const imgElement = element as HTMLImageElement;
					const imgRect = imgElement.getBoundingClientRect();
					const imgWidth = imgRect.width;
					const imgHeight = imgRect.height;
					const imgPosX = imgRect.left - modalBodyRect.left;
					const imgPosY = imgRect.top - modalBodyRect.top;

					// Add the image to the PDF document using the same relative dimensions and position
					doc.addImage(imgElement, 'JPEG', imgPosX, imgPosY, imgWidth, imgHeight);
				} else if (window.getComputedStyle(element as Element).backgroundImage !== 'none') {
					// If the element is a div with a background image, get its dimensions and position relative to the modal body
					const divRect = (element as Element).getBoundingClientRect();
					const divWidth = divRect.width;
					const divHeight = divRect.height;
					const divPosX = divRect.left - modalBodyRect.left;
					const divPosY = divRect.top - modalBodyRect.top;

					// Add the div background image to the PDF document using the same relative dimensions and position
					const bgImageUrl = window
						.getComputedStyle(element as Element)
						.backgroundImage.replace(/url\((['"])?(.*?)\1\)/gi, '$2')
						.split(',')[0];
					const bgImage = new Image();
					bgImage.src = bgImageUrl;
					console.log(bgImageUrl);
					bgImage.onload = function () {
						doc.addImage(bgImage, 'JPEG', divPosX, divPosY, divWidth, divHeight);
					};
				}
			});
			doc.save(`SoundFormX-OrderInvoice-${this.showModalId}.${new Date().getTime()}.pdf`);
		});
	}

	onSearchHandle(event: any) {
		const text = event.target.value || '';
		this.dt1.filterGlobal(text, 'contains');
	}

	onPageChanged(event: LazyLoadEvent) {
		this.currentSortField = event.sortField || 'orderDate';
		this.currentSortOrder = (event.sortOrder || -1) === 1 ? 'ASC' : 'DESC';
		this.loadOrdersWithStartIndex(event.first || 0);
	}
}
