<!-- <button type="button" class="btn text-white bg-success" (click)="onAddSampleItem()">
    Add sample item
</button> -->

<div *ngIf="cartItems.length > 0; else emptyCart">
	<table class="table">
		<thead>
			<tr>
				<th></th>
				<th>Product</th>
				<th class="center">Quantity</th>
				<th class="center">Type</th>
				<th class="center">Delivery</th>
				<th class="center">Price</th>
				<th *ngIf="isEditable" class="center"></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let item of cartItems">
				<td class="center"><img [src]="getFullFileURL(item)" width="60" height="60" [alt]="item.productName" /></td>
				<td>
					<span class="alt text-muted">Product:</span>
					<div class="d-flex flex-column gap-1">
						<div>{{ item.productName }}</div>
						<div *ngIf="item.discountCouponId" style="color: gray; font-size: small">
							<div class="d-flex flex-row gap-1">
								<i class="bi bi-tags-fill navbar-icon"></i>
								<div class="d-flex flex-row gap-2">
									<div>{{ item.discountCouponId }}</div>
									<div>(-{{ item.discountValue | currency }})</div>
								</div>
							</div>
						</div>
					</div>
				</td>
				<td class="center">
					<span class="alt text-muted">Quantity:</span>
					<span *ngIf="item.productTypeName !== 'Merchandise'; else adjustableQuantity"> {{ item.productQuantity || 1 }}</span>
					<ng-template #adjustableQuantity>
						<app-sfx-cart-quantity-dropdown [defaultSelectedOption]="item.productQuantity || 1" (optionSelected)="onOptionSelected($event, item)"></app-sfx-cart-quantity-dropdown>
					</ng-template>
				</td>
				<td class="center"><span class="alt text-muted">Type:</span> {{ item.productTypeName || "" }}</td>
				<td class="center"><span class="alt text-muted">Delivery:</span> {{ item.deliveryTypeName }}</td>
				<td class="center"><span class="alt text-muted">Price:</span> {{ getItemSubtotal(item.price, item.productQuantity) | currency }}</td>
				<td class="center-cell" *ngIf="isEditable">
					<button type="button" class="btn text-white bg-info btn text-white bg-info-sm w-100" (click)="openConfirmationModal(item, confirmationModal)">
						<i class="bi bi-trash pr-2"></i>
						<span class="alt"> Remove item</span>
					</button>
				</td>
			</tr>
		</tbody>
	</table>

	<!-- Confirmation Modal -->
	<ng-template #confirmationModal>
		<div class="modal-header">
			<h5 class="modal-title pull-left">Are you sure?</h5>
			<button (click)="modalRef?.hide()" class="btn text-white bg-info btn text-white bg-info-close close pull-right" type="button">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<p>Are you sure you want to remove this item from your cart?</p>
		</div>
		<div class="modal-footer">
			<button (click)="modalRef?.hide()" class="btn text-white bg-info" type="button">Cancel</button>
			<button (click)="removeItem(selectedItem); modalRef?.hide()" class="btn text-white bg-info" type="button">Remove</button>
		</div>
	</ng-template>
</div>

<ng-template #emptyCart>
	<div class="text-center">
		<p>Your cart is currently empty.</p>
		<button [routerLink]="['/products', 'soundpack']" class="btn text-white bg-success" type="button">Start shopping</button>
	</div>
</ng-template>
