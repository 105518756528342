import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SFXFileType } from '../../../enums/SFXFileType';
import { CarouselItem } from '../../../interfaces/CarouselItem';
import { CarouselService } from '../../../services/carousel.service';

@Component({
	selector: 'app-edit-home-page',
	templateUrl: './edit-home-page.component.html',
	styleUrls: ['./edit-home-page.component.scss'],
})
export class EditHomePageComponent implements OnInit {
	isEditMode: boolean = false;
	editItemId: string | undefined;
	carouselItems: CarouselItem[];
	storage_url: string = environment.storage_url;

	form: FormGroup = this.formBuilder.group({
		imageFileUrl: ['', Validators.required],
		altText: ['', Validators.required],
		redirectLink: ['', Validators.required],
	});

	public sfxFileType: typeof SFXFileType = SFXFileType;

	constructor(private formBuilder: FormBuilder, private carouselService: CarouselService) {}

	async ngOnInit(): Promise<void> {
		this.carouselItems = await this.carouselService.getAllCarouselItems();
	}

	async onSubmit() {
		if (this.form.invalid) {
			return;
		}

		let carouselItem: CarouselItem = {
			imageFileName: this.form.get('imageFileUrl')?.value,
			altText: this.form.get('altText')?.value,
			redirectLink: this.form.get('redirectLink')?.value,
		};

		if (this.isEditMode) {
			carouselItem.carouselId = this.editItemId;
			await lastValueFrom(this.carouselService.updateCarouselItem(carouselItem));
			this.cancelEditMode();
		} else {
			await lastValueFrom(this.carouselService.addCarouselItem(carouselItem));
		}

		this.carouselItems = await this.carouselService.getAllCarouselItems();
	}

	cancelEditMode() {
		this.editItemId = undefined;
		this.isEditMode = false;

		this.form.patchValue({
			imageFileUrl: '',
			altText: '',
			redirectLink: '',
		});
	}

	showEditMenu(carouselItem: CarouselItem) {
		this.editItemId = carouselItem.carouselId;
		this.isEditMode = true;

		this.form.patchValue({
			imageFileUrl: carouselItem.imageFileName,
			altText: carouselItem.altText,
			redirectLink: carouselItem.redirectLink,
		});
	}

	async deleteItem(carouselItem: CarouselItem) {
		let shouldDelete = confirm('Are you sure you want to delete this carousel item? This cannot be undone.');

		if (shouldDelete && carouselItem.carouselId) {
			console.log(carouselItem.carouselId);
			await lastValueFrom(this.carouselService.deleteCarouselItem(carouselItem.carouselId));
			this.carouselItems = await this.carouselService.getAllCarouselItems();
		}
	}
}
