import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Address } from '../interfaces/Address';
import { User } from '../interfaces/User';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	public readonly apiUrl: string = environment.base_url;
	public readonly userId: string = this.authService.getUserId();

	constructor(private readonly http: HttpClient, private readonly authService: AuthService) {}

	getUser(): Observable<User> {
		return this.http.get<User>(this.apiUrl + '/users/' + this.userId, this.authService.getHttpHeaders()).pipe(retry(1));
	}

	getCustomerByUserId(userId: string): Observable<User> {
		return this.http.get<User>(this.apiUrl + '/users/' + userId, this.authService.getHttpHeaders()).pipe(retry(1));
	}

	getAllUsers(): Observable<User[]> {
		return this.http.get<User[]>(this.apiUrl + '/users/all', this.authService.getHttpHeaders()).pipe(retry(1));
	}

	getShippingAddress(): Observable<{ userResp: Address }> {
		return this.http
			.get<{ userResp: Address }>(this.apiUrl + '/users/' + this.userId + '/shippingAddress', this.authService.getHttpHeaders())
			.pipe(retry(1));
	}

	getBillingAddress(): Observable<{ userResp: Address }> {
		return this.http
			.get<{ userResp: Address }>(this.apiUrl + '/users/' + this.userId + '/billingAddress', this.authService.getHttpHeaders())
			.pipe(retry(1));
	}

	getAllAddresses(): Observable<{ userResp: Address[] }> {
		return this.http
			.get<{ userResp: Address[] }>(this.apiUrl + '/users/' + this.userId + '/allAddresses', this.authService.getHttpHeaders())
			.pipe(retry(1));
	}

	deleteAddress(addressId: string) {
		return this.http
			.delete<any>(this.apiUrl + '/users/' + this.userId + '/address/' + addressId, this.authService.getHttpHeaders())
			.pipe(retry(1));
	}

	addAddress(
		firstName?: string,
		middleName?: string,
		lastName?: string,
		streetAddress?: string,
		unitNumber?: string,
		city?: string,
		state?: string,
		zipcode?: string,
		country?: string,
		isDefaultShipping?: boolean
	): Observable<any> {
		let body: any = middleName ? { middleName } : {};
		body = unitNumber ? { ...body, unitNumber } : body;

		body = {
			...body,
			firstName,
			lastName,
			streetAddress,
			city,
			state,
			zipcode,
			country,
			isDefaultShipping,
			userId: this.userId,
		};
		return this.http
			.post<any>(this.apiUrl + '/users/' + this.userId + '/addAddress', body, this.authService.getHttpHeaders())
			.pipe(retry(1));
	}

	updateAddress(
		firstName?: String,
		lastName?: String,
		streetAddress?: String,
		unitNumber?: String,
		city?: String,
		state?: String,
		zipcode?: String,
		country?: String,
		isDefaultShipping?: boolean,
		addressId?: String
	): Observable<any> {
		const body = {
			firstName,
			lastName,
			streetAddress,
			unitNumber,
			city,
			state,
			zipcode,
			country,
			isDefaultShipping,
			userId: this.userId,
			addressId,
		};

		return this.http
			.put<any>(this.apiUrl + '/users/' + this.userId + '/updateAddress', body, this.authService.getHttpHeaders())
			.pipe(retry(1));
	}

	updateUser(firstName?: String, lastName?: String, email?: String, phone?: String, currentPassword?: String, newPassword?: String) {
		const body = {
			userId: this.userId,
			firstName,
			lastName,
			email,
			phone,
			currentPassword,
			newPassword,
		};
		return this.http.put<any>(this.apiUrl + '/users/' + this.userId + '/update', body, this.authService.getHttpHeaders()).pipe(retry(1));
	}

	updateCustomer(userId: string, firstName?: String, lastName?: String, email?: String, phone?: String) {
		const body = {
			userId,
			firstName,
			lastName,
			email,
			phone,
		};
		return this.http.put<any>(this.apiUrl + '/users/' + this.userId + '/update', body, this.authService.getHttpHeaders()).pipe(retry(1));
	}

	deleteUser() {
		return this.http.delete<any>(this.apiUrl + '/users/' + this.userId, this.authService.getHttpHeaders()).pipe(retry(1));
	}

	public checkUserOwnsProduct(productId: string): Observable<{ isOwned: boolean }> {
		return this.http
			.get<{ isOwned: boolean }>(this.apiUrl + '/userLibrary/owns/' + productId, this.authService.getHttpHeaders())
			.pipe(retry(1));
	}
}
