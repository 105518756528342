import { Injectable } from '@angular/core';
import { SfxProductListingComponent } from '../components/sfx-product-listing/sfx-product-listing.component';

@Injectable({
	providedIn: 'root',
})
export class AudioplayerService {
	private activePlayer: HTMLAudioElement | null = null;
	private currentComponent: SfxProductListingComponent | null = null;

	constructor() {}

	play(component: SfxProductListingComponent, audioFile: string) {
		if (this.activePlayer) {
			this.activePlayer.pause();

			if (this.currentComponent) {
				this.currentComponent.isPlaying = false;
			}
		}

		this.activePlayer = new Audio(audioFile);
		this.activePlayer.play();
		this.currentComponent = component;
	}

	pause() {
		if (this.activePlayer) {
			this.activePlayer.pause();
			this.activePlayer = null;
			this.currentComponent = null;
		}
	}
}
